import React, { useState, FC, useEffect, useMemo, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import other_crop from "../../assets/images/other_crop.svg";
import { deviceInfoContext } from "../../context";
import { updateCropData, updateTradeDetails } from "../../reducers/tradeSlice";
import { TRootState } from "../../store";
import { TCrop } from "../../types";
import CropSelection from "../crop-selection";
import Modal from "../ui/modal";
import ModalHeader from "../ui/modal/modal-header";
import classes from "./style.module.scss";
const SelectCrop: FC<{
  status?: string;
  //  openStatus: boolean;
}> = ({ status }) => {
  const dispatch = useDispatch();
  const { width, deviceType } = useContext(deviceInfoContext);
  const { tradeDetailsData, selectedCrop } = useSelector(
    (state: TRootState) => ({
      tradeDetailsData: state.trades.tradeDetails,
      selectedCrop: state.trades.selectedCrop,
    })
  );
  const [showCropModal, setShowCropModal] = useState(false);
  const addCropItem = (crops: TCrop) => {
    var obj = { ...crops };
    Object.assign(obj, {
      qtyUnit: "BAGS",
      weightUnit: "RATE_PER_KG",
      qty: 0,
      weight: 0,
      freightAmt: 0,
      status: 1,
      id: 0,
    });
    dispatch(updateCropData({ selectedCrop: obj, deletedCrop: selectedCrop }));
    console.log(selectedCrop, "selectedCrop");
  };
  return (
    <>
      <div
        className={`inline-flex gap-4 border bg-white border-color-dark max-w-[100%] rounded-lg overflow-auto scrollbar items-center p-2 ${
          status ? classes.cropHeight : ""
        }`}
        style={{ width: "100%" }}
      >
        <div
          className="flex justify-between w-full cursor-pointer"
          onClick={() => {
            setShowCropModal(true);
          }}
        >
          <div className={`flex gap-4 px-2 items-center ${classes.crops_row}`}>
            {selectedCrop != null ? (
              <>
                <img
                  src={selectedCrop?.imageUrl}
                  className="w-10 cursor-pointer "
                />
                <span>{selectedCrop?.cropName}</span>
              </>
            ) : (
              <span>Select Crop</span>
            )}
          </div>
          <span
            className="ml-auto flex items-center justify-center shrink-0 flex-col gap-2"
            style={{
              width: "4.8rem",
            }}
          >
            <img src={other_crop} className="w-10 cursor-pointer " />
          </span>
        </div>
        {showCropModal === true && (
          <>
            <Modal
              open={showCropModal}
              width={deviceType === "MOBILE" ? "80vw" : "400px"}
              onClose={() => {
                setShowCropModal(false);
              }}
              modalWidth={deviceType === "MOBILE" ? "100vw" : width}
            >
              <>
                <ModalHeader title={"Select Crop"} content={<></>} />
                <div className="py-3 px-6">
                  <CropSelection
                    selectionMode={"single"}
                    onSelect={(crops: any) => {
                      addCropItem(crops);
                      setShowCropModal(false);
                    }}
                  />
                </div>
              </>
            </Modal>
          </>
        )}
      </div>
    </>
  );
};

export default SelectCrop;
