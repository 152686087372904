import { FaXmark } from "react-icons/fa6";
import useOrderView from "./useOrderView";
import useArrivalsView from "./useArrivalsView";
import { ChangeEvent, FC } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { TRootState } from "../../store";
import SelectDate from "../../components/ui/select-date";
import { Button, FileUploader, Input } from "../../components/ui";
import { getCurrencyNumberWithSymbol } from "../../helpers/get-currency-number";
import DocumentViewer from "../../components/commons/document-preview";
import { useParams } from "react-router";
const PaymentDetailsEdit: FC<{
  editStatus: string;
}> = ({ editStatus }) => {
  const params = useParams();
  const {
    onUploadReceiptImage,
    pattiDocuments,
    onRemoveReceiptImage,
    alltradeDetail,
    setArrivalIInfo,
    arrivalIInfo,
    confrirmArrival,
    handlePattiDateSelection,
  } = useArrivalsView({
    traderId: parseInt(params.traderId || "0"),
    tradeId: parseInt(params.tradeId || "0"),
    mode: editStatus == "edit" ? true : false,
    type: "PAYMENT",
  });
  useEffect(() => {
    if (arrivalIInfo != null) {
      setArrivalIInfo({
        ...arrivalIInfo,
        platformFeeVal: calculatePercentagefromTotal(
          arrivalIInfo?.platformFeeTotal !== ""
            ? Number(arrivalIInfo?.platformFeeTotal)
            : 0,
          Number(arrivalIInfo?.pattiAmt)
        ),
        platformFeeTotal: arrivalIInfo?.platformFeeTotal || "",
      });
    }
  }, [editStatus == "edit"]);
  const onChangeFeeTotal = (e: ChangeEvent<HTMLInputElement>) => {
    setArrivalIInfo({
      ...arrivalIInfo,
      platformFeeVal: calculatePercentagefromTotal(
        e.target.value !== "" ? Number(e.target.value) : 0,
        Number(alltradeDetail?.pattiAmt)
      ),
      platformFeeTotal: e.target.value !== "" ? parseFloat(e.target.value) : "",
    });
  };
  const onChangeFee = (e: ChangeEvent<HTMLInputElement>) => {
    setArrivalIInfo({
      ...arrivalIInfo,
      platformFeeTotal: parseFloat(
        calculateTotalFromPercentage(
          e.target.value !== "" ? parseFloat(e.target.value) : 0,
          Number(alltradeDetail?.pattiAmt)
        ).toFixed(2)
      ),
      platformFeeVal: e.target.value !== "" ? parseFloat(e.target.value) : "",
    });
  };
  const onChangeDelayChargesTotal = (e: ChangeEvent<HTMLInputElement>) => {
    setArrivalIInfo({
      ...arrivalIInfo,
      delayedChargesPer: calculatePercentagefromTotal(
        e.target.value !== "" ? Number(e.target.value) : 0,
        Number(alltradeDetail?.pattiAmt)
      ),
      delayedChargesVal:
        e.target.value !== "" ? parseFloat(e.target.value) : "",
    });
  };
  const onChangeDelayCharges = (e: ChangeEvent<HTMLInputElement>) => {
    setArrivalIInfo({
      ...arrivalIInfo,
      delayedChargesVal: parseFloat(
        calculateTotalFromPercentage(
          e.target.value !== "" ? parseFloat(e.target.value) : 0,
          Number(alltradeDetail?.pattiAmt)
        ).toFixed(2)
      ),
      delayedChargesPer:
        e.target.value !== "" ? parseFloat(e.target.value) : "",
    });
  };
  const calculateTotalFromPercentage = (
    _percentage: number,
    total: number
  ): number => (_percentage / 100) * total;
  const calculatePercentagefromTotal = (part: number, total: number) => {
    return (part / total) * 100;
  };
  return (
    <>
      <div>
        <div className="bg-light-primary px-3 py-2 rounded-t-md">
          <span className="text-sm">Payment Terms</span>
        </div>
        <div className="bg-white px-3 py-4 border rounded-b-md mb-3">
          <div className="flex gap-2 items-center w-full">
            <div className="flex flex-col flex-1">
              <span className="text-sm">Platform Fees (%)</span>
              <div className="mt-2 relative">
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={alltradeDetail?.platformFeeVal}
                  onChange={(e) => {
                    onChangeFee(e);
                  }}
                  onFocus={(e) => {
                    e.target.value === "0" &&
                      setArrivalIInfo({
                        ...arrivalIInfo,
                        platformFeeVal: "",
                      });
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col flex-1">
              <span className="text-sm">Platform Fees Amount (₹)</span>
              <div className="mt-2 relative">
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={alltradeDetail?.platformFeeTotal}
                  onChange={(e) => {
                    onChangeFeeTotal(e);
                  }}
                  onFocus={(e) => {
                    e.target.value === "0" &&
                      setArrivalIInfo({
                        ...arrivalIInfo,
                        platformFeeTotal: "",
                      });
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex-col flex-1">
              <span className="text-sm">Payment Terms</span>
              <div className="mt-2 relative">
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={alltradeDetail?.paymentTerms}
                  onChange={(e) => {
                    setArrivalIInfo({
                      ...arrivalIInfo!,
                      paymentTerms: parseFloat(e.target.value) || "",
                    });
                  }}
                  onFocus={(e) => {
                    e.target.value === "0" &&
                      setArrivalIInfo({
                        ...arrivalIInfo,
                        paymentTerms: "",
                      });
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col flex-1">
              <span className="text-sm">
                Due Date<span className="color-red">*</span>
              </span>
              <SelectDate
                onChange={handlePattiDateSelection}
                selectedDate={alltradeDetail?.dueDate!}
              />
            </div>
          </div>
          <div className="pt-3">
            <span className="text-sm">Delayed Payment Charges (%)</span>
            <div className="mt-2 relative">
              <Input
                label=""
                type="number"
                required={true}
                value={alltradeDetail?.delayedChargesVal}
                onChange={(e) => {
                  onChangeDelayCharges(e);
                }}
                onFocus={(e) => {
                  e.target.value === "0" &&
                    setArrivalIInfo({
                      ...arrivalIInfo,
                      delayedChargesVal: "",
                    });
                }}
              />
            </div>
          </div>
          <div className="pt-3">
            <span className="text-sm">Delayed Payment Amount (₹)</span>
            <div className="mt-2 relative">
              <Input
                label=""
                type="number"
                required={true}
                value={alltradeDetail?.delayedChargesVal}
                onChange={(e) => {
                  onChangeDelayChargesTotal(e);
                }}
                onFocus={(e) => {
                  e.target.value === "0" &&
                    setArrivalIInfo({
                      ...arrivalIInfo,
                      delayedChargesVal: "",
                    });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PaymentDetailsEdit;
