import moment from "moment";
export function getCurrencyNumberWithSymbol(number, allowZero = false) {
  if (allowZero && number == 0) {
    return "₹0";
  }
  return number == null || number == 0
    ? ""
    : number.toLocaleString("en-IN", {
        maximumFractionDigits: 2,
        style: "currency",
        currency: "INR",
      });
}
export function getCurrencyNumberWithOutSymbol(number) {
  return number == null || number == 0
    ? ""
    : number
        .toLocaleString("en-IN", {
          maximumFractionDigits: 2,
          style: "currency",
          currency: "INR",
        })
        .replace("₹", "");
}
export function getCurrencyNumberWithOneDigit(number) {
  return number == null || number == 0 ? "" : number.toFixed(1);
}
export function getCurrencyNumberForWastage(number) {
  return number == null || number == 0 ? "" : number;
}
export function getMaskedMobileNumber(number) {
  return number.replace(/.(?=.{4})/g, "X");
}
export function getQuantityData(qty, qtyUnit, weight) {
  console.log("hi,", qty, qtyUnit, weight);
  var qtyData = {
    majorUnitVal: `${
      (qty === 0 ? "" : getCurrencyNumberWithOneDigit(qty)) +
      (qtyUnit.toLowerCase() === "loads" ||
      qtyUnit.toLowerCase() === "pieces" ||
      qtyUnit.toLowerCase() === "kgs"
        ? ""
        : " " + getCropUnit(qtyUnit))
    } ${
      qty === 0 || qty === null
        ? ""
        : weight === 0 || weight === null
        ? ""
        : " | "
    }`,
    minorUnitVal:
      getCurrencyNumberWithOneDigit(weight) +
      (qtyUnit.toLowerCase() === "loads" ||
      qtyUnit.toLowerCase() === "pieces" ||
      qtyUnit.toLowerCase() === "kgs"
        ? getCropUnit(qtyUnit)
        : weight === 0 || weight === null
        ? ""
        : " KGS"),
  };
  return qtyData;
}
const getCropUnit = (unit) => {
  var unitType = "";
  switch (unit.toLowerCase()) {
    case "crates":
      unitType = "C";
      break;
    case "boxes":
      unitType = "BX";
      break;
    case "bags":
      unitType = "BG";
      break;
    case "sacs":
      unitType = "S";
      break;
    case "loads":
      unitType = "LDS";
      break;
    case "pieces":
      unitType = "PCS";
      break;
    case "kgs":
      unitType = "KGS";
      break;
    default:
      unitType = "KGS";
      break;
  }
  return unitType;
};
export function getWastage(wastage, qtyUnit, rateType) {
  return `${
    wastage !== 0
      ? wastage !== null
        ? " - " +
          getCurrencyNumberWithOneDigit(wastage) +
          (qtyUnit.toLowerCase() === "pieces"
            ? getCropUnit(qtyUnit)
            : qtyUnit.toLowerCase() === "loads"
            ? ""
            : rateType === "RATE_PER_KG"
            ? "KGS"
            : getCropUnit(qtyUnit))
        : ""
      : ""
  }`;
}
export function totalBagsValue(bags) {
  var totalValue = 0;
  bags.map((item) => {
    totalValue += item.weight;
  });
  return parseFloat(totalValue.toFixed(2));
}
export function isEditBillStatus(date, settingsDate) {
  if (date != "") {
    var currentDate = moment(new Date());
    var billDateVal = moment(new Date(date));
    var days_diff = currentDate.diff(billDateVal, "days");
    console.log(days_diff, settingsDate, "dateif");
    if (days_diff < settingsDate) {
      return true;
    }
  }
  return false;
}
export function getTimeformat12(timeString) {
  const [hourString, minute] = timeString.split(":");
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
}
export function number2words(n) {
  var num =
    "zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(
      " "
    );
  var tens = "twenty thirty forty fifty sixty seventy eighty ninety".split(" ");

  if (n < 20) return num[n]; // Numbers less than 20
  var digit = n % 10;

  if (n < 100)
    return tens[Math.floor(n / 10) - 2] + (digit ? "-" + num[digit] : "");

  if (n < 1000)
    return (
      num[Math.floor(n / 100)] +
      " hundred" +
      (n % 100 == 0 ? "" : " " + number2words(n % 100))
    );

  if (n < 100000)
    return (
      number2words(Math.floor(n / 1000)) +
      " thousand" +
      (n % 1000 != 0 ? " " + number2words(n % 1000) : "")
    );

  if (n < 10000000)
    return (
      number2words(Math.floor(n / 100000)) +
      " lakh" +
      (n % 100000 != 0 ? " " + number2words(n % 100000) : "")
    );

  if (n < 1000000000)
    return (
      number2words(Math.floor(n / 10000000)) +
      " crore" +
      (n % 10000000 != 0 ? " " + number2words(n % 10000000) : "")
    );

  return "Number too large"; // Catch-all for very large numbers
}

export function numberToWordsWithDecimals(amount) {
  const integerPart = Math.floor(amount);
  const decimalPart = Math.round((amount - integerPart) * 100); // Get decimal part as whole number

  let words = number2words(integerPart) + " rupees"; // Add "rupees" after the integer part

  if (decimalPart > 0) {
    words += " and " + number2words(decimalPart) + " paise"; // Handle decimal part with "paise"
  } else {
    words += " only"; // Add "only" if there are no decimal points
  }

  return toCamelCase(words);
}
function toCamelCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
export default {
  getCurrencyNumberWithSymbol,
  getCurrencyNumberWithOutSymbol,
  getCurrencyNumberWithOneDigit,
  getMaskedMobileNumber,
  getQuantityData,
  getWastage,
  totalBagsValue,
  isEditBillStatus,
  getTimeformat12,
  number2words,
  numberToWordsWithDecimals,
};
