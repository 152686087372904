import { ArrowBack } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PartnerInfo from "../../components/partner-info";
import edit from "../../assets/images/edit_round.svg";
import { Button } from "../../components/ui";
import Modal from "../../components/ui/modal";
import ModalHeader from "../../components/ui/modal/modal-header";
import market from "../../assets/images/mandi.svg";
import ContactInfo from "../supply/contact-info";
import AssignOffTaker from "../supply/assign-off-taker";
import EditPostRequest from "../supply/edit-post-request";
import EditSupplyPost from "../supply/edit-supply-post";
import call from "../../assets/images/call.svg";
import useDemandDetailsById from "./useDemandDetailsByID";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  updateActiveTradeTab,
  updateProposalCropImgs,
  updateTraderContact,
} from "../../reducers/tradeSlice";
import CropImageGallery from "../supply/crop-image-gallery";
import EditDemandPost from "./edit-demand";
import NodataAvailable from "../../assets/images/NodataAvailable.svg";
import { FaCheckCircle } from "react-icons/fa";
import { toCamelCaseWord } from "../../helpers/getText";
import { getCurrencyNumberWithOutSymbol } from "../../helpers/get-currency-number";
const DemandPostView = () => {
  const navigate = useNavigate(); // To navigate to the KYC documents page
  const params = useParams();
  const dispatch = useDispatch();
  const { demandDetails, getDemandDetail } = useDemandDetailsById({
    id: parseInt(params.postId || "0"),
  });
  useEffect(() => {
    getDemandDetail();
  }, [params]);
  const [supplyPostEdit, setSupplyPostEdit] = useState(false);
  const [viewContactInfo, setViewContactInfo] = useState(false);
  const [requestPostEdit, setRequestPostEdit] = useState(false);
  const [assignOffTakerStatus, setAssignOffTakerStatus] = useState(false);
  const [imageGalleryOpen, setImageGalleryOpen] = useState(false);
  const [propsalAccept, setAropsalAccept] = useState(false);

  return (
    <div className="">
      <div className="border overflow-hidden bg-white px-4">
        <div className="flex text-sm py-4 cursor-pointer items-center">
          <div
            className="grow-[2.5] basis-0 flex items-center gap-1"
            style={{ width: "30%" }}
            onClick={() => {
              navigate("/trades");
            }}
          >
            <ArrowBack />
            <PartnerInfo
              profilePic={demandDetails?.profilePic || ""}
              name={demandDetails?.traderName || ""}
              shortName={""}
              partnerType={demandDetails?.tradeType}
              partnerId={demandDetails?.traderId}
              partnerMobile={demandDetails?.mobile || ""}
              partyName={demandDetails?.timeAgo}
            />
          </div>

          <div className="flex gap-4 items-center" style={{ width: "25%" }}>
            <img src={market} className="w-8 h-8 " />
            <div className="flex flex-col">
              <span className="text-sm">Mandi Address </span>
              <span>
                <span className="text-sm font-semibold">
                  {demandDetails?.location
                    ? demandDetails?.location + ","
                    : "-"}
                  {demandDetails?.city ? demandDetails?.city + "," : ""}
                  {demandDetails?.state ? demandDetails?.state : ""}
                  {demandDetails?.pincode ? " " + demandDetails?.pincode : ""}
                </span>
              </span>
            </div>
          </div>
          <div className="flex gap-4 items-center" style={{ width: "20%" }}>
            <img src={call} className="w-8 h-8 " />
            <div className="flex flex-col">
              <span className="text-sm">Mobile number </span>
              <span>
                <span className="text-sm font-semibold">
                  {" "}
                  {demandDetails?.mobile || "-"}
                </span>
              </span>
            </div>
          </div>
          <div className="flex gap-4 items-center" style={{ width: "20%" }}>
            <img src={call} className="w-8 h-8 " />
            <div className="flex flex-col">
              <span className="text-sm">Alternative Mobile </span>
              <span>
                <span className="text-sm font-semibold">
                  {" "}
                  {demandDetails?.mobile || "-"}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-4">
        <div className="border br-10 px-2 bg-white">
          {" "}
          <div
            className=" border-b text-sm py-4 flex justify-between items-center"
            style={{ width: "100%" }}
          >
            <div
              className=" flex justify-between px-2 "
              style={{ width: "100%" }}
            >
              <div
                className="grow-[3] basis-0 flex flex-col"
                style={{ width: "30%" }}
              >
                <div className="flex items-center gap-x-3">
                  <div>
                    <img
                      src={demandDetails?.cropUrl}
                      alt=""
                      className="br-100 w-8 h-8 "
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm font-bold">
                      {demandDetails?.cropName}
                    </span>
                    <span className="text-xs font-medium">
                      {demandDetails?.quality
                        ? toCamelCaseWord(demandDetails?.quality) + " Quality"
                        : "-"}
                    </span>

                    <span className="text-xs">
                      Post ID :{" "}
                      <span className="color-blue">{demandDetails?.id}</span> |{" "}
                      {demandDetails?.timeAgo}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="grow-[2] basis-0 flex px-2 flex-col"
                style={{ width: "20%" }}
              >
                <span className="text-sm">Quantity</span>
                <span className="text-sm font-semibold">
                  {" "}
                  {getCurrencyNumberWithOutSymbol(demandDetails?.qty) || "-"}
                  {demandDetails?.unitType}
                </span>
              </div>
              <div
                className="grow-[2] basis-0 flex px-2 flex-col"
                style={{ width: "20%" }}
              >
                <span className="text-sm">Expected Rate</span>
                <span className="font-semibold text-sm ">
                  {" "}
                  Rs.{demandDetails?.expRate} Per KG
                </span>
              </div>
              <div
                className="grow-[1.8] basis-0 flex px-2 flex-col"
                style={{ width: "20%" }}
              >
                <span className="text-sm">Expected Date</span>
                <span className="text-sm font-semibold">
                  {" "}
                  {demandDetails?.expDate
                    ? moment(demandDetails?.expDate).format("DD-MMM-YYYY")
                    : "-"}
                </span>
              </div>
              <div
                className="grow-[2.2] basis-0 flex px-2 flex-col"
                style={{ width: "20%" }}
              >
                <span className="text-sm">Offer Validity </span>
                <span className="text-sm font-semibold">
                  {" "}
                  {demandDetails?.offerValidity || demandDetails?.validity
                    ? (demandDetails?.validity
                        ? demandDetails?.validity + " | "
                        : "") +
                      moment(demandDetails?.offerValidity).format("DD-MMM-YYYY")
                    : "-"}
                </span>
              </div>
              <div
                className="grow-[2] basis-0 flex px-2 flex-col"
                style={{ width: "20%" }}
              >
                <span className="text-sm">Requests</span>
                <span className="font-semibold text-sm ">{`${
                  demandDetails?.propsCount
                    ? `${demandDetails?.propsCount}`
                    : "-"
                }
                          `}</span>
              </div>
            </div>
            <div className="flex" style={{ width: "20%" }}>
              <div>
                {demandDetails?.status != "ASSIGNED" ? (
                  <img
                    src={edit}
                    onClick={() => {
                      setSupplyPostEdit(true);
                    }}
                    className="cursor-pointer"
                  />
                ) : (
                  <Button variant="primaryLight" className="">
                    <FaCheckCircle color="#16A02C" />
                    Status : {demandDetails?.status}
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="py-4 px-2 flex justify-between items-center">
            <div className="flex gap-4">
              {demandDetails?.imgs
                ? demandDetails?.imgs.map((crop, i) => (
                    <img
                      src={crop.url}
                      alt=""
                      className="br-10 cursor-pointer"
                      style={{ width: "60px", height: "60px" }}
                      onClick={() => {
                        setImageGalleryOpen(true);
                        dispatch(
                          updateProposalCropImgs({
                            proposalCropImages: demandDetails.imgs,
                          })
                        );
                      }}
                    />
                  ))
                : ""}
            </div>
            {demandDetails?.status != "ASSIGNED" ? (
              <Button
                variant="contained"
                className=""
                onClick={() => {
                  setAssignOffTakerStatus(true);
                }}
              >
                Assign Agri Entrepreneur
              </Button>
            ) : (
              <Button
                variant="contained"
                className=""
                onClick={() => {
                  if (demandDetails?.tradeDtls?.tradeId) {
                    navigate(
                      `/trade-detail/${demandDetails?.traderId}/${demandDetails?.tradeDtls?.tradeId}`
                    );
                  } else {
                    navigate("/trades");
                  }
                  dispatch(updateActiveTradeTab("OPEN"));
                }}
              >
                Go To Trades
              </Button>
            )}
          </div>
        </div>
      </div>
      {demandDetails?.status == "ASSIGNED" &&
        demandDetails?.tradeDtls &&
        demandDetails?.propsCount == 0 && (
          <div className="px-4 py-1">
            <div className="border-b pb-2">
              <span className="rounded-t-lg border-green-600 border-b-4 pb-2 font-medium color-primary">
                Assigned to Agri Entrepreneur
              </span>
            </div>

            <div className="bg-white border br-10 py-4 px-4 flex justify-between items-center mt-4">
              <div className="flex items-center gap-4" style={{ width: "50%" }}>
                {" "}
                <PartnerInfo
                  profilePic={""}
                  name={demandDetails?.tradeDtls?.traderName || ""}
                  shortName={""}
                  partnerType={"Trader"}
                  partnerId={demandDetails?.tradeDtls?.traderId}
                  partnerMobile={demandDetails?.tradeDtls?.mobile || ""}
                  partyName={demandDetails?.tradeDtls?.addrLine}
                />
                <div
                  className="grow-[2] basis-0 flex px-2 flex-col"
                  style={{ width: "20%" }}
                >
                  <span className="text-sm">Pickup Date</span>
                  <span className="font-semibold text-sm ">
                    {moment(demandDetails?.tradeDtls?.date).format(
                      "DD-MMM-YYYY"
                    )}
                  </span>
                </div>
                <div
                  className="grow-[2] basis-0 flex px-2 flex-col"
                  style={{ width: "20%" }}
                >
                  <span className="text-sm">Offer Price</span>
                  <span className="font-semibold text-sm ">
                    Rs. {demandDetails?.tradeDtls?.rate} Per Kg
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      <div className="px-4 py-1">
        <div className="border-b pb-2">
          <span className="rounded-t-lg border-green-600 border-b-4 pb-2 font-medium color-primary">
            {demandDetails?.status == "ASSIGNED" && demandDetails?.proposals
              ? "Accepted"
              : ""}{" "}
            Requests (
            {demandDetails?.propsCount ? demandDetails?.propsCount : "0"})
          </span>
        </div>
        <div>
          {demandDetails?.proposals ? (
            demandDetails?.proposals.map((req, index) => (
              <div className="bg-white border br-10 py-4 px-4 flex justify-between items-center mt-4">
                <div
                  className="flex items-center gap-4"
                  style={{ width: "25%" }}
                >
                  {" "}
                  <PartnerInfo
                    profilePic={req?.profilePic || ""}
                    name={req?.traderName || ""}
                    shortName={""}
                    partnerType={req?.tradeType}
                    partnerId={req?.traderId}
                    partnerMobile={""}
                    partyName={req?.addressLine}
                    distanceFrom={true}
                    distance={req?.distance ? req?.distance.toFixed(2) : ""}
                  />
                  {req?.imgs && req?.imgs.length > 0 && (
                    <div
                      className="relative cursor-pointer"
                      onClick={() => {
                        setImageGalleryOpen(true);
                        dispatch(
                          updateProposalCropImgs({
                            proposalCropImages: req?.imgs,
                          })
                        );
                      }}
                    >
                      <img
                        src={req.imgs[0].url}
                        alt=""
                        className="br-10"
                        style={{ width: "120px", height: "70px" }}
                      />
                      {req?.imgs.length > 1 && (
                        <div
                          className="br-10 absolute top-0 flex justify-center items-center"
                          style={{
                            width: "70px",
                            height: "70px",
                            backgroundColor: "#000",
                            opacity: "0.5",
                            right: "-10px",
                          }}
                        >
                          <span className="text-sm text-white font-medium">
                            +{req?.imgs.length - 1}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div
                  className="flex items-center gap-4"
                  style={{ width: "45%" }}
                >
                  <div
                    className="grow-[1.8] basis-0 flex px-2 flex-col"
                    style={{ width: "20%" }}
                  >
                    <span className="text-sm">Expected Rate</span>
                    <span className="font-semibold text-sm ">
                      Rs.{req.rate} Per KG
                    </span>
                  </div>
                  <div
                    className="grow-[1.8] basis-0 flex px-2 flex-col"
                    style={{ width: "20%" }}
                  >
                    <span className="text-sm">Expected Date</span>
                    <span className="text-sm font-semibold">
                      {" "}
                      {req.expDate
                        ? moment(req.expDate).format("DD-MMM-YYYY")
                        : "-"}
                    </span>
                  </div>
                  <div
                    className="grow-[2.2] basis-0 flex px-2 flex-col"
                    style={{ width: "20%" }}
                  >
                    <span className="text-sm">Offer Validity </span>
                    <span className="text-sm font-semibold">
                      {" "}
                      {req?.offerValidity || req?.validity
                        ? (req?.validity ? req?.validity + " | " : "") +
                          moment(req?.offerValidity).format("DD-MMM-YYYY")
                        : "-"}
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <div>
                    {demandDetails?.status != "ASSIGNED" && (
                      <img
                        src={edit}
                        className="cursor-pointer"
                        onClick={() => {
                          setRequestPostEdit(true);
                          dispatch(
                            updateTraderContact({
                              traderContactInfo: req,
                            })
                          );
                        }}
                      />
                    )}
                  </div>
                  <Button
                    variant="lightBlue"
                    className=""
                    onClick={() => {
                      setViewContactInfo(true);
                      dispatch(
                        updateTraderContact({
                          traderContactInfo: req,
                        })
                      );
                    }}
                  >
                    View Contact Info
                  </Button>
                  {/* <Button
                      variant="primaryLight"
                      className=""
                      onClick={() => {}}
                    >
                      Chat
                    </Button> */}
                  {demandDetails?.status != "ASSIGNED" && (
                    <Button
                      variant="contained"
                      className=""
                      onClick={() => {
                        setAssignOffTakerStatus(true);
                        setAropsalAccept(true);
                        dispatch(
                          updateTraderContact({
                            traderContactInfo: req,
                          })
                        );
                      }}
                    >
                      Accept
                    </Button>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="flex items-center justify-center py-8 mt-4 ">
              <img src={NodataAvailable} alt="image" />
            </div>
          )}
        </div>
      </div>
      {supplyPostEdit && (
        <>
          <Modal
            open={supplyPostEdit}
            onClose={() => {
              setSupplyPostEdit(false);
            }}
            width="40vw"
          >
            <>
              <ModalHeader title={"Edit Demand "} content={<></>} />
              <div className="py-3 px-6">
                <EditDemandPost
                  onModalClose={setSupplyPostEdit}
                  type="Demand"
                />
              </div>
            </>
          </Modal>
        </>
      )}
      {viewContactInfo && (
        <>
          <Modal
            open={viewContactInfo}
            onClose={() => {
              setViewContactInfo(false);
            }}
            width="40vw"
          >
            <>
              <ModalHeader title={"Buyer Contact Info"} content={<></>} />
              <div className="py-3 px-6">
                <ContactInfo
                  onModalClose={setViewContactInfo}
                  data={demandDetails}
                />
              </div>
            </>
          </Modal>
        </>
      )}
      {requestPostEdit && (
        <>
          <Modal
            open={requestPostEdit}
            onClose={() => {
              setRequestPostEdit(false);
            }}
            width="40vw"
          >
            <>
              <ModalHeader title={"Edit Request "} content={<></>} />
              <div className="py-3 px-6">
                <EditPostRequest
                  onModalClose={setRequestPostEdit}
                  type={"DEMAND"}
                />
              </div>
            </>
          </Modal>
        </>
      )}
      {assignOffTakerStatus && (
        <>
          <Modal
            open={assignOffTakerStatus}
            onClose={() => {
              setAssignOffTakerStatus(false);
            }}
            width="50vw"
          >
            <>
              <ModalHeader
                title={`Demand Post ID : ${demandDetails?.id}  `}
                content={<></>}
              />
              <div className="py-3 px-6">
                <AssignOffTaker
                  onModalClose={setAssignOffTakerStatus}
                  type={"DEMAND"}
                  fromPropsal={propsalAccept}
                />
              </div>
            </>
          </Modal>
        </>
      )}
      {imageGalleryOpen}
      {imageGalleryOpen && (
        <>
          <Modal
            open={imageGalleryOpen}
            onClose={() => {
              setImageGalleryOpen(false);
            }}
            width="40vw"
          >
            <>
              <ModalHeader title={demandDetails?.cropName} content={<></>} />
              <div className="py-3 px-6">
                <CropImageGallery onModalClose={setImageGalleryOpen} />
              </div>
            </>
          </Modal>
        </>
      )}
    </div>
  );
};

export default DemandPostView;
