import { FC, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FaSearch, FaEdit, FaSave } from "react-icons/fa";
import { FaDownload, FaFile, FaXmark } from "react-icons/fa6";
import moment from "moment";
import {
  Button,
  Card,
  TabsList,
  PanelSlider,
  Input,
  FileUploader,
} from "../../components/ui";
import Modal from "../../components/ui/modal";
import TradeStatus from "./trade-status";
import DocumentPreview from "../../components/commons/document-preview";
import Trades from "../../components/home/trades";
import {
  qtyValues,
  getTradeStatus,
  getTradeStatusDisplayTime,
} from "../../helpers/getText";
import {
  getCurrencyNumberWithOutSymbol,
  getCurrencyNumberWithSymbol,
  getTimeformat12,
} from "../../helpers/get-currency-number";
import download from "../../assets/images/download_pdf.svg";
import defultProfilePic from "../../assets/images/single_bill.svg";
import cancelIcon from "../../assets/images/close.svg";
import closeActiveIcon from "../../assets/images/closed_active.svg";
import useOrderView from "./useOrderView";
import Paymentdetail from "./payment-detail";
import RecordPayment from "./record-payment";
import TradeDetailsForm from "./trade-detail-edit";
import ArrivalConfirmation from "./arrival-detail-edit";
import PattiFinalization from "./patti-finalization-edit";
import PaymentDetailsEdit from "./payment-details-edit";
import ModalHeader from "../../components/ui/modal/modal-header";
import TradeDocs from "./trade-docs";
import ArrivalDocs from "./arrival-docs";
import { updatePaymentUpdateStatus } from "../../reducers/tradeSlice";
import { useDispatch } from "react-redux";
import PattiDocs from "./patti-docs";
import InvoiceEdit from "./invoice-details-edit";
import { ArrowBack } from "@mui/icons-material";
import doc from "../../assets/images/doc.svg";
import TermsAcceptance from "./terms-acceptance";
import TermsAdvance from "./terms-advance";
import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoiceDocs from "./invoiceDocs";
import TapalDocs from "./tapalDocs";
import TaxDocs from "./finalTaxDocs";
import PattiDocsPDf from "./pattiDocs";
const TradeDetail = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [activePaymentTab, setActivePaymentTab] = useState("outward_payment");
  const [activePattiTab, setActivePattiTab] = useState("source_patti");
  const {
    tradeDetail,
    showCloseTradeAlert,
    getTradeDetail,
    onChangeTradeStatus,
    setShowCloseTradeAlert,
    onUploadReceiptImage,
    onRemoveReceiptImage,
    invoiceDocuments,
    receiptsDocuments,
    otherDocuments,
    arivalDocuments,
    pattiDocuments,
    paymentDocuments,
    saveTradeDetail,
    editDetail,
    setEditDetail,
    role,
  } = useOrderView({
    traderId: parseInt(params.traderId || "0"),
    tradeId: parseInt(params.tradeId || "0"),
    activePaymentTab,
  });
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("trade_details");

  const [openRecordPayment, setOpenRecordPayment] = useState(false);
  const [seachText, setSearchText] = useState("");
  const [showTradeForm, setShowTradeForm] = useState(false);
  const [showArrivalForm, setShowArrivalForm] = useState(false);
  const [showPattiForm, setShowPattiForm] = useState(false);
  const [showInvoiceForm, setShowInvoiceForm] = useState(false);
  const [termsAcceptance, setTermsAcceptance] = useState(false);
  const [termsAdvance, setTermsAdvance] = useState(false);
  const [pendingStatus, setPendingStatus] = useState(false);
  const [arrivalStatus, setArrivalStatus] = useState(false);
  const [pattiStatus, setPattiStatus] = useState(false);
  const [acceptedStatus, setAcceptedStatus] = useState(false);
  useEffect(() => {
    setActiveTab(activeTab);
    setActivePattiTab("source_patti");
    getTradeDetail();
  }, [params]);
  useEffect(() => {
    setAcceptedStatus(false);
    setArrivalStatus(false);
    setPattiStatus(false);
    setPendingStatus(false);
    if (tradeDetail !== null) {
      const tradeStatusVal = tradeDetail?.tradeStatus;
      if ((tradeDetail?.progress).length > 0) {
        for (let status of tradeDetail?.progress!) {
          if (status.progState == "PENDING") {
            setPendingStatus(true);
          }
          if (status.progState == "ACCEPTED" || tradeStatusVal == "ACCEPTED") {
            setAcceptedStatus(true);
          }
          if (
            status.progState == "ARL_CONFIRMED" ||
            tradeStatusVal == "ARL_CONFIRMED"
          ) {
            setAcceptedStatus(true);
            setArrivalStatus(true);
            if (tradeDetail?.tradeMode == "ORDER") {
              setPattiStatus(true);
            }
          }
          if (
            status.progState == "PATTI_FINALIZED" ||
            tradeStatusVal == "PATTI_FINALIZED"
          ) {
            setPattiStatus(true);
          }
        }
      } else {
        if (tradeStatusVal == "PENDING") {
          setPendingStatus(true);
        }
        if (tradeStatusVal == "ACCEPTED") {
          setAcceptedStatus(true);
        }
        if (tradeStatusVal == "ARL_CONFIRMED") {
          setAcceptedStatus(true);
          setArrivalStatus(true);
        }
        if (tradeStatusVal == "PATTI_FINALIZED") {
          setPattiStatus(true);
        }
      }
    }
  }, [tradeDetail?.tradeStatus, tradeDetail?.progress, params]);
  return (
    <>
      <div className="flex h-full">
        <div className="basis-1/4 border-r p-2 h-full flex-shrink-0">
          <div className="relative">
            <FaSearch
              className="absolute color-primary"
              style={{ top: "17px", left: "12px" }}
            />
            <Input
              palceholder="Search By Trade Id"
              value={seachText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              inputStyle={{ textIndent: "20px" }}
            />
          </div>
          <Card
            titile={
              searchParams.get("tab") === "CLOSED"
                ? "Closed Trades"
                : "Open Trades"
            }
            titileClassName="bg-light-primary"
            className="mt-2"
          >
            <div style={{ maxHeight: "calc(100vh - 12rem)", overflow: "auto" }}>
              <Trades
                status={
                  searchParams.get("tab") === "CLOSED" ? "CLOSED" : "OPEN"
                }
                displayMode="list"
                searchText={seachText}
              />
            </div>
          </Card>
        </div>
        <div className="grow py-2">
          <div className="flex items-center py-2 border-b bg-white">
            <div className="text-lg px-2 font-semibold">
              <span
                className="cursor-pointer"
                onClick={() => {
                  navigate("/trades");
                }}
              >
                <ArrowBack />
              </span>
              {/* Global ID: {params.tradeId} */}
            </div>
            <div className="">
              <span className="text-lg px-2 font-semibold">
                Global ID: {params.tradeId}{" "}
              </span>
              <p className="text-base color-sub-text px-2">
                Trade ID: {tradeDetail?.traderSeq}
              </p>
            </div>
            {tradeDetail?.tradeStatus !== "CANCEL" &&
              tradeDetail?.tradeStatus !== "CLOSED" &&
              role == "ADMIN" && (
                <div className="flex gap-2 ml-auto pr-4">
                  {/* <Button
                    onClick={() => {
                      setOpenRecordPayment(true);
                      dispatch(
                        updatePaymentUpdateStatus({
                          paymentUpdateStatus: false,
                        })
                      );
                    }}
                  >
                    RECORD PAYMENT
                  </Button> */}
                  {/* <Button variant="outlined">EDIT</Button> */}
                  <Button
                    variant="danger"
                    onClick={() => {
                      onChangeTradeStatus(true, "CLOSED");
                    }}
                  >
                    CLOSE TRADE
                  </Button>
                </div>
              )}
          </div>
          <div>
            <div className="bg-white p-4">
              {tradeDetail && (
                <div className="flex w-full justify-between border br-10 p-4 gap-2">
                  <div className="flex grow-[3] items-center br-10 border p-2">
                    <img
                      src={defultProfilePic}
                      alt="Trader"
                      className="w-10 h-10 rounded-full mr-4"
                    />
                    <div>
                      <span className="font-semibold text-sm">
                        From: {tradeDetail.srcTradeName}
                      </span>
                      <div className="text-xs">
                        <div>
                          Trader - {tradeDetail.srcPartyId} |{" "}
                          {tradeDetail.srcMobile}
                        </div>
                        <div>{tradeDetail.srcName}</div>
                        <div className="color-sub-text">
                          {tradeDetail.srcCity}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex grow-[3] items-center p-2 br-10 border">
                    <img
                      src={defultProfilePic}
                      alt="Trader"
                      className="w-10 h-10 rounded-full mr-3"
                    />
                    <div>
                      <span className="font-semibold text-sm">
                        To: {tradeDetail.destTradeName}
                      </span>
                      <div className="text-xs">
                        <div>
                          Trader - {tradeDetail.destPartyId} |{" "}
                          {tradeDetail.destMobile}
                        </div>
                        <div>{tradeDetail.destName}</div>
                        <div className="color-sub-text text-xs">
                          {tradeDetail.destCity}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" grow-[2]">
                    <TradeStatus
                      trade_status={getTradeStatus(
                        tradeDetail.tradeStatus == "PMT_COMPLETED" &&
                          tradeDetail.srcTradeStatus == "PMT_COMPLETED"
                          ? "PMT_COMPLETED"
                          : tradeDetail.tradeStatus == "PMT_PENDING" ||
                            tradeDetail.srcTradeStatus == "PMT_PENDING"
                          ? "PMT_PENDING"
                          : tradeDetail.tradeStatus == "PMT_COMPLETED" ||
                            tradeDetail.srcTradeStatus == "PMT_COMPLETED"
                          ? "PMT_PENDING"
                          : tradeDetail.tradeStatus,
                        tradeDetail.paidAmt,
                        tradeDetail.arlTime ? tradeDetail?.arlTime : "",
                        tradeDetail.pattiAmt,
                        tradeDetail.totalPayable
                      )}
                      time={getTradeStatusDisplayTime({
                        status:
                          tradeDetail.tradeStatus == "PMT_COMPLETED" &&
                          tradeDetail.srcTradeStatus == "PMT_COMPLETED"
                            ? "PMT_COMPLETED"
                            : tradeDetail.tradeStatus == "PMT_PENDING" ||
                              tradeDetail.srcTradeStatus == "PMT_PENDING"
                            ? "PMT_PENDING"
                            : tradeDetail.tradeStatus == "PMT_COMPLETED" ||
                              tradeDetail.srcTradeStatus == "PMT_COMPLETED"
                            ? "PMT_PENDING"
                            : tradeDetail.tradeStatus,
                        tradeCreateTime: tradeDetail.timeStamp,
                        tradeAcceptedTime: tradeDetail.tradeAcceptedTime,
                        arrivalTime:
                          tradeDetail.arlDate + " " + tradeDetail.arlTime,
                        paidAmt: tradeDetail.paidAmt,
                        totalPayable: tradeDetail.totalPayable || 0,
                        tradeClosedTime: tradeDetail?.updatedOn,
                      })}
                      onChangeTradeStatus={onChangeTradeStatus}
                      roleType={role}
                    />
                  </div>
                </div>
              )}
            </div>

            <TabsList
              onChange={(tab) => {
                setActiveTab(tab.value);
              }}
              activeTab={activeTab}
              data={[
                { label: "Trade Details", value: "trade_details" },
                { label: "Arrival Details", value: "arival_details" },
                {
                  label: `${
                    tradeDetail?.tradeMode == "ORDER"
                      ? "Invoice Details"
                      : "Patti Details"
                  }`,
                  value: `${
                    tradeDetail?.tradeMode == "ORDER"
                      ? "invoice_details"
                      : "pati_details"
                  }`,
                },
                { label: "Payment Details", value: "payment_details" },
                { label: "Download PDFs", value: "download_pdfs" },
              ]}
            />
          </div>
          <div
            style={{
              backgroundColor: "#EFF3EF",
            }}
          >
            <div
              className="overflow-auto"
              style={{
                height: "calc(100vh - 22rem)",
              }}
            >
              {tradeDetail ? (
                <>
                  <div className="h-full overflow-auto">
                    {activeTab === "trade_details" ? (
                      <>
                        <div
                          className="p-4 flex gap-4"
                          style={{ backgroundColor: "#EFF3EF" }}
                        >
                          <div style={{ width: "40%" }}>
                            <>
                              <Card
                                titile={
                                  <div className="flex items-center">
                                    <span>Trade Details</span>

                                    {tradeDetail?.tradeStatus != "CLOSED" &&
                                      role == "ADMIN" && (
                                        <Button
                                          variant="outlined"
                                          className="ml-auto gap-1"
                                          size="small"
                                          onClick={() => {
                                            setEditDetail({
                                              ...editDetail,
                                              section: "trade_detail_document",
                                              mode: "edit",
                                            });
                                            setShowTradeForm(true);
                                          }}
                                        >
                                          <FaEdit className="" />
                                          Edit
                                        </Button>
                                      )}
                                  </div>
                                }
                                titileClassName="px-4"
                                className="bg-gray-50 p-2"
                              >
                                {tradeDetail?.lineItems.map((crop, i) => (
                                  <div className="flex items-center px-4 py-2 border-b gap-2">
                                    <img
                                      src={crop.imageUrl}
                                      alt="user_profile_pic"
                                      className="w-12 h-12 br-100"
                                      style={{ maxWidth: "initial" }}
                                    />
                                    <div className="flex flex-col">
                                      <span className="font-medium text-sm">
                                        {crop.cropName}
                                      </span>
                                      <span className="text-xs font-medium">
                                        {qtyValues(
                                          crop.qty,
                                          crop.qtyUnit,
                                          crop.weight,
                                          crop.weightUnit
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                                <div className="flex justify-between gap-2 border-b px-2 py-2">
                                  <div className="flex flex-1 flex-col">
                                    <span className="text-sm">
                                      Dispatch Date :
                                    </span>
                                    <span className="font-semibold text-base">
                                      {tradeDetail.date
                                        ? moment(tradeDetail.date).format(
                                            "DD-MMM-YYYY"
                                          )
                                        : "-"}
                                    </span>
                                  </div>
                                  <div className="flex flex-1 flex-col">
                                    <span className="text-sm">Trade Type:</span>
                                    <span className="font-semibold text-base">
                                      {tradeDetail?.tradeMode == "ORDER"
                                        ? "Order Mode"
                                        : "Commission Mode"}
                                    </span>
                                  </div>
                                </div>

                                {tradeDetail?.tradeMode == "ORDER" && (
                                  <>
                                    <div className="flex py-3 px-2 border-b justify-between items-center">
                                      <span className="flex flex-1 flex-col">
                                        <span className="text-sm">
                                          Original Rate{" "}
                                          {tradeDetail?.lineItems.length > 0 &&
                                          tradeDetail?.lineItems[0]
                                            .weightUnit == "RATE_PER_UNIT"
                                            ? "(Per UNIT)"
                                            : "(Per KG)"}
                                          :
                                        </span>

                                        <span
                                          className={"font-semibold text-base"}
                                        >
                                          {(tradeDetail?.lineItems.length > 0 &&
                                            getCurrencyNumberWithSymbol(
                                              tradeDetail?.lineItems[0].rate
                                            )) ||
                                            "-"}
                                        </span>
                                      </span>
                                      <span className="flex flex-1 flex-col">
                                        <span className="text-sm">
                                          Final Rate{" "}
                                          {tradeDetail?.lineItems.length > 0 &&
                                          tradeDetail?.lineItems[0]
                                            .weightUnit == "RATE_PER_UNIT"
                                            ? "(Per UNIT)"
                                            : "(Per KG)"}
                                          :
                                        </span>

                                        <span
                                          className={"font-semibold text-base"}
                                        >
                                          {(tradeDetail?.lineItems.length > 0 &&
                                            getCurrencyNumberWithSymbol(
                                              tradeDetail?.lineItems[0].destRate
                                            )) ||
                                            "-"}
                                        </span>
                                      </span>
                                    </div>
                                    <div className="flex py-3 px-2 border-b justify-between items-center">
                                      <span className="flex flex-1 flex-col">
                                        <span className="text-sm">
                                          Commodity Cost:
                                        </span>

                                        <span
                                          className={"font-semibold text-base"}
                                        >
                                          {getCurrencyNumberWithSymbol(
                                            tradeDetail?.cmdityCost
                                          ) || "-"}
                                        </span>
                                      </span>
                                      <span className="flex flex-1 flex-col">
                                        <span className="text-sm">
                                          Invoice Amount:
                                        </span>

                                        <span
                                          className={"font-semibold text-base"}
                                        >
                                          {getCurrencyNumberWithSymbol(
                                            tradeDetail?.invAmt
                                          ) || "-"}
                                        </span>
                                      </span>
                                    </div>
                                  </>
                                )}
                                <div className="flex justify-between gap-2 border-b px-2 py-2">
                                  <div className="flex flex-1 flex-col">
                                    <span className="text-sm">
                                      Freight Amount :
                                    </span>
                                    <span className="font-semibold text-base">
                                      {getCurrencyNumberWithSymbol(
                                        tradeDetail?.freightAmt
                                      ) || "-"}
                                    </span>
                                  </div>
                                  <div className="flex flex-1 flex-col">
                                    <span className="text-sm">
                                      Freight Advance :
                                    </span>
                                    <span className="font-semibold text-base">
                                      {getCurrencyNumberWithSymbol(
                                        tradeDetail?.freightAdv
                                      ) || "-"}
                                    </span>
                                  </div>
                                </div>
                                <div className="flex  justify-between gap-2 border-b px-2 py-2">
                                  <div className="flex flex-1 flex-col">
                                    <span className="text-sm">
                                      Balance Freight :
                                    </span>
                                    <span className={"font-semibold text-base"}>
                                      {getCurrencyNumberWithSymbol(
                                        Number(tradeDetail?.freightAmt) -
                                          Number(tradeDetail?.freightAdv)
                                      ) || "-"}
                                    </span>
                                  </div>
                                </div>
                                <div className="flex  justify-between gap-2 px-2 py-2">
                                  <div className="flex flex-1 flex-col">
                                    <span className="text-sm">
                                      Truck Number :
                                    </span>
                                    <span className={"font-semibold text-base"}>
                                      {tradeDetail?.srcVehNum || "-"}
                                    </span>
                                  </div>
                                </div>
                              </Card>

                              <Card
                                titile="Destination Details"
                                className="mt-3"
                              >
                                <div className="flex justify-between gap-2 py-2 px-2">
                                  <div className="flex-1">
                                    <div className="text-sm">Trade Name :</div>
                                    <div className="font-semibold">
                                      {tradeDetail.destTradeName}
                                    </div>
                                  </div>

                                  <div className="flex-1">
                                    <div className="text-sm">Market Name :</div>
                                    <div className="font-semibold text-sm">
                                      {tradeDetail.destMarketName || "--"}
                                    </div>
                                  </div>
                                </div>
                                <div className="px-2 pb-2">
                                  <div className="text-sm">Address :</div>
                                  <div
                                    style={{ width: "70%" }}
                                    className="text-sm font-semibold"
                                  >
                                    {tradeDetail.destAddrLine ||
                                    tradeDetail.destDist ||
                                    tradeDetail.destCity ? (
                                      <span>
                                        {tradeDetail.destAddrLine},{" "}
                                        {tradeDetail.destDist},
                                        {tradeDetail.destState}{" "}
                                        {tradeDetail.destPincode}
                                      </span>
                                    ) : (
                                      "--"
                                    )}
                                  </div>
                                </div>
                              </Card>

                              <Card titile="Source Details" className="mt-3">
                                <div className="flex justify-between gap-2 py-2 px-2">
                                  <div className="flex-1">
                                    <div className="text-sm">Trade Name :</div>
                                    <div className="text-base font-semibold">
                                      {tradeDetail.srcTradeName}
                                    </div>
                                  </div>

                                  <div className="flex-1">
                                    <div className="text-sm">Market Name :</div>
                                    <div className="text-base font-semibold">
                                      {tradeDetail.srcMarketName || "--"}
                                    </div>
                                  </div>
                                </div>
                                <div className="px-2 pb-2">
                                  <div className="text-sm">Address :</div>
                                  <div
                                    className="text-sm font-semibold"
                                    style={{ width: "70%" }}
                                  >
                                    {tradeDetail.srcAddrLine ||
                                    tradeDetail.srcState ||
                                    tradeDetail.srcDist ? (
                                      <span>
                                        {tradeDetail.srcAddrLine},
                                        {tradeDetail.srcDist},{" "}
                                        {tradeDetail.srcState}{" "}
                                        {tradeDetail.srcPincode}
                                      </span>
                                    ) : (
                                      "--"
                                    )}
                                  </div>
                                </div>
                              </Card>
                              <Card
                                titile="Platform Facilitator Details"
                                className="mt-3"
                              >
                                <div className="flex justify-between py-2 px-2">
                                  <div className="flex-1">
                                    <div className="text-sm">Company :</div>
                                    <div className="text-sm font-semibold">
                                      ONO ARK INDIA PVT. LTD.
                                    </div>
                                  </div>
                                </div>
                                <div className="flex justify-between gap-2 py-2 px-2">
                                  <div className="flex-1">
                                    <div className="text-sm">
                                      Relatiopnship Manager :
                                    </div>
                                    <div className="text-sm font-semibold">
                                      {tradeDetail.srcRmName || "--"}
                                    </div>
                                  </div>

                                  <div className="flex-1">
                                    <div className="text-sm">Contact No :</div>
                                    <div className="text-sm font-semibold">
                                      {tradeDetail.srcRmMobile || "--"}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex justify-between gap-2 py-2 px-2">
                                  <div className="flex-1">
                                    <div className="text-sm">
                                      Relatiopnship Manager :
                                    </div>
                                    <div className="text-sm font-semibold">
                                      {tradeDetail.destRmName || "--"}
                                    </div>
                                  </div>

                                  <div className="flex-1">
                                    <div className="text-sm">Contact No :</div>
                                    <div className="text-sm font-semibold">
                                      {tradeDetail.destRmMobile || "--"}
                                    </div>
                                  </div>
                                </div>
                              </Card>
                            </>
                          </div>
                          <div style={{ width: "60%" }}>
                            <TradeDocs />
                          </div>
                        </div>
                      </>
                    ) : activeTab === "arival_details" ? (
                      <>
                        <div
                          className="p-4 flex gap-4"
                          style={{ backgroundColor: "#EFF3EF" }}
                        >
                          <div style={{ width: "40%" }}>
                            <>
                              <Card
                                titile={
                                  <div className="flex items-center">
                                    <span>Arrival Details</span>
                                    {tradeDetail?.tradeStatus != "CLOSED" &&
                                      role == "ADMIN" && (
                                        <Button
                                          variant="outlined"
                                          className="ml-auto gap-1"
                                          size="small"
                                          onClick={() => {
                                            setEditDetail({
                                              ...editDetail,
                                              section: "arival_document",
                                              mode: "edit",
                                            });
                                            setShowArrivalForm(true);
                                          }}
                                        >
                                          <FaEdit className="" />
                                          Edit
                                        </Button>
                                      )}
                                  </div>
                                }
                              >
                                <div className="px-4">
                                  <div className="flex justify-between py-2 gap-2 border-b">
                                    <div className="flex flex-1 flex-col">
                                      <span className="color-sub-text text-sm">
                                        Arrival Date :
                                      </span>
                                      <span className="font-semibold">
                                        {tradeDetail.arlDate
                                          ? moment(tradeDetail.arlDate).format(
                                              "DD-MMM-YYYY"
                                            )
                                          : "-"}
                                      </span>
                                    </div>
                                    <div className="flex flex-1 flex-col">
                                      <span className="color-sub-text text-sm">
                                        Arrival Time :
                                      </span>
                                      <span className="font-semibold">
                                        {tradeDetail?.arlTime != null
                                          ? tradeDetail?.arlTime != ""
                                            ? getTimeformat12(
                                                tradeDetail?.arlTime
                                              )
                                            : tradeDetail?.arlTime || "-"
                                          : tradeDetail?.arlTime || "-"}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="flex justify-between py-2 border-b gap-2 ">
                                    <div className="flex flex-1 flex-col">
                                      <span className="color-sub-text text-sm">
                                        Freight Amount :
                                      </span>
                                      <span className="font-semibold">
                                        {getCurrencyNumberWithSymbol(
                                          tradeDetail.freightAmt || "-"
                                        )}
                                      </span>
                                    </div>
                                    <div className="flex flex-1 flex-col">
                                      <span className="color-sub-text text-sm">
                                        Freight Advance :
                                      </span>
                                      <span className="font-semibold">
                                        {getCurrencyNumberWithSymbol(
                                          tradeDetail.freightAdv || "-"
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="flex justify-between py-2 border-b gap-2 ">
                                    <div className="flex flex-1 flex-col">
                                      <span className="color-sub-text text-sm">
                                        Freight Balance Paid:
                                      </span>
                                      <span className="font-semibold">
                                        {getCurrencyNumberWithSymbol(
                                          tradeDetail.freightPaid || "-"
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="flex  justify-between gap-2 px-2 py-2">
                                    <div className="flex flex-1 flex-col">
                                      <span className="text-sm">
                                        Truck Number :
                                      </span>
                                      <span
                                        className={"font-semibold text-base"}
                                      >
                                        {tradeDetail?.destVehNum || "-"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Card>
                              <Card titile="Weight Details" className="mt-3">
                                <div className="px-4">
                                  <div className="flex justify-between py-2 gap-2 border-b">
                                    <div className="flex flex-1 flex-col">
                                      <span className="color-sub-text text-sm">
                                        Weight at Source :
                                      </span>
                                      <span className="font-semibold">
                                        {(tradeDetail?.lineItems.length > 0 &&
                                          tradeDetail?.lineItems[0].weight +
                                            "KGS") ||
                                          "-"}
                                      </span>
                                    </div>
                                    <div className="flex flex-1 flex-col">
                                      <span className="color-sub-text text-sm">
                                        Weight at Destination :
                                      </span>
                                      <span className="font-semibold">
                                        {(tradeDetail?.lineItems.length > 0 &&
                                          (tradeDetail?.lineItems[0].destWeight
                                            ? tradeDetail?.lineItems[0]
                                                .destWeight + "KGS"
                                            : "-")) ||
                                          "-"}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="flex justify-between border-b py-2 gap-2 ">
                                    <div className="flex flex-1 flex-col">
                                      <span className="color-sub-text text-sm">
                                        Moisture Loss :
                                      </span>
                                      <span className="font-semibold">
                                        {tradeDetail?.lineItems.length > 0
                                          ? tradeDetail?.lineItems[0]
                                              .destWeight !=
                                            tradeDetail?.lineItems[0].weight
                                            ? getCurrencyNumberWithOutSymbol(
                                                Number(
                                                  tradeDetail?.lineItems[0]
                                                    .weight
                                                ) -
                                                  Number(
                                                    tradeDetail?.lineItems[0]
                                                      .destWeight
                                                  )
                                              ) + "KGS"
                                            : "0 KGS"
                                          : "0KGS"}
                                      </span>
                                    </div>
                                    <div className="flex flex-1 flex-col">
                                      <span className="color-sub-text text-sm">
                                        Additional Wastage :
                                      </span>
                                      <span className="font-semibold">
                                        {(tradeDetail?.lineItems.length > 0 &&
                                          (tradeDetail?.lineItems[0].addWastage
                                            ? tradeDetail?.lineItems[0]
                                                .addWastage + "KGS"
                                            : "-")) ||
                                          "-"}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="flex justify-between py-2 gap-2 border-b">
                                    <div className="flex flex-1 flex-col">
                                      <span className="color-sub-text text-sm">
                                        Net Weight :
                                      </span>
                                      <span className="font-semibold">
                                        {tradeDetail?.lineItems.length > 0 &&
                                          (tradeDetail?.lineItems[0].destWeight
                                            ? tradeDetail?.lineItems[0]
                                                .destWeight -
                                              Number(
                                                tradeDetail?.lineItems[0]
                                                  .addWastage
                                              ) +
                                              "KGS"
                                            : "-")}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Card>
                              {tradeDetail?.tradeMode == "ORDER" && (
                                <Card titile="Rate Details" className="mt-3">
                                  <div className="px-4">
                                    <div className="flex justify-between py-2 gap-2 border-b">
                                      <div className="flex flex-1 flex-col">
                                        <span className="color-sub-text text-sm">
                                          Original Rate{" "}
                                          {tradeDetail?.lineItems.length > 0 &&
                                          tradeDetail?.lineItems[0]
                                            .weightUnit == "RATE_PER_UNIT"
                                            ? "(Per UNIT)"
                                            : "(Per KG)"}
                                          :
                                        </span>
                                        <span className="font-semibold">
                                          {(tradeDetail?.lineItems.length > 0 &&
                                            getCurrencyNumberWithSymbol(
                                              tradeDetail?.lineItems[0].rate
                                            )) ||
                                            "-"}
                                        </span>
                                      </div>
                                      <div className="flex flex-1 flex-col">
                                        <span className="color-sub-text text-sm">
                                          Final Rate{" "}
                                          {tradeDetail?.lineItems.length > 0 &&
                                          tradeDetail?.lineItems[0]
                                            .weightUnit == "RATE_PER_UNIT"
                                            ? "(Per UNIT)"
                                            : "(Per KG)"}
                                          :
                                        </span>
                                        <span className="font-semibold">
                                          {(tradeDetail?.lineItems.length > 0 &&
                                            (tradeDetail?.lineItems[0].destRate
                                              ? getCurrencyNumberWithSymbol(
                                                  tradeDetail?.lineItems[0]
                                                    .destRate
                                                )
                                              : "-")) ||
                                            "-"}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="flex justify-between py-2 gap-2 ">
                                      <div className="flex flex-1 flex-col">
                                        <span className="color-sub-text text-sm">
                                          Final Invoice Amount:
                                        </span>
                                        <span className="font-semibold">
                                          {getCurrencyNumberWithSymbol(
                                            tradeDetail?.invAmt
                                          ) || "-"}
                                        </span>
                                      </div>
                                    </div>
                                    {/* <div className="flex justify-between py-2 gap-2 border-b">
                                      <div className="flex flex-1 flex-col">
                                        <span className="color-sub-text text-sm">
                                          Comments
                                        </span>
                                        <span className="font-semibold">
                                          {arrrivalComments || "-"}
                                        </span>
                                      </div>
                                    </div> */}
                                  </div>
                                </Card>
                              )}
                            </>
                          </div>
                          <div style={{ width: "60%" }}>
                            <ArrivalDocs type="ARRIVAL" />
                          </div>
                        </div>
                      </>
                    ) : activeTab === "pati_details" ? (
                      <>
                        <div
                          className="p-4"
                          style={{ backgroundColor: "#EFF3EF" }}
                        >
                          <TabsList
                            onChange={(tab) => {
                              setActivePattiTab(tab.value);
                            }}
                            activeTab={activePattiTab}
                            data={[
                              {
                                label: "Source Patti",
                                value: "source_patti",
                              },
                              {
                                label: "Destination Patti",
                                value: "destination_patti",
                              },
                            ]}
                          />
                          <div className="p-4 flex gap-4">
                            <div style={{ width: "40%" }}>
                              {activePattiTab == "destination_patti" && (
                                <Card
                                  titile={
                                    <div className="flex items-center">
                                      <span>Patti Details</span>
                                      {tradeDetail?.tradeStatus != "CLOSED" &&
                                        role == "ADMIN" && (
                                          <Button
                                            variant="outlined"
                                            className="ml-auto gap-1"
                                            size="small"
                                            onClick={() => {
                                              setEditDetail({
                                                ...editDetail,
                                                section: "patti_document",
                                                mode: "edit",
                                                type: "DEST",
                                              });
                                              setShowPattiForm(true);
                                            }}
                                          >
                                            <FaEdit className="" />
                                            Edit
                                          </Button>
                                        )}
                                    </div>
                                  }
                                  className="px-2"
                                >
                                  <div className="px-2">
                                    <div className="flex justify-between py-4 border-b">
                                      <div className="flex flex-1 flex-col">
                                        <span className="color-sub-text text-sm">
                                          {tradeDetail?.tradeMode
                                            ? tradeDetail?.tradeMode == "ORDER"
                                              ? "Invoice Amount"
                                              : "Sales Amount"
                                            : "Sales Amount"}
                                          :
                                        </span>
                                        <span className="font-semibold">
                                          {tradeDetail?.tradeMode
                                            ? tradeDetail?.tradeMode == "ORDER"
                                              ? getCurrencyNumberWithSymbol(
                                                  tradeDetail?.invAmt || 0,
                                                  true
                                                )
                                              : getCurrencyNumberWithSymbol(
                                                  tradeDetail?.pattiAmt || 0,
                                                  true
                                                )
                                            : getCurrencyNumberWithSymbol(
                                                tradeDetail?.pattiAmt || 0,
                                                true
                                              )}
                                        </span>
                                      </div>
                                      <div className="flex flex-1 flex-col">
                                        <span className="color-sub-text text-sm">
                                          Average Rate :
                                        </span>
                                        <span className="font-semibold">
                                          {Number(tradeDetail?.pattiAmt) > 0 &&
                                          Number(
                                            tradeDetail?.lineItems[0].destWeight
                                          ) > 0
                                            ? getCurrencyNumberWithSymbol(
                                                Number(tradeDetail?.pattiAmt) /
                                                  (Number(
                                                    tradeDetail?.lineItems[0]
                                                      .destWeight
                                                  ) -
                                                    Number(
                                                      tradeDetail?.lineItems[0]
                                                        .addWastage
                                                    ))
                                              ) || "-"
                                            : 0}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="flex justify-between py-4 border-b">
                                      <div className="flex flex-1 flex-col">
                                        <span className="color-sub-text text-sm">
                                          {tradeDetail?.tradeMode
                                            ? tradeDetail?.tradeMode == "ORDER"
                                              ? "Invoice"
                                              : "Patti"
                                            : "Patti"}
                                          Date :
                                        </span>
                                        <span className="font-semibold">
                                          {tradeDetail?.pattiDate &&
                                          tradeDetail?.pattiAmt > 0
                                            ? moment(
                                                tradeDetail?.pattiDate
                                              ).format("DD-MMM-YY")
                                            : "-"}
                                        </span>
                                      </div>
                                      <div className="flex flex-1 flex-col">
                                        <span className="color-sub-text text-sm">
                                          Total Payable :
                                        </span>
                                        <span className="font-semibold">
                                          {getCurrencyNumberWithSymbol(
                                            tradeDetail.totalPayable || 0,
                                            true
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="border-b mt-4">
                                      <div className="flex py-2 text-sm">
                                        <span>
                                          {" "}
                                          {tradeDetail?.tradeMode
                                            ? tradeDetail?.tradeMode == "ORDER"
                                              ? "Invoice Amount"
                                              : "Sales Amount"
                                            : "Sales Amount"}
                                          :
                                        </span>
                                        <span className="ml-auto">
                                          {tradeDetail?.tradeMode
                                            ? tradeDetail?.tradeMode == "ORDER"
                                              ? getCurrencyNumberWithSymbol(
                                                  tradeDetail?.invAmt || 0,
                                                  true
                                                )
                                              : getCurrencyNumberWithSymbol(
                                                  tradeDetail?.pattiAmt || 0,
                                                  true
                                                )
                                            : getCurrencyNumberWithSymbol(
                                                tradeDetail?.pattiAmt || 0,
                                                true
                                              )}
                                        </span>
                                      </div>
                                      <div className="flex py-2 text-sm">
                                        <span>Freight Bal Paid</span>
                                        <span className="ml-auto">
                                          {getCurrencyNumberWithSymbol(
                                            tradeDetail.freightPaid || 0,
                                            true
                                          )}
                                        </span>
                                      </div>
                                      <div className="flex py-2 text-sm">
                                        <span>Commission</span>
                                        <span className="ml-auto">
                                          {getCurrencyNumberWithSymbol(
                                            tradeDetail.comm || 0,
                                            true
                                          )}
                                        </span>
                                      </div>
                                      <div className="flex py-2 text-sm">
                                        <span>Labour Charge</span>
                                        <span className="ml-auto">
                                          {getCurrencyNumberWithSymbol(
                                            tradeDetail.lbrCharges || 0,
                                            true
                                          )}
                                        </span>
                                      </div>

                                      <div className="flex py-2 text-sm">
                                        <span>miscellaneous</span>
                                        <span className="ml-auto">
                                          {getCurrencyNumberWithSymbol(
                                            tradeDetail.misc || 0,
                                            true
                                          )}
                                        </span>
                                      </div>
                                      <div className="flex py-2 border-b text-sm">
                                        <span> Advance Paid</span>
                                        <span className="ml-auto">
                                          {getCurrencyNumberWithSymbol(
                                            tradeDetail.advancePaid || 0,
                                            true
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="flex py-2 text-sm font-semibold">
                                      <span className="">Total Payable</span>
                                      <span className="ml-auto">
                                        {getCurrencyNumberWithSymbol(
                                          tradeDetail.totalPayable || 0,
                                          true
                                        )}
                                      </span>
                                    </div>
                                    <div className="flex py-2 text-sm">
                                      <span>
                                        {" "}
                                        Platform Fees <br></br>Inclusive of
                                        Taxes
                                      </span>
                                      <span className="ml-auto">
                                        {getCurrencyNumberWithSymbol(
                                          tradeDetail.destPf || 0,
                                          true
                                        )}
                                      </span>
                                    </div>
                                    <div className="flex py-2 border-b text-sm">
                                      <span>Platform Fees Discount</span>
                                      <span className="ml-auto">
                                        {" "}
                                        {getCurrencyNumberWithSymbol(
                                          tradeDetail.destDis || 0,
                                          true
                                        )}
                                      </span>
                                    </div>
                                    <div className="flex py-2 text-sm font-semibold">
                                      <span className="color-red">
                                        Final Payable
                                      </span>
                                      <span className="ml-auto color-red">
                                        {getCurrencyNumberWithSymbol(
                                          Number(tradeDetail?.totalPayable) +
                                            Number(tradeDetail?.destPf) -
                                            Number(tradeDetail?.destDis || 0) ||
                                            0,
                                          true
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </Card>
                              )}
                              {activePattiTab == "source_patti" && (
                                <Card
                                  titile={
                                    <div className="flex items-center">
                                      <span>Patti Details</span>
                                      {tradeDetail?.tradeStatus != "CLOSED" &&
                                        role == "ADMIN" && (
                                          <Button
                                            variant="outlined"
                                            className="ml-auto gap-1"
                                            size="small"
                                            onClick={() => {
                                              setEditDetail({
                                                ...editDetail,
                                                section: "patti_document",
                                                mode: "edit",
                                                type: "SOURCE",
                                              });
                                              setShowPattiForm(true);
                                            }}
                                          >
                                            <FaEdit className="" />
                                            Edit
                                          </Button>
                                        )}
                                    </div>
                                  }
                                  className="px-2"
                                >
                                  <div className="px-2">
                                    <div className="flex justify-between py-4 border-b">
                                      <div className="flex flex-1 flex-col">
                                        <span className="color-sub-text text-sm">
                                          {tradeDetail?.tradeMode
                                            ? tradeDetail?.tradeMode == "ORDER"
                                              ? "Invoice Amount"
                                              : "Sales Amount"
                                            : "Sales Amount"}
                                          :
                                        </span>
                                        <span className="font-semibold">
                                          {tradeDetail?.tradeMode
                                            ? tradeDetail?.tradeMode == "ORDER"
                                              ? getCurrencyNumberWithSymbol(
                                                  tradeDetail?.invAmt || 0,
                                                  true
                                                )
                                              : getCurrencyNumberWithSymbol(
                                                  tradeDetail?.pattiAmt || 0,
                                                  true
                                                )
                                            : getCurrencyNumberWithSymbol(
                                                tradeDetail?.pattiAmt || 0,
                                                true
                                              )}
                                        </span>
                                      </div>
                                      <div className="flex flex-1 flex-col">
                                        <span className="color-sub-text text-sm">
                                          Average Rate :
                                        </span>
                                        <span className="font-semibold">
                                          {Number(tradeDetail?.pattiAmt) > 0
                                            ? getCurrencyNumberWithSymbol(
                                                Number(tradeDetail?.pattiAmt) /
                                                  (Number(
                                                    tradeDetail?.lineItems[0]
                                                      .destWeight
                                                  ) -
                                                    Number(
                                                      tradeDetail?.lineItems[0]
                                                        .addWastage
                                                    ))
                                              ) || "-"
                                            : 0}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="flex justify-between py-4 border-b">
                                      <div className="flex flex-1 flex-col">
                                        <span className="color-sub-text text-sm">
                                          {tradeDetail?.tradeMode
                                            ? tradeDetail?.tradeMode == "ORDER"
                                              ? "Invoice"
                                              : "Patti"
                                            : "Patti"}
                                          Date :
                                        </span>
                                        <span className="font-semibold">
                                          {tradeDetail?.pattiDate &&
                                          tradeDetail?.pattiAmt > 0
                                            ? moment(
                                                tradeDetail?.pattiDate
                                              ).format("DD-MMM-YY")
                                            : "-"}
                                        </span>
                                      </div>
                                      <div className="flex flex-1 flex-col">
                                        <span className="color-sub-text text-sm">
                                          Total Receivable :
                                        </span>
                                        <span className="font-semibold">
                                          {getCurrencyNumberWithSymbol(
                                            tradeDetail.totalReceivable || 0,
                                            true
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="border-b mt-4">
                                      <div className="flex py-2 text-sm">
                                        <span>
                                          {" "}
                                          {tradeDetail?.tradeMode
                                            ? tradeDetail?.tradeMode == "ORDER"
                                              ? "Invoice Amount"
                                              : "Sales Amount"
                                            : "Sales Amount"}
                                          :
                                        </span>
                                        <span className="ml-auto">
                                          {tradeDetail?.tradeMode
                                            ? tradeDetail?.tradeMode == "ORDER"
                                              ? getCurrencyNumberWithSymbol(
                                                  tradeDetail?.invAmt || 0,
                                                  true
                                                )
                                              : getCurrencyNumberWithSymbol(
                                                  tradeDetail?.pattiAmt || 0,
                                                  true
                                                )
                                            : getCurrencyNumberWithSymbol(
                                                tradeDetail?.pattiAmt || 0,
                                                true
                                              )}
                                        </span>
                                      </div>
                                      <div className="flex py-2 text-sm">
                                        <span>Freight Bal Paid</span>
                                        <span className="ml-auto">
                                          {getCurrencyNumberWithSymbol(
                                            tradeDetail.freightPaid || 0,
                                            true
                                          )}
                                        </span>
                                      </div>
                                      <div className="flex py-2 text-sm">
                                        <span>Commission</span>
                                        <span className="ml-auto">
                                          {getCurrencyNumberWithSymbol(
                                            tradeDetail.comm || 0,
                                            true
                                          )}
                                        </span>
                                      </div>
                                      <div className="flex py-2 text-sm">
                                        <span>Labour Charge</span>
                                        <span className="ml-auto">
                                          {getCurrencyNumberWithSymbol(
                                            tradeDetail.lbrCharges || 0,
                                            true
                                          )}
                                        </span>
                                      </div>

                                      <div className="flex py-2 text-sm">
                                        <span>miscellaneous</span>
                                        <span className="ml-auto">
                                          {getCurrencyNumberWithSymbol(
                                            tradeDetail.misc || 0,
                                            true
                                          )}
                                        </span>
                                      </div>
                                      <div className="flex py-2 text-sm">
                                        <span> Advance Received</span>
                                        <span className="ml-auto">
                                          {getCurrencyNumberWithSymbol(
                                            tradeDetail?.advanceRcvd || 0,
                                            true
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="flex py-4 text-sm font-semibold">
                                      <span className="color-primary">
                                        Total Receivable
                                      </span>
                                      <span className="ml-auto">
                                        {getCurrencyNumberWithSymbol(
                                          tradeDetail.totalReceivable || 0,
                                          true
                                        )}
                                      </span>
                                    </div>
                                    <div className="flex py-2 text-sm">
                                      <span>
                                        {" "}
                                        Platform Fees <br></br>Inclusive of
                                        Taxes
                                      </span>
                                      <span className="ml-auto">
                                        {getCurrencyNumberWithSymbol(
                                          tradeDetail.srcPf || 0,
                                          true
                                        )}
                                      </span>
                                    </div>
                                    <div className="flex py-2 border-b text-sm">
                                      <span>Platform Fees Discount</span>
                                      <span className="ml-auto">
                                        {" "}
                                        {getCurrencyNumberWithSymbol(
                                          tradeDetail.srcDis || 0,
                                          true
                                        )}
                                      </span>
                                    </div>
                                    <div className="flex py-2 text-sm font-semibold">
                                      <span className="color-primary">
                                        Final Receivable
                                      </span>
                                      <span className="ml-auto color-primary">
                                        {getCurrencyNumberWithSymbol(
                                          Number(tradeDetail?.totalReceivable) -
                                            Number(tradeDetail?.srcPf) +
                                            Number(tradeDetail?.srcDis || 0) ||
                                            0,
                                          true
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </Card>
                              )}
                            </div>
                            <div style={{ width: "60%" }}>
                              <PattiDocs type="PATTI" />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : activeTab === "invoice_details" ? (
                      <>
                        <div
                          className="p-4"
                          style={{ backgroundColor: "#EFF3EF" }}
                        >
                          <TabsList
                            onChange={(tab) => {
                              setActivePattiTab(tab.value);
                            }}
                            activeTab={activePattiTab}
                            data={[
                              {
                                label: "Source Invoice",
                                value: "source_patti",
                              },
                              {
                                label: "Destination Invoice",
                                value: "destination_patti",
                              },
                            ]}
                          />
                          <div className="p-4 flex gap-4">
                            <div style={{ width: "40%" }}>
                              {activePattiTab == "destination_patti" && (
                                <Card
                                  titile={
                                    <div className="flex items-center">
                                      <span>Invoice Details:</span>
                                      {tradeDetail?.tradeStatus != "CLOSED" &&
                                        role == "ADMIN" && (
                                          <Button
                                            variant="outlined"
                                            className="ml-auto gap-1"
                                            size="small"
                                            onClick={() => {
                                              setEditDetail({
                                                ...editDetail,
                                                section: "patti_document",
                                                mode: "edit",
                                                type: "DEST",
                                              });
                                              setShowInvoiceForm(true);
                                            }}
                                          >
                                            <FaEdit className="" />
                                            Edit
                                          </Button>
                                        )}
                                    </div>
                                  }
                                  className="px-2"
                                >
                                  <div className="px-2">
                                    <div className="mt-4">
                                      <div className="flex py-2 text-sm">
                                        <span> Invoice Amount :</span>
                                        <span className="ml-auto">
                                          {getCurrencyNumberWithSymbol(
                                            tradeDetail?.invAmt || 0,
                                            true
                                          )}
                                        </span>
                                      </div>
                                      <div className="flex py-2 text-sm">
                                        <span>Freight Bal Paid</span>
                                        <span className="ml-auto">
                                          {getCurrencyNumberWithSymbol(
                                            tradeDetail.freightPaid || 0,
                                            true
                                          )}
                                        </span>
                                      </div>
                                      <div className="flex py-2 text-sm">
                                        <span>Advance Paid</span>
                                        <span className="ml-auto">
                                          {" "}
                                          {getCurrencyNumberWithSymbol(
                                            tradeDetail.advancePaid || 0,
                                            true
                                          )}
                                        </span>
                                      </div>
                                      <div className="flex py-2 border-b text-sm">
                                        <span>Discount</span>
                                        <span className="ml-auto">
                                          {" "}
                                          {getCurrencyNumberWithSymbol(
                                            tradeDetail.destDisc || 0,
                                            true
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="flex py-2 text-sm font-semibold">
                                      <span className="">Total Payable</span>
                                      <span className="ml-auto">
                                        {getCurrencyNumberWithSymbol(
                                          tradeDetail.totalPayable || 0,
                                          true
                                        )}
                                      </span>
                                    </div>
                                    <div className="flex py-2 text-sm">
                                      <span>
                                        {" "}
                                        Platform Fees <br></br>Inclusive of
                                        Taxes
                                      </span>
                                      <span className="ml-auto">
                                        {getCurrencyNumberWithSymbol(
                                          tradeDetail.destPf || 0,
                                          true
                                        )}
                                      </span>
                                    </div>
                                    <div className="flex py-2 border-b text-sm">
                                      <span>Platform Fees Discount</span>
                                      <span className="ml-auto">
                                        {" "}
                                        {getCurrencyNumberWithSymbol(
                                          tradeDetail.destDis || 0,
                                          true
                                        )}
                                      </span>
                                    </div>
                                    <div className="flex py-2 text-sm font-semibold">
                                      <span className="color-red">
                                        Final Payable
                                      </span>
                                      <span className="ml-auto color-red">
                                        {getCurrencyNumberWithSymbol(
                                          Number(tradeDetail?.totalPayable) +
                                            Number(tradeDetail?.destPf) -
                                            Number(tradeDetail?.destDis || 0) ||
                                            0,
                                          true
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </Card>
                              )}
                              {activePattiTab == "source_patti" && (
                                <Card
                                  titile={
                                    <div className="flex items-center">
                                      <span>Invoice Details:</span>
                                      {tradeDetail?.tradeStatus != "CLOSED" &&
                                        role == "ADMIN" && (
                                          <Button
                                            variant="outlined"
                                            className="ml-auto gap-1"
                                            size="small"
                                            onClick={() => {
                                              setEditDetail({
                                                ...editDetail,
                                                section: "patti_document",
                                                mode: "edit",
                                                type: "SOURCE",
                                              });
                                              setShowInvoiceForm(true);
                                            }}
                                          >
                                            <FaEdit className="" />
                                            Edit
                                          </Button>
                                        )}
                                    </div>
                                  }
                                  className="px-2"
                                >
                                  <div className="px-2">
                                    <div className="mt-4">
                                      <div className="flex py-2 text-sm">
                                        <span> Invoice Amount :</span>
                                        <span className="ml-auto">
                                          {getCurrencyNumberWithSymbol(
                                            tradeDetail?.invAmt || 0,
                                            true
                                          )}
                                        </span>
                                      </div>
                                      <div className="flex py-2 text-sm">
                                        <span>Freight Bal Paid</span>
                                        <span className="ml-auto">
                                          {getCurrencyNumberWithSymbol(
                                            tradeDetail.freightPaid || 0,
                                            true
                                          )}
                                        </span>
                                      </div>
                                      <div className="flex py-2 text-sm">
                                        <span>Advance Received</span>
                                        <span className="ml-auto">
                                          {" "}
                                          {getCurrencyNumberWithSymbol(
                                            tradeDetail.advanceRcvd || 0,
                                            true
                                          )}
                                        </span>
                                      </div>
                                      <div className="flex py-2 border-b text-sm">
                                        <span>Discount</span>
                                        <span className="ml-auto">
                                          {" "}
                                          {getCurrencyNumberWithSymbol(
                                            tradeDetail.srcDisc || 0,
                                            true
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="flex py-2 text-sm font-semibold">
                                      <span className="">Total Receivable</span>
                                      <span className="ml-auto">
                                        {getCurrencyNumberWithSymbol(
                                          tradeDetail.totalReceivable || 0,
                                          true
                                        )}
                                      </span>
                                    </div>
                                    <div className="flex py-2 text-sm">
                                      <span>
                                        {" "}
                                        Platform Fees <br></br>Inclusive of
                                        Taxes
                                      </span>
                                      <span className="ml-auto">
                                        {getCurrencyNumberWithSymbol(
                                          tradeDetail.srcPf || 0,
                                          true
                                        )}
                                      </span>
                                    </div>
                                    <div className="flex py-2 border-b text-sm">
                                      <span>Platform Fees Discount</span>
                                      <span className="ml-auto">
                                        {" "}
                                        {getCurrencyNumberWithSymbol(
                                          tradeDetail.srcDis || 0,
                                          true
                                        )}
                                      </span>
                                    </div>
                                    <div className="flex py-2 text-sm font-semibold">
                                      <span className="color-primary">
                                        Final Receivable
                                      </span>
                                      <span className="ml-auto color-primary">
                                        {getCurrencyNumberWithSymbol(
                                          Number(tradeDetail?.totalReceivable) -
                                            Number(tradeDetail?.srcPf) +
                                            Number(tradeDetail?.srcDis || 0) ||
                                            0,
                                          true
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </Card>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : activeTab === "payment_details" ? (
                      <div className="p-4">
                        {editDetail.section === "payment_document" &&
                          editDetail.mode === "edit" && (
                            <PaymentDetailsEdit editStatus={editDetail?.mode} />
                          )}
                        <Paymentdetail
                          tradeId={parseInt(params.tradeId || "0")}
                          activeTab={activePaymentTab}
                          onChangeTab={(tab) => {
                            setActivePaymentTab(tab);
                          }}
                          roleType={role}
                        />
                        {/* <Card
                          titile={
                            <div className="flex items-center">
                              <span>Payment Documents</span>
                              {editDetail.mode === "view" ? (
                                <Button
                                  variant="outlined"
                                  className="ml-auto gap-1"
                                  size="small"
                                  onClick={() => {
                                    setEditDetail({
                                      ...editDetail,
                                      section: "payment_document",
                                      mode: "edit",
                                    });
                                  }}
                                >
                                  <FaEdit className="" />
                                  Edit
                                </Button>
                              ) : (
                                <div className="ml-auto flex gap-2">
                                  <Button
                                    variant="danger"
                                    className="gap-1"
                                    size="small"
                                    onClick={() => {
                                      setEditDetail({
                                        ...editDetail,
                                        section: "",
                                        mode: "view",
                                      });
                                    }}
                                  >
                                    <FaEdit className="" />
                                    Cancel
                                  </Button>
                                  <Button
                                    variant="contained"
                                    className="gap-1"
                                    size="small"
                                    onClick={() => {
                                      saveTradeDetail();
                                      console.log("payment upload");
                                    }}
                                  >
                                    <FaSave className="" />
                                    Save
                                  </Button>
                                </div>
                              )}
                            </div>
                          }
                          className="px-2 mt-2"
                        >
                          <div className="px-2">
                            <h2 className="color-text-primary px-4 pb-2 pt-2 text-sm">
                              Payment receipt
                            </h2>
                            <div className="flex flex-wrap gap-2 border-b overflow-auto pb-4">
                              {editDetail.section === "payment_document" &&
                                editDetail.mode === "edit" && (
                                  <div className="shrink-0 overflow-hidden p-2">
                                    <FileUploader
                                      label=""
                                      onChange={(e) => {
                                        onUploadReceiptImage(
                                          e.target.files
                                            ? e.target.files[0]
                                            : null,
                                          "payment"
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              {paymentDocuments.length > 0 &&
                                paymentDocuments.map((image, i) => (
                                  <div
                                    className="shrink-0 overflow-hidden border-2 p-2 rounded relative"
                                    style={{
                                      width: "33.333%",
                                      height: "10rem",
                                    }}
                                    key={`payment-doc-${i}`}
                                  >
                                    <a href={image.url} target="_blank">
                                      <DocumentPreview
                                        url={image.url}
                                        docType={image.docType}
                                      />
                                    </a>
                                    {editDetail.section === "patti_document" &&
                                      editDetail.mode === "edit" && (
                                        <span
                                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                                          style={{ background: "#ff0000de" }}
                                          onClick={() => {
                                            onRemoveReceiptImage(
                                              i,
                                              false,
                                              "payment"
                                            );
                                          }}
                                        >
                                          <FaXmark style={{ color: "white" }} />
                                        </span>
                                      )}
                                  </div>
                                ))}
                              {tradeDetail.paymentDocs.length > 0 ? (
                                tradeDetail.paymentDocs?.map((image, i) => (
                                  <div
                                    className="shrink-0 overflow-hidden border-2 p-2 rounded"
                                    style={{
                                      width: "33.333%",
                                      height: "10rem",
                                    }}
                                    key={`receipt-doc-${i}`}
                                  >
                                    <a href={image.url} target="_blank">
                                      <DocumentPreview
                                        url={image.url}
                                        docType={image.docType}
                                      />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <div
                                  className="border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                                  style={{
                                    width: "25%",
                                    height: "10rem",
                                  }}
                                >
                                  No Patti Documents Uploaded
                                </div>
                              )}
                            </div>
                          </div>
                        </Card> */}
                      </div>
                    ) : activeTab === "download_pdfs" ? (
                      <>
                        {" "}
                        <div
                          className="p-4 flex gap-4"
                          style={{ backgroundColor: "#EFF3EF" }}
                        >
                          <div style={{ width: "50%" }}>
                            <Card
                              titile={
                                <div className="flex items-center">
                                  <span>Source Documents:</span>
                                </div>
                              }
                            >
                              <div className="px-4 py-4 flex flex-col gap-4">
                                <div
                                  className="flex gap-2 items-center cursor-pointer"
                                  onClick={() => {
                                    if (
                                      acceptedStatus &&
                                      tradeDetail?.reqAdv != null &&
                                      tradeDetail?.reqAdv.advLimit != 0
                                    ) {
                                      setTermsAdvance(true);
                                    }
                                  }}
                                >
                                  <img src={doc} alt="image" />
                                  <span className="text-sm font-medium">
                                    {" "}
                                    Trade Advance Agreement
                                  </span>
                                </div>

                                {acceptedStatus && (
                                  <PDFDownloadLink
                                    document={
                                      tradeDetail?.tradeMode == "ORDER" ? (
                                        <InvoiceDocs
                                          details={tradeDetail}
                                          type={"SOURCE"}
                                          status={"single"}
                                        />
                                      ) : (
                                        <TapalDocs
                                          details={tradeDetail}
                                          type={"SOURCE"}
                                          status={"single"}
                                        />
                                      )
                                    }
                                    fileName={
                                      tradeDetail?.tradeMode == "ORDER"
                                        ? "Invoice.pdf"
                                        : "Tapal.pdf"
                                    }
                                  >
                                    <div className="flex gap-2 items-center">
                                      <img src={download} alt="image" />
                                      {tradeDetail?.tradeMode == "ORDER" ? (
                                        <span className="text-sm font-medium">
                                          Source Invoice
                                        </span>
                                      ) : (
                                        <p className="text-sm font-medium">
                                          Source Tapal
                                        </p>
                                      )}
                                    </div>
                                  </PDFDownloadLink>
                                )}
                                {tradeDetail?.tradeMode == "COMM" &&
                                  pattiStatus && (
                                    <PDFDownloadLink
                                      document={
                                        <PattiDocsPDf
                                          details={tradeDetail}
                                          type={"SOURCE"}
                                          status={"single"}
                                        />
                                      }
                                      fileName="Patti.pdf"
                                    >
                                      <div className="flex gap-2 items-center">
                                        <img src={download} className="w-8" />
                                        <span className="text-sm font-medium">
                                          Source Patti
                                        </span>
                                      </div>
                                    </PDFDownloadLink>
                                  )}
                                {(tradeDetail?.tradeStatus == "CLOSED" ||
                                  tradeDetail?.tradeStatus ==
                                    "PMT_COMPLETED") && (
                                  <PDFDownloadLink
                                    document={
                                      <TaxDocs
                                        details={tradeDetail}
                                        type={"SOURCE"}
                                        status={"single"}
                                      />
                                    }
                                    fileName="Pf_invoice.pdf"
                                  >
                                    <>
                                      <div className="flex gap-2 items-center ">
                                        <img src={download} className="w-8" />
                                        <span className="text-sm font-medium">
                                          Source PF Invoice
                                        </span>
                                      </div>
                                    </>
                                  </PDFDownloadLink>
                                )}
                              </div>
                            </Card>
                          </div>
                          <div style={{ width: "50%" }}>
                            <Card
                              titile={
                                <div className="flex items-center">
                                  <span>Destination Documents:</span>
                                </div>
                              }
                            >
                              <div className="px-4 py-4 flex flex-col gap-4">
                                <div
                                  className="flex gap-2 items-center cursor-pointer"
                                  onClick={() => {
                                    if (acceptedStatus) {
                                      setTermsAcceptance(true);
                                    }
                                  }}
                                >
                                  <img src={doc} alt="image" />
                                  <span className="text-sm font-medium">
                                    {" "}
                                    Trade Acceptance Agreement
                                  </span>
                                </div>
                                {acceptedStatus && (
                                  <PDFDownloadLink
                                    document={
                                      tradeDetail?.tradeMode == "ORDER" ? (
                                        <InvoiceDocs
                                          details={tradeDetail}
                                          type={"DEST"}
                                          status={"single"}
                                        />
                                      ) : (
                                        <TapalDocs
                                          details={tradeDetail}
                                          type={"DEST"}
                                          status={"single"}
                                        />
                                      )
                                    }
                                    fileName={
                                      tradeDetail?.tradeMode == "ORDER"
                                        ? "Invoice.pdf"
                                        : "Tapal.pdf"
                                    }
                                  >
                                    <>
                                      {/* {({ loading }) =>
                                      loading ? (
                                        "Loading document..."
                                      ) : ( */}

                                      <div className="flex gap-2 items-center">
                                        <img src={download} alt="image" />
                                        {tradeDetail?.tradeMode == "ORDER" ? (
                                          <p className="text-sm font-medium">
                                            Destination Invoice
                                          </p>
                                        ) : (
                                          <p className="text-sm font-medium">
                                            Destination Tapal
                                          </p>
                                        )}
                                      </div>
                                      {/* )
                                    } */}
                                    </>
                                  </PDFDownloadLink>
                                )}
                                {tradeDetail?.tradeMode == "COMM" &&
                                  pattiStatus && (
                                    <PDFDownloadLink
                                      document={
                                        <PattiDocsPDf
                                          details={tradeDetail}
                                          type={"DEST"}
                                          status={"single"}
                                        />
                                      }
                                      fileName="Patti.pdf"
                                    >
                                      <div className="flex gap-2 items-center">
                                        <img src={download} className="w-8" />
                                        <span className="text-sm font-medium">
                                          Destination Patti{" "}
                                        </span>
                                      </div>
                                    </PDFDownloadLink>
                                  )}
                                {(tradeDetail?.tradeStatus == "CLOSED" ||
                                  tradeDetail?.tradeStatus ==
                                    "PMT_COMPLETED") && (
                                  <PDFDownloadLink
                                    document={
                                      <TaxDocs
                                        details={tradeDetail}
                                        type={"DEST"}
                                        status={"single"}
                                      />
                                    }
                                    fileName="Pf_invoice.pdf"
                                  >
                                    <>
                                      <div className="flex gap-2 items-center ">
                                        <img src={download} className="w-8" />
                                        <span className="text-sm font-medium">
                                          Destination PF Invoice
                                        </span>
                                      </div>
                                    </>
                                  </PDFDownloadLink>
                                )}
                              </div>
                            </Card>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <PanelSlider
        direction="right_to_left"
        open={openRecordPayment}
        onClose={() => {
          setOpenRecordPayment(false);
        }}
        style={{ width: "40vw" }}
      >
        {role == "ADMIN" && (
          <div className="py-6 px-4 border-b flex items-center justify-between">
            <div>
              <h2>Record Payment</h2>
            </div>
            <div>
              <img
                src={cancelIcon}
                className="cursor-pointer"
                onClick={() => {
                  setOpenRecordPayment(false);
                }}
              />
            </div>
          </div>
        )}
        <Modal
          open={showCloseTradeAlert}
          onClose={() => {
            setShowCloseTradeAlert(false);
          }}
        >
          <div className="">
            <div
              className="bg-white shadow-md flex items-center pr-2 font-semibold "
              style={{ height: "3.8rem" }}
            >
              <span className="ml-3">Close Trade</span>
            </div>
            <div className="px-32">
              <div className="flex justify-center pb-8 pt-10">
                <img src={closeActiveIcon} className="h-12" />
              </div>
              <div>
                <p className="flex justify-center font-semibold color-sub-text">
                  You are about to close the trade. Are you sure you want to
                  proceed?
                </p>
              </div>
              <div className="flex align-middle border-b border-t mt-5 ">
                <div className="pr-4 flex-1 flex flex-col justify-center border-l items-center p-3">
                  <span className="text-blue-500 text-sm font-semibold">
                    Trader Id : <span>{tradeDetail?.tradeId}</span>
                  </span>
                  <span className="text-sm font-semibold">
                    From:
                    <span> {tradeDetail?.srcTradeName}</span>
                  </span>
                </div>
                <div className="border-l flex-1 flex flex-col justify-center items-center p-3">
                  <span className="text-sm font-base">Final Receivable :</span>
                  <span className="font-semibold">
                    {getCurrencyNumberWithSymbol(
                      Number(tradeDetail?.totalReceivable) -
                        Number(tradeDetail?.srcPf) +
                        Number(tradeDetail?.srcDis),
                      true
                    )}
                  </span>
                </div>
                <div className="border-l flex-1 flex flex-col justify-center items-center p-3">
                  <span className="text-sm font-base">
                    Received Till Date :
                  </span>
                  <span className="font-semibold">
                    {getCurrencyNumberWithSymbol(tradeDetail?.outwardPmt, true)}
                  </span>
                </div>
                <div className="border-l border-r flex-1 flex flex-col justify-center items-center p-3 bg-light-red ">
                  <span className="text-sm font-base">
                    Balance Receivable :
                  </span>
                  <span className="font-semibold color-red">
                    {getCurrencyNumberWithSymbol(
                      (Number(tradeDetail?.totalReceivable) -
                        Number(tradeDetail?.srcPf) +
                        Number(tradeDetail?.srcDis) || 0) -
                        (tradeDetail?.outwardPmt || 0),
                      true
                    )}
                  </span>
                </div>
              </div>

              <div className="flex align-middle  mt-5 border-b border-t">
                <div className="pr-4 flex-1 flex flex-col border-l  justify-center items-center p-3">
                  <span className="text-blue-500 text-sm font-semibold">
                    Trader Id : {tradeDetail?.tradeId}
                  </span>
                  <span className="text-sm font-semibold">
                    To: {tradeDetail?.destTradeName}
                  </span>
                </div>
                <div className="border-l flex-1 flex flex-col justify-center items-center p-3">
                  <span className="text-sm font-base">Final Payable :</span>
                  <span className="font-semibold">
                    {getCurrencyNumberWithSymbol(
                      Number(tradeDetail?.totalPayable) +
                        Number(tradeDetail?.destPf) -
                        Number(tradeDetail?.destDis),
                      true
                    )}
                  </span>
                </div>
                <div className="border-l flex-1 flex flex-col justify-center items-center p-3">
                  <span className="text-sm font-base">Paid Till Date :</span>
                  <span className="font-semibold">
                    {getCurrencyNumberWithSymbol(tradeDetail?.inwardPmt, true)}
                  </span>
                </div>
                <div className="border-l flex-1 border-r flex flex-col justify-center bg-light-red items-center p-3 ">
                  <span className="text-sm font-base">Balance Payable :</span>
                  <span className="font-semibold color-primary">
                    {getCurrencyNumberWithSymbol(
                      (Number(tradeDetail?.totalPayable) +
                        Number(tradeDetail?.destPf) -
                        Number(tradeDetail?.destDis) || 0) -
                        (tradeDetail?.inwardPmt || 0),
                      true
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className="flex justify-center gap-10 pt-6 pb-10">
              <Button
                className="w-24"
                variant="outlined"
                onClick={() => {
                  setShowCloseTradeAlert(false);
                }}
              >
                No
              </Button>
              <Button
                className="w-24"
                onClick={() => {
                  onChangeTradeStatus(false, "CLOSED");
                }}
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal>
        {openRecordPayment === true && (
          <RecordPayment
            traderId={parseInt(params.traderId || "0")}
            tradeId={parseInt(params.tradeId || "0")}
            onClose={(paymentDone: boolean) => {
              setOpenRecordPayment(false);
              if (paymentDone) {
                setActiveTab("payment_details");
                setActivePaymentTab("outward_payment");
              }
            }}
          />
        )}
      </PanelSlider>

      {editDetail.section === "trade_detail_document" &&
        editDetail.mode === "edit" &&
        showTradeForm && (
          <>
            <Modal
              open={showTradeForm}
              onClose={() => {
                setShowTradeForm(false);
              }}
              width="40vw"
            >
              <>
                <ModalHeader title={"Trade Details"} content={<></>} />
                <div className="py-3 px-6">
                  <TradeDetailsForm
                    editStatus={editDetail.mode}
                    onTradeFormClosed={setShowTradeForm}
                  />
                </div>
              </>
            </Modal>
          </>
        )}
      {showArrivalForm && (
        <>
          <Modal
            open={showArrivalForm}
            onClose={() => {
              setShowArrivalForm(false);
            }}
            width="40vw"
          >
            <>
              <ModalHeader title={"Arrival Details"} content={<></>} />
              <div className="py-3 px-6">
                <ArrivalConfirmation
                  editStatus={editDetail.mode}
                  onArrivalModalClose={setShowArrivalForm}
                  activeTabFun={setActiveTab}
                />
              </div>
            </>
          </Modal>
        </>
      )}
      {showPattiForm && (
        <>
          <Modal
            open={showPattiForm}
            onClose={() => {
              setShowPattiForm(false);
            }}
            width="40vw"
          >
            <>
              <ModalHeader title={"Patti Details"} content={<></>} />
              <div className="py-3 px-6">
                <PattiFinalization
                  editStatus={editDetail.mode}
                  onPattiClosed={setShowPattiForm}
                  tyoeFrom={editDetail?.type}
                />
              </div>
            </>
          </Modal>
        </>
      )}
      {showInvoiceForm && (
        <>
          <Modal
            open={showInvoiceForm}
            onClose={() => {
              setShowInvoiceForm(false);
            }}
            width="40vw"
          >
            <>
              <ModalHeader title={"Invoice Details"} content={<></>} />
              <div className="py-3 px-6">
                <InvoiceEdit
                  editStatus={editDetail.mode}
                  onPattiClosed={setShowInvoiceForm}
                  tyoeFrom={editDetail?.type}
                />
              </div>
            </>
          </Modal>
        </>
      )}
      {termsAcceptance && (
        <Modal
          open={termsAcceptance}
          width="40vw"
          onClose={() => {
            setTermsAcceptance(false);
          }}
        >
          <>
            <ModalHeader
              title={"Trade Acceptance Terms & Conditions (Agreement)"}
              content={<></>}
            />
            <div
              className="py-3 color-text-primary text-sm"
              // style={{ overflowY: "auto", height: "calc(100vh - 16rem)" }}
            >
              <TermsAcceptance fromPdfView={false} />
            </div>
          </>
        </Modal>
      )}
      {termsAdvance && (
        <Modal
          open={termsAdvance}
          width="40vw"
          onClose={() => {
            setTermsAdvance(false);
          }}
        >
          <>
            <ModalHeader
              title={"Trade Advance Terms & Conditions (Agreement)"}
              content={<></>}
            />
            <div
              className="py-3 color-text-primary text-sm"
              // style={{ overflowY: "auto", height: "calc(100vh - 16rem)" }}
            >
              <TermsAdvance />
            </div>
          </>
        </Modal>
      )}
    </>
  );
};
export default TradeDetail;
