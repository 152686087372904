import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import "../../fonts"; // Ensure the fonts are registered
import watermark from "../../assets/images/watermark.png"; // Make sure this path is correct
import ono_logo from "../../assets/images/ono_logo_pdf.png";
import logo_op from "../../assets/images/logo_op.png";
import store from "../../store";
import {
  getCurrencyNumberWithOutSymbol,
  getCurrencyNumberWithSymbol,
  numberToWordsWithDecimals,
} from "../../helpers/get-currency-number";
import moment from "moment";

const styles = StyleSheet.create({
  page: {
    paddingTop: 80, // Adjust to leave space for the header
    paddingBottom: 50, // Adjust to leave space for the footer
    paddingHorizontal: 40,
    fontFamily: "Roboto",
  },
  header: {
    fontSize: 11,
    color: "#000000",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: "10px solid #16A02C",
    paddingBottom: 10,
    paddingLeft: 40,
    paddingRight: 40,
    // marginBottom: 70,
    paddingTop: 20,
    borderBottom: "1px solid black",
    // width: "100%",
  },
  logo: {
    width: 80,
  },
  header_head_main: {
    fontSize: 14,
    textAlign: "center",
    marginLeft: "20%",
    marginRight: "auto",
  },
  header_head: {
    fontSize: 14,
    textAlign: "center",
  },
  footer: {
    fontSize: 12,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "left",
    paddingLeft: 40,
    paddingRight: 40,
    // borderTop: "1px solid black",
    borderBottom: "5px solid #16A02C",
    paddingTop: 20,
    // color: "grey",
  },
  watermark: {
    position: "absolute",
    top: "30%",
    left: "15%",
    width: 300,
    height: 300,
    // opacity: 0.3, // Make the watermark semi-transparent
    // transform: "rotate(-45deg)",
    zIndex: -1, // Ensure it's behind the content
  },
  section: {
    marginBottom: 20,
    fontSize: 10,
    paddingTop: 20,
    lineHeight: 1.5,
  },
  table: {
    display: "table",
    width: "100%",
    marginTop: 20,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    borderCollapse: "collapse",
    borderBottom: "0px", // This should help to collapse the borders
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1, // Apply border only to the bottom
    borderColor: "#000",
  },
  tableCol: {
    width: "20%", // Ensuring the width adds up to 100%
    padding: 5,
    borderLeftWidth: 1, // Apply border only to the left (except for first column)
    borderColor: "#000",
    // borderBottomWidth: 1, // Apply border to bottom to create a complete row
  },
  firstCol: {
    width: "10%",
    padding: 5,
    // borderBottomWidth: 1,
    borderColor: "#000",
  },
  tablBigCol: {
    width: "50%",
  },
  tablSmCol: {
    width: "10%",
  },
  para: {
    lineHeight: 1.5,
  },
  subHeading: {
    fontSize: 12,
    fontWeight: "bold",
    marginVertical: 5,
  },
  paragraph: {
    marginBottom: 10,
  },
  list: {
    marginLeft: 10,
    marginBottom: 10,
    listStyle: "disc",
  },
  listItem: {
    marginBottom: 5,
    // marginLeft: 10,
    display: "flex",
    flexDirection: "row",
    fontSize: 10,
  },
  listItemLeft: {
    justifyContent: "flex-end",
    textAlign: "right",
  },
  footerPara: {
    fontSize: 10,
    color: "#121212",
    paddingTop: 10,
    paddingBottom: 10,
    borderTop: "1px solid ",
  },
  footerParaSm: {
    fontSize: 10,
    color: "#595959",
  },
  bold: {
    fontWeight: "bold",
  },
  space: {
    paddingBottom: 10,
  },
  pageno: {
    paddingTop: 10,
    textAlign: "center",
  },
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  hindiDiv: {
    // borderTop: "1px solid black",
    // padding: 20,
    paddingBottom: 10,
    paddingTop: 10,
    fontFamily: "NotoSansTamil",
  },
  mainpara: {
    paddingBottom: 10,
  },
  visible: {
    visibility: "hidden",
  },
  relative: {
    position: "relative",
  },
  logo_op: {
    position: "absolute",
    top: "18%",
    left: "0%",
    width: 100,
    height: 50,
  },
  platform: {
    // borderTop: "1px solid black",
    borderBottom: "1px solid black",
    // padding: 5,
    fontSize: 10,
    textAlign: "center",
    textTransform: "uppercase",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
    // justifyContent: "center",
    width: "100%",
  },
  col1: {
    width: "50%",
  },
  col2: {
    width: "50%",
  },
  justifyContent: {
    justifyContent: "space-between",
  },
  border: {
    borderTop: "1px dotted black",
    // paddingBottom: 20,
    paddingTop: 10,
  },
  padding: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  borderLeft: {
    borderLeft: "0px",
  },
  borderTop: {
    borderTop: "0px",
  },
  mergedCol: {
    width: "60%", // This merges two columns into one by doubling the width
    padding: 5,
    borderLeftWidth: 1,
    borderColor: "#000",
    // borderLeft: "0px",
    borderBottomWidth: "0px",
  },
  mergedColLeft: {
    width: "60%",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
  },
  leftCol: {
    width: "50%", // Width for the "label" part
  },
  rightCol: {
    width: "50%", // Width for the "value" part
    // textAlign: "right", // Align the value to the right
  },
  borderTopPx: {
    borderTop: "1px solid black",
  },
  padding0: {
    padding: 0,
  },
  padding5: {
    padding: 5,
  },
  bullet: {
    fontSize: 15,
    marginRight: 5,
  },
  th: {
    fontSize: 9,
  },
  textRight: {
    textAlign: "right",
    // backgroundColor: "red",
  },
  tableCellLeftTotal: {
    width: "60%",
  },
  tableCellRightTotal: {
    width: "40%",
  },
  netwt: {
    fontSize: 8,
    color: "#595959",
  },
  blue: {
    color: "#0066ff",
  },
});

// Watermark component to be used on each page
const Watermark = () => (
  <View fixed style={styles.watermark}>
    <Image src={watermark} style={styles.watermark} />
  </View>
);

const FinalTaxInvoiceDoc = ({ details, type }) => (
  <>
    {/* <Page size="A4" style={styles.page}> */}
    <Watermark />
    {/* Header */}
    <View fixed style={styles.header}>
      <Image src={ono_logo} style={styles.logo} />
      <Text style={styles.header_head}>PF INVOICE</Text>
      <View>
        <Text>
          Invoice No :{" "}
          {type == "DEST" ? details?.destPfId || "-" : details?.srcPfId || "-"}
        </Text>
        <Text> Date : {moment(details?.updatedOn).format("DD-MMM-YYYY")}</Text>
        {/* <Text>Trade ID : 1233-434-23</Text> */}
      </View>
    </View>
    <View style={styles.platform}>
      <Text>CIN: U72900KA2021PTC148332 | GSTIN: 29AADCO4845M1ZV</Text>
    </View>
    {/* Table */}
    <View style={styles.section}>
      <View style={styles.flex}>
        <View style={styles.col1}>
          <View>
            <Text style={styles.bold}>ONO ARK INDIA PVT. LTD.</Text>
            <Text>
              No 148, Bhive Workspace, 5th Main Road, Sector 6, HSR Layout,
              Bengaluru, Karnataka, 560102
            </Text>
            <Text>
              Website:{" "}
              <Text href="https://ono.ag/" style={styles.blue}>
                www.onoark.com
              </Text>{" "}
            </Text>
            <Text>Email: ono-company@onoark.com</Text>
          </View>
        </View>
        <View style={styles.col2}>
          <View>
            <Text>Bill To:</Text>
            {type == "DEST" ? (
              <View>
                <Text style={styles.bold}>{details?.destTradeName}</Text>
                <Text>{details?.destAddrLine || "-"}</Text>
              </View>
            ) : (
              <View>
                <Text style={styles.bold}>{details?.srcTradeName}</Text>
                <Text>{details?.srcAddrLine || "-"}</Text>
              </View>
            )}
          </View>
          <View style={styles.padding}>
            <Text>Ship To:</Text>
            <View>
              <Text style={styles.bold}>{details?.destTradeName}</Text>
              <Text>{details?.destAddrLine || "-"}</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.border}></View>

      <View style={styles.flex}>
        <Text style={styles.bold}>Trade Details:</Text>
        <Text>(Trade ID: {details?.traderSeq}) </Text>
      </View>
      <View style={styles.border}></View>
      <View style={styles.flex}>
        <View style={styles.col1}>
          <View>
            <Text>Source Trader:</Text>
            <View>
              <Text style={styles.bold}>{details?.srcTradeName}</Text>
              <Text>{details?.srcAddrLine || "-"}</Text>
            </View>
          </View>
        </View>
        <View style={styles.col2}>
          <View>
            <Text>Destination Trader:</Text>
            <View>
              <View>
                <Text style={styles.bold}>{details?.destTradeName}</Text>
                <Text>{details?.destAddrLine || "-"}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.firstCol}>
            <Text style={[styles.tableCell, styles.th]}>#</Text>
          </View>
          <View style={[styles.tableCol, styles.tablBigCol]}>
            <Text style={[styles.tableCell, styles.th]}>Particular</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={[styles.tableCell, styles.th]}>Quantity</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={[styles.tableCell, styles.th]}>Unit Price(₹)</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={[styles.tableCell, styles.th]}>Total(₹)</Text>
          </View>
        </View>
        {/* {invoiceData.items.map((item, index) => ( */}
        <View style={styles.tableRow}>
          <View style={styles.firstCol}>
            <Text style={styles.tableCell}>{1}</Text>
          </View>
          <View style={[styles.tableCol, styles.tablBigCol]}>
            <Text style={[styles.tableCell, , styles.bold]}>
              Platform Facilitation Fees
            </Text>
            <Text style={styles.tableCell}>
              Charged for Trade ID: {details?.traderSeq}; Closed on:{" "}
              {moment(details?.updatedOn).format("DD-MMM-YYYY")}
              for the trade value of{" "}
              {details?.tradeMode == "ORDER"
                ? getCurrencyNumberWithSymbol(details?.invAmt)
                : getCurrencyNumberWithSymbol(details?.pattiAmt)}
            </Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>1</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>
              {" "}
              {type == "DEST"
                ? (details.unitPriceDest
                    ? getCurrencyNumberWithOutSymbol(details.unitPriceDest)
                    : 0) || 0
                : getCurrencyNumberWithOutSymbol(details.unitPriceSrc) || 0}
            </Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>
              {type == "DEST"
                ? (details.unitPriceDest
                    ? getCurrencyNumberWithOutSymbol(details.unitPriceDest)
                    : 0) || 0
                : getCurrencyNumberWithOutSymbol(details.unitPriceSrc) || 0}
            </Text>
          </View>
        </View>
        {/* ))} */}
        <View style={styles.tableRow}>
          <View
            style={[styles.mergedCol, styles.borderLeft, styles.mergedColLeft]}
          ></View>
          <View style={[styles.mergedCol, styles.padding0]}>
            {/* First sub-row */}
            <View style={[styles.flex, styles.padding5]}>
              <Text
                style={[
                  styles.tableCell,
                  styles.textRight,
                  styles.tableCellLeftTotal,
                  styles.bold,
                ]}
              >
                Taxable Amount:
              </Text>

              <Text
                style={[
                  styles.tableCell,
                  styles.bold,
                  styles.tableCellRightTotal,
                ]}
              >
                {type == "DEST"
                  ? getCurrencyNumberWithOutSymbol(details.totalAmountDest) || 0
                  : getCurrencyNumberWithOutSymbol(details.totalAmountSrc) || 0}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View
            style={[styles.mergedCol, styles.borderLeft, styles.mergedColLeft]}
          >
            <Text style={[styles.tableCell, styles.bold]}>
              Total Gross Amount in words:
            </Text>
            <Text style={styles.tableCell}>
              {type == "DEST"
                ? numberToWordsWithDecimals(details?.payableAfterGstDest) || "-"
                : numberToWordsWithDecimals(details?.payableAfterGstSrc) || "-"}
            </Text>
          </View>
          <View style={[styles.mergedCol, styles.padding0]}>
            <View style={[styles.flex, styles.padding5]}>
              <Text
                style={[
                  styles.tableCell,
                  styles.textRight,
                  styles.tableCellLeftTotal,
                ]}
              >
                {type == "DEST" ? details?.destTax : details?.srcTax} @ 18%:
              </Text>
              <Text style={[styles.tableCell, styles.tableCellRightTotal]}>
                {type == "DEST"
                  ? details?.gstAmountDest
                    ? getCurrencyNumberWithOutSymbol(
                        parseFloat((details?.gstAmountDest).toFixed(2))
                      )
                    : 0
                  : details?.gstAmountSrc
                  ? getCurrencyNumberWithOutSymbol(
                      parseFloat((details?.gstAmountSrc).toFixed(2))
                    )
                  : 0}
              </Text>
            </View>
            <View style={[styles.flex, styles.borderTopPx, styles.padding5]}>
              <Text
                style={[
                  styles.tableCell,
                  styles.textRight,
                  styles.tableCellLeftTotal,
                ]}
              >
                Round off:
              </Text>
              <Text style={[styles.tableCell, styles.tableCellRightTotal]}>
                0
                {/* {getCurrencyNumberWithSymbol(
                  parseFloat(
                    (
                      details?.gstAmount - Math.floor(details?.gstAmount)
                    ).toFixed(2)
                  )
                ) || 0} */}
              </Text>
            </View>
            <View style={[styles.flex, styles.borderTopPx, styles.padding5]}>
              <Text
                style={[
                  styles.tableCell,
                  styles.textRight,
                  styles.tableCellLeftTotal,
                  styles.bold,
                ]}
              >
                Total Payable:
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  styles.bold,
                  styles.tableCellRightTotal,
                ]}
              >
                {type == "DEST"
                  ? getCurrencyNumberWithSymbol(details?.payableAfterGstDest) ||
                    0
                  : getCurrencyNumberWithSymbol(details?.payableAfterGstSrc) ||
                    0}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <Text style={styles.footerParaSm}>
        This is a computer generated Invoice. No Signature Required.
      </Text>
    </View>
    {/* Footer with Page Numbers */}

    <View style={styles.footer} fixed>
      <Text style={styles.footerPara}>
        Registered Office: No 148, Bhive Workspace, 5th Main Road, Sector 6, HSR
        Layout, Bengaluru, Karnataka, 560102
      </Text>
    </View>
    {/* </Page> */}
  </>
);

export default FinalTaxInvoiceDoc;
