import { PDFDownloadLink } from "@react-pdf/renderer";
import AcceptanceDocument from "./acceptanceDocument";
import moment from "moment";
import { getCurrencyNumberWithSymbol } from "../../helpers/get-currency-number";
import download from "../../assets/images/download.svg";
const PDFAcceptance = (detailsData) => {
  const details = detailsData?.detailsData;
  const invoiceData = {
    advanceAmt:
      details?.reqAdv != null
        ? getCurrencyNumberWithSymbol(details?.reqAdv?.requestAdv)
        : 0,
    tradeId: details?.tradeId,
    tradeSeq: details?.traderSeq,
    destName: details?.destName,
    destTradename: details?.destTradeName || "-",
    destAddrLine: details?.destAddrLine || "-",
    srcName: details?.srcName,
    dob: "-",
    date: moment(details?.date).format("DD-MMM-YY"),
    srcAddrLine: details?.srcAddrLine || "-",
    srcTradename: details?.srcTradeName || "-",
    srcMobile: details?.srcMobile,
    destMobile: details?.destMobile,
    timeStamp: moment(details?.acceptTradeDate).format("DD-MMM-YY | hh:mm:A"),
    srcEsign: details?.srcEsign,
    destEsign: details?.destEsign,
  };

  return (
    <div className="py-2 flex justify-end">
      <PDFDownloadLink
        document={<AcceptanceDocument invoiceData={invoiceData} />}
        fileName="Terms_Acceptance.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            "Loading document..."
          ) : (
            <div className="flex flex-col justify-center items-center">
              <img src={download} className="w-8" />
              <p className="text-sm">Download</p>
            </div>
          )
        }
      </PDFDownloadLink>
    </div>
  );
};

export default PDFAcceptance;
