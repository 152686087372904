import React, { FC, useEffect, useState } from "react";
import Modal from "../../components/ui/modal";
import Borrowers from "./Borrowers";
import GaadiOperators from "./GaadiOperators";
import Traders from "./Traders";
import AddTrader from "./AddTrader";
import AllCustomers from "./AllCustomers";
import Subscribers from "./Subscribers";
import useAllTraders from "../../hooks/useAllTraders";
import useCustomerDocs from "../../hooks/useCustomerDocs";

const Customer: FC<{}> = ({ }) => {
  const [visible, setVisible] = useState(false);

  const [activeTab, setActiveTab] = useState("All");
  const { traders, getAllTraders, onRefresh, setOnRefresh, role } =
    useAllTraders();

  useEffect(() => {
    getAllTraders();
    getStatusDetails();
  }, [activeTab, onRefresh]);

  const { getStatusDetails } = useCustomerDocs({});

  return (
    <div className="bg-gray">
      <div className="flex items-center px-2 border-b">
        <div className="flex space-x-4 text-base">
          <button
            className={`cursor-pointer px-4 py-1 rounded-t-lg bg-white-200  ${activeTab === "All" ? "border-green-600 border-b-4" : ""
              } text-base`}
            onClick={() => setActiveTab("All")}
          >
            All
          </button>
          <button
            className={`cursor-pointer px-4 py-1 rounded-t-lg bg-white-200  ${activeTab === "Subscribers" ? "border-green-600 border-b-4" : ""
              } text-base`}
            onClick={() => setActiveTab("Subscribers")}
          >
            Subscribers
          </button>
          <button
            className={`cursor-pointer px-4 py-1 rounded-t-lg bg-white-200  ${activeTab === "Traders" ? "border-green-600 border-b-4" : ""
              } text-base`}
            onClick={() => setActiveTab("Traders")}
          >
            Traders
          </button>
          <button
            className={`cursor-pointer px-4 py-1 rounded-t-lg bg-white-200  ${activeTab === "Borrowers" ? "border-green-600 border-b-4" : ""
              } text-base`}
            onClick={() => setActiveTab("Borrowers")}
          >
            Borrowers
          </button>
          <button
            className={`cursor-pointer px-4 py-1 rounded-t-lg bg-white-200  ${activeTab === "GAADI Operators"
              ? "border-green-600 border-b-4"
              : ""
              } text-base`}
            onClick={() => setActiveTab("GAADI Operators")}
          >
            GAADI Operators
          </button>
        </div>
      </div>

      <div className="items-center">
        {activeTab === "All" && (
          <>
            <AllCustomers/>
          </>
        )}
      </div>

      <div className="items-center">
        {activeTab === "Subscribers" && (
          <>
            <Subscribers />
          </>
        )}
      </div>

      <div>
        {activeTab === "Traders" && <Traders />}
      </div>

      <div className="items-center">
        {activeTab === "Borrowers" && (
          <>
            <Borrowers />
          </>
        )}
      </div>

      <div className="items-center">
        {activeTab === "GAADI Operators" && (
          <>
            <GaadiOperators />
          </>
        )}
      </div>
    </div>
  );
};
export default Customer;
