import { FC, useCallback, useEffect, useState } from "react";
import classes from "./style.module.scss";
import moment from "moment";
import { Button } from "../../ui";
const TimePicker: FC<{
  height?: string;
  onChange: (date: string) => void;
  selectedTime: string;
  editTime: boolean;
  onModalClose: any;
}> = ({ height = "182px", onChange, selectedTime, editTime, onModalClose }) => {
  var hourVal = 0;
  var minuteVal = 0;
  var meridianVal = "";

  const [time, setTime] = useState({
    hour: 0,
    minute: 0,
    handRotate: 0,
    active: "hour",
    meridian: "",
  });
  useEffect(() => {
    const [hourString, minute] = selectedTime.split(":");
    const hour = +hourString % 24;
    console.log(hour % 12 || 12, minute);
    hourVal = hour % 12 || 12;
    minuteVal = parseInt(minute);
    meridianVal = hour < 12 ? "am" : "pm";
    setTime({
      ...time,
      hour:
        editTime || selectedTime != ""
          ? hourVal
          : parseInt(moment().format("hh")),
      minute:
        editTime || selectedTime != ""
          ? minuteVal
          : parseInt(moment().format("mm")),
      handRotate:
        editTime || selectedTime != ""
          ? hourVal * 30
          : parseInt(moment().format("hh")) * 30,
      active: "hour",
      meridian:
        editTime || selectedTime != "" ? meridianVal : moment().format("a"),
    });
    //  (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
  }, []);
  // const [time, setTime] = useState({
  //   hour: editTime ? hourVal : parseInt(moment().format("hh")),
  //   minute: editTime ? minuteVal : parseInt(moment().format("mm")),
  //   handRotate: parseInt(moment().format("hh")) * 30,
  //   active: "hour",
  //   meridian: editTime ? meridianVal : moment().format("a"),
  // });
  const handelClick = (value: number, active: string) => {
    if (active === "hour") {
      setTime({
        ...time,
        hour: value,
        active: "minute",
        handRotate: time.minute * 6,
      });
      // onChange(
      //   moment(value + ":" + time.minute + ":" + time.meridian, [
      //     "h:mm A",
      //   ]).format("HH:mm")
      // );
      let hi = value + ":" + time.minute + ":" + time.meridian;
    } else {
      setTime({
        ...time,
        minute: value,
        handRotate: value * 6,
      });
      // onChange(
      //   moment(time.hour + ":" + value + ":" + time.meridian, [
      //     "h:mm A",
      //   ]).format("HH:mm")
      // );
    }
  };
  return (
    <>
      <div className="flex gap-2 h-14 mx-auto" style={{ width: height }}>
        <span
          className={`grow flex items-center justify-center cursor-pointer ${
            classes.timeBox
          } ${time.active === "hour" ? classes.active : ""}`}
          onClick={() => {
            setTime({ ...time, handRotate: time.hour * 30, active: "hour" });
          }}
        >
          {time.hour}
        </span>
        <span className="flex justify-center items-center">:</span>
        <span
          className={`grow flex items-center justify-center cursor-pointer ${
            classes.timeBox
          } ${time.active === "minute" ? classes.active : ""}`}
          onClick={() => {
            setTime({ ...time, handRotate: time.minute * 6, active: "minute" });
          }}
        >
          {time.minute}
        </span>
        <span className="flex flex-col grow items-center justify-center gap-2">
          <span
            className={`w-full flex justify-center cursor-pointer ${
              classes.timeBox
            } ${time.meridian === "am" ? classes.active : ""}`}
            onClick={() => {
              setTime({ ...time, meridian: "am" });
              // onChange(
              //   moment(time.hour + ":" + time.minute + ":" + "am", [
              //     "h:mm A",
              //   ]).format("HH:mm")
              // );
            }}
          >
            AM
          </span>
          <span
            className={`w-full flex justify-center cursor-pointer ${
              classes.timeBox
            } ${time.meridian === "pm" ? classes.active : ""}`}
            onClick={() => {
              setTime({ ...time, meridian: "pm" });
              onChange(
                moment(time.hour + ":" + time.minute + ":" + "pm", [
                  "h:mm A",
                ]).format("HH:mm")
              );
            }}
          >
            PM
          </span>
        </span>
      </div>
      <div
        className="relative flex flex-col mx-auto"
        style={{
          height: height,
          width: height,
          borderRadius: "50%",
        }}
      >
        <span
          className={`absolute inline-flex items-center justify-center ${
            classes.number
          } ${
            (time.active === "hour" && time.hour === 1) ||
            (time.active === "minute" && time.minute === 5)
              ? classes.active
              : ""
          }`}
          style={{
            left: `calc((${height} - 36px)/2)`,
            transform: `translate(45px, 13px)`,
          }}
          onClick={() => {
            time.active === "hour"
              ? handelClick(1, "hour")
              : handelClick(5, "minute");
          }}
        >
          {time.active === "hour" ? 1 : 5}
        </span>
        <span
          className={`absolute inline-flex items-center justify-center ${
            classes.number
          } ${
            (time.active === "hour" && time.hour === 2) ||
            (time.active === "minute" && time.minute === 10)
              ? classes.active
              : ""
          }`}
          style={{
            left: `calc((${height} - 36px)/2)`,
            transform: `translate(79px, 47px)`,
          }}
          onClick={() => {
            time.active === "hour"
              ? handelClick(2, "hour")
              : handelClick(10, "minute");
          }}
        >
          {time.active === "hour" ? 2 : 10}
        </span>
        <span
          className={`absolute inline-flex items-center justify-center ${
            classes.number
          } ${
            (time.active === "hour" && time.hour === 3) ||
            (time.active === "minute" && time.minute === 15)
              ? classes.active
              : ""
          }`}
          style={{
            left: `calc((${height} - 36px)/2)`,
            transform: `translate(91px, 92px)`,
          }}
          onClick={() => {
            time.active === "hour"
              ? handelClick(3, "hour")
              : handelClick(15, "minute");
          }}
        >
          {time.active === "hour" ? 3 : 15}
        </span>
        <span
          className={`absolute inline-flex items-center justify-center ${
            classes.number
          } ${
            (time.active === "hour" && time.hour === 4) ||
            (time.active === "minute" && time.minute === 20)
              ? classes.active
              : ""
          }`}
          style={{
            left: `calc((${height} - 36px)/2)`,
            transform: `translate(79px, 137px)`,
          }}
          onClick={() => {
            time.active === "hour"
              ? handelClick(4, "hour")
              : handelClick(20, "minute");
          }}
        >
          {time.active === "hour" ? 4 : 20}
        </span>
        <span
          className={`absolute inline-flex items-center justify-center ${
            classes.number
          } ${
            (time.active === "hour" && time.hour === 5) ||
            (time.active === "minute" && time.minute === 25)
              ? classes.active
              : ""
          }`}
          style={{
            left: `calc((${height} - 36px)/2)`,
            transform: `translate(45px, 171px)`,
          }}
          onClick={() => {
            time.active === "hour"
              ? handelClick(5, "hour")
              : handelClick(25, "minute");
          }}
        >
          {time.active === "hour" ? 5 : 25}
        </span>
        <span
          className={`absolute inline-flex items-center justify-center ${
            classes.number
          } ${
            (time.active === "hour" && time.hour === 6) ||
            (time.active === "minute" && time.minute === 30)
              ? classes.active
              : ""
          }`}
          style={{
            left: `calc((${height} - 36px)/2)`,
            transform: `translate(0px, 182px)`,
          }}
          onClick={() => {
            time.active === "hour"
              ? handelClick(6, "hour")
              : handelClick(30, "minute");
          }}
        >
          {time.active === "hour" ? 6 : 30}
        </span>
        <span
          className={`absolute inline-flex items-center justify-center ${
            classes.number
          } ${
            (time.active === "hour" && time.hour === 7) ||
            (time.active === "minute" && time.minute === 35)
              ? classes.active
              : ""
          }`}
          style={{
            left: `calc((${height} - 36px)/2)`,
            transform: `translate(-46px, 171px)`,
          }}
          onClick={() => {
            time.active === "hour"
              ? handelClick(7, "hour")
              : handelClick(35, "minute");
          }}
        >
          {time.active === "hour" ? 7 : 35}
        </span>
        <span
          className={`absolute inline-flex items-center justify-center ${
            classes.number
          } ${
            (time.active === "hour" && time.hour === 8) ||
            (time.active === "minute" && time.minute === 40)
              ? classes.active
              : ""
          }`}
          style={{
            left: `calc((${height} - 36px)/2)`,
            transform: `translate(-79px, 138px)`,
          }}
          onClick={() => {
            time.active === "hour"
              ? handelClick(8, "hour")
              : handelClick(40, "minute");
          }}
        >
          {time.active === "hour" ? 8 : 40}
        </span>
        <span
          className={`absolute inline-flex items-center justify-center ${
            classes.number
          } ${
            (time.active === "hour" && time.hour === 9) ||
            (time.active === "minute" && time.minute === 45)
              ? classes.active
              : ""
          }`}
          style={{
            left: `calc((${height} - 36px)/2)`,
            transform: `translate(-91px, 92px)`,
          }}
          onClick={() => {
            time.active === "hour"
              ? handelClick(9, "hour")
              : handelClick(45, "minute");
          }}
        >
          {time.active === "hour" ? 9 : 45}
        </span>
        <span
          className={`absolute inline-flex items-center justify-center ${
            classes.number
          } ${
            (time.active === "hour" && time.hour === 10) ||
            (time.active === "minute" && time.minute === 50)
              ? classes.active
              : ""
          }`}
          style={{
            left: `calc((${height} - 36px)/2)`,
            transform: `translate(-79px, 46px)`,
          }}
          onClick={() => {
            time.active === "hour"
              ? handelClick(10, "hour")
              : handelClick(50, "minute");
          }}
        >
          {time.active === "hour" ? 10 : 50}
        </span>
        <span
          className={`absolute inline-flex items-center justify-center ${
            classes.number
          } ${
            (time.active === "hour" && time.hour === 11) ||
            (time.active === "minute" && time.minute === 55)
              ? classes.active
              : ""
          }`}
          style={{
            left: `calc((${height} - 36px)/2)`,
            transform: `translate(-46px, 13px)`,
          }}
          onClick={() => {
            time.active === "hour"
              ? handelClick(11, "hour")
              : handelClick(55, "minute");
          }}
        >
          {time.active === "hour" ? 11 : 55}
        </span>
        <span
          className={`absolute inline-flex items-center justify-center ${
            classes.number
          } ${
            (time.active === "hour" && time.hour === 12) ||
            (time.active === "minute" && time.minute === 0)
              ? classes.active
              : ""
          }`}
          style={{
            left: `calc((${height} - 36px)/2)`,
            transform: `translate(0px, 0px)`,
          }}
          onClick={() => {
            time.active === "hour"
              ? handelClick(12, "hour")
              : handelClick(0, "minute");
          }}
        >
          {time.active === "hour" ? 12 : "00"}
        </span>
        <span
          className={`absolute ${classes.hand}`}
          style={{
            height: `calc((${height} - 54px)/2)`,
            left: `calc((${height} - 4px)/2)`,
            top: "40px",
            transform: `rotate(${time.handRotate}deg)`,
            transformOrigin: "bottom",
            transition: "transform .3s",
          }}
        >
          <span
            className="absolute top-0"
            style={{
              height: "16px",
              width: "16px",
              borderLeft: "2px solid var(--ono-primary)",
              borderTop: "2px solid var(--ono-primary)",
              transform: "rotate(45deg)",
              left: "-7px",
            }}
          ></span>
          <span
            className="absolute rounded-full border bottom-0"
            style={{
              height: "10px",
              width: "10px",
              background: "var(--ono-primary)",
              left: "-4px",
            }}
          ></span>
        </span>
      </div>
      <div className="pt-3 mx-4">
        <Button
          className=" w-full mt-7"
          onClick={() => {
            onChange(
              moment(time.hour + ":" + time.minute + ":" + time.meridian, [
                "h:mm A",
              ]).format("HH:mm")
            );
            onModalClose(false);
          }}
        >
          Ok
        </Button>
      </div>
    </>
  );
};
export default TimePicker;
