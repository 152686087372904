import React, { useEffect, useState } from 'react';
import useCustomerDocs from '../../../hooks/useCustomerDocs';
import { FaSearch } from "react-icons/fa";
import { Input } from "../../../components/ui";
import { IoMdCloseCircleOutline } from 'react-icons/io';
import EmptyProfile from "../../../assets/images/profile.jpg";
import useRm from './useRm';

const RelationshipMngr: React.FC<{
    onChange: (rm: any) => void;
    onClear: ()=> void;
    rmId: number;
}> = ({
    onChange, onClear, rmId
}) => {
        const { searchTerm, 
            setSearchTerm,
            relationshipManager, 
            setRelationshipManager,
            getRmDetails,selectedOpt, setSelectedOpt} = useRm({});

        const clearSearch = () => {
            setSelectedOpt(0);
            setSearchTerm("");
            onClear();
        };

        const handleOptionClick = (rm: any) => {
            setSearchTerm(rm.name);
            let index = relationshipManager.findIndex((r) => r.id == rm.id);
            relationshipManager.splice(index, 1);
            relationshipManager.splice(0, 0, rm);
            onChange(rm);
            setRelationshipManager(relationshipManager);
        };

        const filteredOptions = relationshipManager.filter((option) =>
            option.name.toLowerCase().includes(searchTerm.toLowerCase())
        );

        useEffect(() => {
            getRmDetails();
        }, []);

        return (
            <div>
                <div>
                    <div className=" relative m-2">
                        <FaSearch
                            className=" absolute color-primary"
                            size={15}
                            style={{ top: "14px", left: "6px" }}
                        />
                        <Input
                            type="text"
                            palceholder="Search RM Name"
                            value={searchTerm}
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                            }}
                            inputStyle={{
                                textIndent: "22px",
                                padding: "8px 5px",
                            }}
                            style={{
                                width: "95%",
                            }}
                        />

                        <div>
                            <IoMdCloseCircleOutline
                                onClick={() => {
                                    clearSearch();
                                }}
                                className="absolute color-primary cursor-pointer w-10"
                                style={{
                                    top: "32%",
                                    right: "1rem",
                                    fontSize: "1.2rem",
                                    color: "gray",
                                    cursor: "pointer",
                                }}
                            />
                        </div>
                    </div>

                    <div
                        className="overflow-y-auto p-[5px]"
                        style={{ height: "calc(50vh - 5rem)" }}
                    >
                        {filteredOptions.length > 0 ? (
                            filteredOptions.map((option, id) => (
                                <div
                                    onClick={() => handleOptionClick(option)}
                                    className="cursor-pointer p-1"
                                >
                                    <div
                                        className={`flex border-gray br-5 mb-1 p-2 ${rmId === option.id
                                            ? "bg-light-primary border-primary"
                                            : "bg-white"
                                            } hv-primary cursor-pointer`}
                                    >
                                        <div>
                                            {option.profilePic ? (
                                                <img
                                                    src={option.profilePic}
                                                    className="w-8 h-8 mt-1.5"
                                                />
                                            ) : (
                                                <img
                                                    src={EmptyProfile}
                                                    className="w-8 h-8 mt-1.5"
                                                />
                                            )}
                                        </div>
                                        <div className="ml-2">
                                            <span className="font-semibold">
                                                {option?.name}
                                            </span>
                                            <br />
                                            <span className="text-sm font-semibold">
                                                {option?.mobile}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="flex items-center justify-center h-full">
                                <span className="font-semibold">No RM found</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

export default RelationshipMngr;