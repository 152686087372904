import { ChangeEvent, useRef, useState } from "react";
import useAxiosClient from "./useAxiosClient";
import Compressor from "compressorjs";
import { useSelector } from "react-redux";
import { TRootState } from "../store";
import {
  MarketDetails,
  RMdetails,
  TraderStatus,
  TTraderId,
  TTraderKycDoc,
} from "../types";
import useRm from "../pages/customer/CustomerDetails/useRm";
import moment from "moment";

const useCustomerDocs = ({
  traderId,
}: // tradeId,
// activePaymentTab,
{
  traderId?: number;
  // tradeId: number;
  // activePaymentTab?: string;
}) => {
  const { handlePostCall } = useAxiosClient();

  const { adminId } = useSelector((state: TRootState) => ({
    adminId: state.auth.adminId,
  }));

  const [loading, setLoading] = useState(true);

  const [activeTab, setActiveTab] = useState("All Trades"); // AllDetails useState

  const [kycDocs, setKycDocs] = useState<
    Array<{ docType: "image" | "pdf"; url: string; type: string }>
  >([]);

  // for profile pic
  const [profile, setProfile] = useState("");

  // for status
  const [openStatus, setOpenStatus] = useState(false);
  const [selectStatus, setSelectStatus] = useState("");

  // for radio buttons
  const [selectedOption, setSelectedOption] = useState<string | number>("");

  // for image upload
  const ref = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<File | null>(null);

  // Handler for button clicks
  const [activeButton, setActiveButton] = useState("");

  const [traderView, setTraderView] = useState<TTraderId | null>();

  // profile percentage

  const [completionStatus, setCompletionStatus] = useState(false);
  const [selectedCompletion, setSelectedCompletion] = useState<string | number>(
    ""
  );


  //renewal date

  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));


  // account number

  const [confirmAccountNum, setConfirmAccountNum] = useState("");

  const { handleGetCall } = useAxiosClient();

  // const { traderId } = useSelector((state: TRootState) => ({
  //   traderId: state.trades.traderId,
  // }));
  const { tradeStatus } = useSelector((state: TRootState) => ({
    tradeStatus: state.trades.tradeStatus,
  }));

  // Relationship Manager API

  const {
    searchTerm,
    setSearchTerm,
    relationshipManager,
    setRelationshipManager,
    getRmDetails,
  } = useRm({});

  //MARKET API
  const [searchMrkt, setSearchMrkt] = useState("");

  const [marketDetails, setMarketDetails] = useState<MarketDetails[]>([]);
  const getMarketDetails = (mrktId = 0) => {
    handleGetCall<MarketDetails[]>(`admin/markets/click`).then(({ data }) => {
      if (data) {
        //to show the selected market at the top of the list
        if (mrktId > 0) {
          var index = data.findIndex((mrkt) => mrkt.marketId == mrktId);
          setSearchMrkt(data[index].marketName);
          var market = data[index];
          data.splice(index, 1);
          data.splice(0, 0, market);
          setMarketDetails(data);
        } else {
          setMarketDetails(data);
        }
      } else {
        setMarketDetails([]);
      }
    });
  };

  // Trader status API
  const selectedStatus = useSelector(
    (state: TRootState) => state.trades.selectedStatus
  );

  const [selectedStatuses, setSelectedStatuses] =
    useState<string[]>(selectedStatus);

  const [traderStatus, setTraderStatus] = useState<TraderStatus[]>([]);

  const getStatusDetails = () => {
    handleGetCall<TraderStatus[]>(
      `admin/trade-platform/traderStatus/adminId/${adminId}`
    ).then(({ data }) => {
      if (data) {
        setTraderStatus(data);
      } else {
        setTraderStatus([]);
      }
    });
  };

  // aaa particular id lo unna data ni EDIT click chesaka chupiyadaniki ikkada inputs radio buttons useState lu anni set chesam
  const getTraderDetails = (isCallRm = false) => {
    setLoading(true);
    handleGetCall<TTraderId>(
      `admin/trade-platform/traders/adminId/${adminId}/traderId/${traderId}?tradeStatus=${tradeStatus}`
    ).then(({ data }) => {
      if (data) {
        let invoices: TTraderKycDoc[] = [];
        if (data.kycDocs != null && data.kycDocs.length > 0) {
          for (let image of data.kycDocs) {
            let docType: "pdf" | "image" = image.url.includes(".pdf")
              ? "pdf"
              : "image";
            invoices.push({ ...image, docType: docType });
          }
        }
        setTraderView({
          ...data,
          kycDocs: invoices,
          ors: data.ors !== 0 ? data.ors : "",
          tradeLimit: data.tradeLimit !== 0 ? data.tradeLimit : "",
          pincode: data?.bsnAcc[0].pincode
            ? data?.bsnAcc[0].pincode.toString()
            : "",
          addressLine: data?.bsnAcc[0].addrLine,
          dist: data?.bsnAcc[0].city,
          state: data?.bsnAcc[0].state,
          marketName: data?.bsnAcc[0].marketName,
          shopNum: data?.bsnAcc[0].shopNum,
          marketId: data?.bsnAcc[0].marketId,
          tradeDesc: data?.bsnAcc[0].tradeDesc,
          accountNum: data?.accountNum !== 0 ? data?.accountNum : "",
           bankName: data?.bankName !== "" ? data?.bankName : "",
          ifsc: data?.ifsc !== 0 ? data?.ifsc : "",
          emailId: data?.emailId !== "" ? data?.emailId : "",
        });
        setSelectStatus(data.status);
        setSelectedOption(data.regType);
        setActiveButton(data.tradeType);
        setProfile(data.profilePic);
        setSelectedCompletion(data.traderProgress);
        setSelectedDate(data.renewalDate);
        setConfirmAccountNum(data.accountNum?.toString());
        if (isCallRm) {
          getRmDetails(data.relMgrId);
          getMarketDetails(data.marketId);
          getStatusDetails();
        }
      } else {
        setTraderView(null);
      }
      setLoading(false);
    });
  };

  // image / pdf upload cheyadaniki

  const onUploadReceiptImage = async (
    type:
      | "AADHAR"
      | "PAN"
      | "BANK_STATEMENT"
      | "ITR"
      | "TRADE_LICENSE"
      | "PROFILE_PIC"
      | "INVOICE",
    files: FileList | File[] // Accept multiple files
  ) => {
    const uploadPromises = Array.from(files).map(async (file) => {
      let fileType = "";
      if (file.type === "application/pdf") {
        fileType = "pdf";
      } else if (file.type.includes("image/")) {
        fileType = "image";
      }

      let formdata = new FormData();
      formdata.append("name", type);
      formdata.append("category", "PERSONAL");
      formdata.append("tpId", `${adminId}`);

      if (fileType === "pdf") {
        formdata.append("file", file, file.name.replace(" ", "-"));
        let imageUploadResponse = await handlePostCall<string[]>({
          URL: "/mandi/files/kyc-doc/upload",
          apiParams: formdata,
          config: {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        });
        let url = imageUploadResponse.data[0];
        setKycDocs((prevDocs) => [
          { docType: "pdf", url: url, type: type },
          ...prevDocs,
        ]);
      } else {
        new Compressor(file, {
          quality: 0.8,
          success: async (compressedResult) => {
            formdata.append(
              "file",
              compressedResult,
              file.name.replace(" ", "-")
            );
            let imageUploadResponse = await handlePostCall<string[]>({
              URL: "/mandi/files/kyc-doc/upload",
              apiParams: formdata,
              config: {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              },
            });
            let url = imageUploadResponse.data[0];
            if (type === "PROFILE_PIC") {
              setProfile(url);
            } else {
              setKycDocs((prevDocs) => [
                { docType: "image", url: url, type: type },
                ...prevDocs,
              ]);
            }
          },
        });
      }
    });

    // Await all upload promises for multiple files
    await Promise.all(uploadPromises);
  };

  // pdf / image remove cheyadaniki
  const onRemoveReceiptImage = (
    image: string,
    isExist: boolean = false,
    index: number = 0
  ) => {
    if (isExist === true && traderView?.kycDocs !== null) {
      let invoices = [...traderView?.kycDocs!];
      let i = invoices.findIndex((img) => img.id === index);
      invoices[i] = { ...invoices[i], status: 0 };
      setTraderView({
        ...traderView!,
        kycDocs: invoices,
      });
    } else {
      let newtapalInvoice = [...kycDocs];
      let index = newtapalInvoice.findIndex((img) => img.url === image);
      newtapalInvoice.splice(index, 1);
      setKycDocs(newtapalInvoice);
    }
  };
  return {
    kycDocs,
    onUploadReceiptImage,
    onRemoveReceiptImage,
    setKycDocs,
    traderView,
    setTraderView,
    getTraderDetails,
    getStatusDetails,
    openStatus,
    selectStatus,
    selectedOption,
    ref,
    files,
    profile,
    activeButton,
    activeTab,
    loading,
    relationshipManager,
    marketDetails,
    searchTerm,
    searchMrkt,
    traderStatus,
    setTraderStatus,
    setSearchMrkt,
    setSearchTerm,
    setMarketDetails,
    getMarketDetails,
    getRmDetails,
    setActiveTab,
    setActiveButton,
    setSelectedOption,
    setSelectStatus,
    setOpenStatus,
    setFiles,
    setProfile,
    useCustomerDocs,
    setRelationshipManager,
    setLoading,
    completionStatus,
    setCompletionStatus,
    selectedCompletion,
    setSelectedCompletion,
    selectedStatuses,
    setSelectedStatuses,
    selectedDate, 
    setSelectedDate,
    confirmAccountNum,
     setConfirmAccountNum
  };
};
export default useCustomerDocs;
