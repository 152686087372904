import React, { FC, useEffect, useState } from "react";
import comingsoon from "../../assets/images/coming_soon.gif";
import { FaAngleDown, FaChevronRight } from "react-icons/fa6";
import EmptyProfile from "../../assets/images/profile.jpg";
import { useNavigate } from "react-router-dom";
import market from "../../assets/images/market.png";
import timer from "../../assets/images/timer-img.png";
import tickmark from "../../assets/images/tickmark.png";
import hold from "../../assets/images/hold.svg";
import reject from "../../assets/images/reject.svg";
import useAllTraders from "../../hooks/useAllTraders";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedStatus, updateTraderId } from "../../reducers/tradeSlice";
import { getMaskedMobileNumber } from "../../helpers/get-currency-number";
import { TTrader } from "../../types";
import { getCurrencyNumberWithSymbol } from "../../helpers/get-currency-number";
import Modal from "../../components/ui/modal";
import Popover from "../../components/ui/popover";
import { FaSearch } from "react-icons/fa";
import { Input } from "../../components/ui";
import useCustomerDocs from "../../hooks/useCustomerDocs";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Checkbox } from "@mui/material";
import AddTrader from "./AddTrader";
import blocked from "../../assets/images/blocked.svg";
import { TRootState } from "../../store";
import NodataAvailable from "../../assets/images/NodataAvailable.svg";
const AllCustomers: FC<{}> = ({ }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);

  // const [searchText, setSearchText] = useState("");
  const { traders, getAllTraders, onRefresh, setOnRefresh, role } = useAllTraders();

  const label = { inputProps: { "aria-label": "Checkbox demo" } }; //checkbox kosam
  const { traderStatus, getStatusDetails} = useCustomerDocs({});

  const { selectedStatus, allTraders } = useSelector((state: TRootState) => ({
    selectedStatus: state.trades.selectedStatus,
    allTraders: state.trades.allTraders,
  }));

  const [searchText, setSearchText] = useState("");
  const [filteredTraders, setFilteredTraders] = useState<any[]>([]);
  const [open, setOpen] = useState(false);

  // Local state to hold selected statuses temporarily
  const [localSelectedStatus, setLocalSelectedStatus] = useState<string[]>([]);

  useEffect(() => {
    // Initialize localSelectedStatus with selectedStatus from Redux on component mount or update
    setLocalSelectedStatus(selectedStatus);
  }, [selectedStatus]);

  const filterTraders = () => {
    let filtered = allTraders;
    const trimmedSearchText = searchText.trim().toLowerCase();
    // Apply search filter
    if (searchText.length > 0) {
      filtered = filtered.filter(
        (option) =>
          (option.tradeType && option.tradeType.toLowerCase().includes(trimmedSearchText)) ||
          (option.traderId && option.traderId.toString().toLowerCase().includes(trimmedSearchText)) ||
          (option.tradeName && option.tradeName.toLowerCase().includes(trimmedSearchText)) ||
          (option.marketName && option.marketName.toLowerCase().includes(trimmedSearchText))
      );
    }

    // Apply status filter from Redux selectedStatus
    if (selectedStatus.length > 0) {
      filtered = filtered.filter((option) => selectedStatus.includes(option.status));
    }

    setFilteredTraders(filtered);
    setOpen(false);
  };

  // Call filterTraders whenever searchText or selectedStatus in Redux changes
  useEffect(() => {
    filterTraders();
  }, [searchText, selectedStatus, allTraders]);

  // Handle checkbox changes for localSelectedStatus without dispatching to Redux
  const handleCheckboxChange = (status: string) => {
    setLocalSelectedStatus((prev) =>
      prev.includes(status) ? prev.filter((s) => s !== status) : [...prev, status]
    );
  };

  // Function to apply filters and close popup
  const handleApply = () => {
    dispatch(setSelectedStatus(localSelectedStatus)); // Update Redux state with local selected statuses
    setOpen(false);
  };

  // Function to clear all filters
  const handleClearAll = () => {
    setSearchText(""); // Clear search text if desired
    setFilteredTraders([]);
    setLocalSelectedStatus([]); // Clear local selected statuses
    dispatch(setSelectedStatus([])); // Clear Redux state
    setOpen(false);
  };
  

  useEffect(() => {
    if (traders.length == 0) {
      getAllTraders();
    }
    getStatusDetails();
  }, [traders]);

  return (
    <div
      style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
      className="ml-3 mr-3"
    >
      <div className="flex justify-between px-4 py-4">
        <div className="flex relative">
          <FaSearch
            className="absolute color-primary"
            size={15}
            style={{ top: "14px", left: "6px" }}
          />
          <Input
            type="text"
            palceholder="Search by Trader Name / Trader ID / Type / Market Name"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            style={{ width: "450px" }}
            inputStyle={{ textIndent: "22px", padding: "8px 5px" }}
          />
        </div>

        <div className="flex space-x-4 mr-2">
          <div className="">
            <button
              onClick={() => {
                setOpen(true);
              }}
              className="flex items-center bg-white border border-gray color-primary rounded-lg px-2 py-2 focus:outline-none focus:ring-2 focus:ring-green-200"
            >
              <FilterAltIcon className="w-4 h-4 mr-2" />
              Filter By:{"  "}
              <span className="text-gray-700 px-1">
                Status({selectedStatus.length})
              </span>
              <span className="text-gray-700 px-4">
                <ArrowDropDownIcon />
              </span>
            </button>

            {open && (
              <Popover
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
                className="w-[230px] h-auto"
                style={{ marginLeft: "7.2rem" }}
              >
                <div>
                  {traderStatus.map((status) => (
                    <div
                      className="p-1 cursor-pointer"
                      onClick={() => handleCheckboxChange(status.status)}
                    >
                      <Checkbox
                        {...label}
                        checked={localSelectedStatus.includes(status.status)}
                        // onChange={() => handleCheckboxChange(status.status)}
                      />{" "}
                      {status.status}
                    </div>
                  ))}

                  <div className="flex p-3 gap-2" style={{ width: "220px" }}>
                    <button
                      className=" align-items-center rounded-md h-[40px]"
                      style={{ backgroundColor: "#d7f3dd", width: "100px" }}
                      onClick={() => {
                        handleClearAll();
                      }}
                    >
                      <span className="color-primary"> Clear All</span>
                    </button>
                    <button
                      className="bg-primary align-items-center rounded-md h-[40px]"
                      style={{ backgroundColor: "white", width: "100px" }}
                      onClick={() => {
                        handleApply();
                        filterTraders();
                      }}
                    >
                      <span className="text-white">Apply</span>
                    </button>
                  </div>
                </div>
              </Popover>
            )}
          </div>
        </div>
      </div>

      <div className="bg-gray">
        <div
          style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
          className="ml-3 mr-3 border-gray "
        >
          <div
            className="overflow-y-auto bg-white"
            style={{ height: "calc(100vh - 12rem)" }}
          >
            <div className="flex bg-light-primary text-sm font-semibold py-2 sticky rounded-t-md top-0 bg-gray-50">
              <span className="grow-[0.2] basis-0 px-2 text-center">#</span>
              <span className="grow-[1.5] basis-0 px-2">Trader</span>
              <span className="grow-[1] basis-0 px-2">Trader Type</span>
              <span className="grow-[1.8] basis-0 px-4">Market</span>
              <span className="grow-[1] basis-0 px-2">Pending Balance(₹)</span>
              <span className="grow-[1] basis-0 px-3">Trade Limit(₹)</span>
              <span className="grow-[1] basis-0 px-2">Status</span>
            </div>
            <div className="text-sm">
              <ul>
                {filteredTraders.length > 0 ? (
                  filteredTraders.map((trader, index) => (

                    <li
                      onClick={() => {
                        navigate(`/allDetails/${trader.traderId}`);
                        dispatch(
                          updateTraderId({
                            traderId: trader.traderId,
                            tradeStatus: "ALL",
                          })
                        );
                      }}
                      className="flex p-2 cursor-pointer border-b"
                    >
                      <span className="grow-[0.2] basis-0 text-center font-semibold mt-1">
                        {index + 1}
                      </span>
                      <div className="grow-[1.8] basis-0 flex ml-4">
                        <div>
                          {(trader?.profilePic === "" ||
                            trader?.profilePic === null) && (
                              <img
                                src={EmptyProfile}
                                alt=""
                                className="w-10 h-10 mt-1 mr-2"
                              />
                            )}
                          {trader?.profilePic !== "" &&
                            trader?.profilePic !== null && (
                              <img
                                className="w-10 h-10 rounded-full object-cover mr-2 mt-1"
                                src={trader?.profilePic}
                              />
                            )}
                        </div>
                        <div>
                          <span className="font-semibold">
                            {trader.tradeName}
                          </span>
                          <br></br>
                          <span className="">
                            Trader-
                            <span className="color-blue">
                              {trader.traderId}
                            </span>{" "}
                            | {getMaskedMobileNumber(trader.mobile || "")}
                          </span>
                          <br></br>
                          <span className="">{trader.name}</span>
                        </div>
                      </div>
                      <span className="grow-[1] basis-0 mr-1 font-semibold">
                        {trader.tradeType ?? "-"}
                      </span>
                      <div className="grow-[2.1] basis-0 flex items-center">
                        <div>
                          <img
                            className="w-10 h-10 rounded-full object-cover"
                            src={market}
                          ></img>
                        </div>
                        <div>
                          <span className="font-semibold ml-1">
                            {trader.marketName ?? "-"}
                          </span>
                        </div>
                      </div>

                      {trader.tradeType === "SOURCE" && (
                        <span className="grow-[1.1] basis-0">
                          <div>
                            {trader.amtDetails !== null ? (
                              <span className=" px-2 color-orange text-sm font-semibold">
                                {" "}
                                {/* {(
                              trader.amtDetails.paidReceived -
                              trader.amtDetails.paidAmount
                            ).toFixed(2)} */}
                                {getCurrencyNumberWithSymbol(
                                  trader.amtDetails.paidReceived -
                                  trader.amtDetails.paidAmount || 0,
                                  true
                                )}
                              </span>
                            ) : (
                              <span className=" px-2 color-orange text-sm font-semibold">
                                {getCurrencyNumberWithSymbol(0, true)}
                              </span>
                            )}

                            <br />
                            <span className="color-black text-sm ml-1.5">
                              To Be Paid
                            </span>
                          </div>
                        </span>
                      )}

                      {trader.tradeType !== "SOURCE" && (
                        <span className="grow-[1.1] basis-0">
                          <div>
                            {trader.amtDetails !== null ? (
                              <span className=" px-2 color-primary text-sm font-semibold">
                                {" "}
                                {getCurrencyNumberWithSymbol(
                                  trader.amtDetails.paidReceived -
                                  trader.amtDetails.paidAmount || 0,
                                  true
                                )}
                              </span>
                            ) : (
                              <span className=" px-2 color-primary text-sm font-semibold">
                                {getCurrencyNumberWithSymbol(0, true)}
                              </span>
                            )}
                            <br />

                            <span className="color-black text-sm ml-1.5">
                              To Be Received
                            </span>
                          </div>
                        </span>
                      )}
                      <div className="grow-[0.9] basis-0 px-2">
                        <span className="font-semibold text-sm">
                          {getCurrencyNumberWithSymbol(
                            trader.tradeLimit || 0,
                            true
                          )}
                        </span>
                        {trader.amtDetails !== null && (
                          <p className="text-sm">
                            Avl Limit: <span className="font-semibold">--</span>
                          </p>
                        )}
                      </div>

                      <div className="grow-[1] basis-0 px-2 flex font-semibold text-base">
                        {trader.status === "ACTIVE" && (
                          <div className="ml-2 flex cursor-pointer color-primary font-semibold">
                            <div>
                              {" "}
                              <img src={tickmark} className="w-5 h-5 mt-1" />{" "}
                            </div>
                            <div className="ml-1">{trader.status}</div>
                          </div>
                        )}

                        {trader.status === "UNDER REVIEW" && (
                          <div className="ml-2 flex cursor-pointer color-orange font-semibold">
                            <div>
                              {" "}
                              <img src={timer} className="w-5 h-5 mt-1" />{" "}
                            </div>
                            <div className="ml-1">{trader.status}</div>
                          </div>
                        )}

                        {trader.status === "HOLD" && (
                          <div className="ml-2 flex cursor-pointer flex color-orange font-semibold">
                            <div>
                              {" "}
                              <img src={hold} className="w-5 h-5 mt-1" />{" "}
                            </div>
                            <div className="ml-1">{trader.status}</div>
                          </div>
                        )}

                        {trader.status === "REJECTED" && (
                          <div className="ml-2 flex cursor-pointer color-red font-semibold">
                            <div>
                              {" "}
                              <img src={reject} className="w-5 h-5 mt-1" />{" "}
                            </div>
                            <div className="ml-1">{trader.status}</div>
                          </div>
                        )}

                        {trader.status === "BLOCKED" && (
                          <div className="ml-2 flex cursor-pointer color-red font-semibold">
                            <div>
                              {" "}
                              <img src={blocked} className="w-5 h-5 mt-1" />{" "}
                            </div>
                            <div className="ml-1">{trader.status}</div>
                          </div>
                        )}
                      </div>
                      <div className="">
                        {" "}
                        <FaChevronRight />{" "}
                      </div>
                    </li>

                  ))
                ) : (
                  <div className="flex items-center justify-center py-8">
                    <img src={NodataAvailable} alt="image" />
                  </div>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCustomers;
