import { FC, useEffect, useState } from "react";

const TabsList: FC<{
  data: Array<{ label: string; value: string }>;
  onChange: (tab: { label: string; value: string }, i: number) => void;
  activeTab?: string;
}> = ({ data, onChange, activeTab }) => {
  const [active, setActive] = useState(0);
  const handleClick = (tab: { label: string; value: string }, i: number) => {
    onChange(tab, i);
  };
  return (
    <>
      <ul className="flex">
        {data.map((tab, i) => (
          <li
            key={`tab-${i}`}
            className={`cursor-pointer px-4 py-2 rounded-t-lg bg-white-200 ${
              activeTab === tab.value ? "border-green-600 border-b-4" : ""
            }`}
            onClick={() => handleClick(tab, i)}
          >
            {tab.label}
          </li>
        ))}
      </ul>
    </>
  );
};
export default TabsList;
