import { FC, useContext, useEffect } from "react";
import moment from "moment";

import { useSelector } from "react-redux";
import logo_op from "../../assets/images/logo_op.png";
import { TRootState } from "../../store";
import PDFAcceptance from "./pdfAcceptance";
const TermsAcceptance: FC<{ fromPdfView?: boolean }> = ({ fromPdfView }) => {
  const { tradeDetail } = useSelector((state: TRootState) => ({
    tradeDetail: state.trades.tradeDetails,
  }));

  //   const { getTradeDetail } = useOrderView();
  //   useEffect(() => {
  //     if (tradeDetail === null) {
  //       getTradeDetail();
  //     }
  //   }, []);
  return (
    <div className="relative">
      <div
        className="py-3 px-6 pb-6 color-text-primary text-sm terms_mobile"
        style={{ overflowY: "auto", height: "calc(100vh - 18rem)" }}
      >
        <div>
          <p className="mb-4">
            The undersigned Destination Partner has, by way of submitting the
            Trade Acceptance Form
            <span className="font-semibold">(TAF)</span>, requested ONO ARK
            INDIA PRIVATE LIMITED{" "}
            <span className="font-semibold">(“ONO PLATFORM”)</span> to
            facilitate the trade through the platform for the Trade ID:{" "}
            {tradeDetail?.traderSeq}. It shall be governed by below mentioned
            terms and conditions (“Agreement”):
          </p>
          <ul className="main_list mb-4">
            <li>
              <p className="mb-1 font-semibold">
                1.Destination Partner acknowledges, understands and agrees that:
              </p>
              <ul className="sub_list">
                <li>
                  Good Faith and Trust: The Destination Partner is obligated to
                  act with utmost good faith and trust in all matters pertaining
                  to this Trade Agreement.
                </li>
                <li>
                  {" "}
                  The destination partner expressly agreed to act timely and
                  upload arrivals confirmation, commodity details such as
                  quantities, quality and sample specifications, to ONO APP to
                  avoid any disputes with the source partner. Not confirming
                  arrival details and any quality disputes within 6 hours of
                  vehicle arrivals will result in breakage of destination party
                  trust and leads to penalties.
                </li>
                <li>
                  The destination partner expressly agreed to provide final
                  Patti within 4 days from the vehicle arrival in case of
                  commission mode vehicle. Failure to provide Patti within
                  stipulated time will result in penalties and delayed charges
                  of 0.1% of the trade value per day.
                </li>
                <li>
                  The destination partner expressly agreed to provide full and
                  final payment within 7 days from the vehicle arrival. Failure
                  to make full and final payment within stipulated time will
                  result in penalties and delayed charges of 0.1% per day of the
                  balance payable amount.
                </li>
                <li>
                  The destination partner shall not be entitled to reject /
                  cancel the placed trade upon successful dispatch / in-transit
                  / arrivals of the vehicle. If, for any reason, the destination
                  partner rejects / cancels the trade after it has been arrived
                  / in-transit / dispatched, ONO shall have a right to charge
                  the Destination Partner a cancellation fee up to 5% of trade
                  value plus transportation costs.
                </li>
                <li>
                  The destination partner shall furnish accurate, correct and
                  reliable photographs / videos of commodities and information
                  about trade details of quantities, varieties, separation marks
                  and any quality disputes.
                </li>
              </ul>
            </li>
          </ul>
          <ul className="main_list mb-4">
            <li>
              <p className="mb-1 font-semibold">
                2.Prohibition on Rejecting / Canceling the Accepted Trade
                Vehicles:
              </p>
              <ul className="sub_list">
                <li>
                  Under no circumstances shall accepted trade vehicles be
                  rejected / cancelled without the formal consent and approval
                  of ONO, as such actions compromise the trust established with
                  source partners.
                </li>
                <li>
                  If the Destination Partner failed to unload the arrival
                  vehicle on-time and provide arrivals confirmation details and
                  any quality issues within 4 hours of the vehicle, arrivals
                  result in penalties and delayed charges of up to 5% of trade
                  values plus transportation costs on actual basis.
                </li>
                <li>
                  The destination partner shall not be entitled to cancel /
                  reject the arrived vehicle due to price fluctuations,
                  especially in order mode trade. If any force rejection /
                  cancellation of a vehicle will result in collections of full
                  truck value plus transportation cost on actual basis.
                </li>
              </ul>
            </li>
          </ul>
          <ul className="main_list mb-4">
            <li>
              <p className="mb-1 font-semibold">
                3.ONO shall not be held liable for:
              </p>
              <ul className="sub_list">
                <li>
                  Any commodity price fluctuations / reduced risks that may
                  arise.
                </li>
                <li>
                  Any and all risks associated with post arrivals delays or
                  liquidation challenges.
                </li>
                <li>
                  While ONO facilitates the dispute resolution process ONO is
                  not responsible for taking any rejections of trades due to
                  quality issues that may occur during the course of the
                  transaction or trade.
                </li>
                <li>
                  Any unlawful images or data that may inadvertently appear on
                  the platform despite its efforts to ensure that all data and
                  images published on the platform comply with applicable laws.
                  ONO assumes no responsibility for any information or images
                  requested by a partner for publication on the platform that do
                  not conform to legal requirements.
                </li>
              </ul>
            </li>
          </ul>
          <p className="mb-4">
            I acknowledge that I have read, accepted, and agreed to the terms
            and conditions above and accepted the trade.
          </p>
          <div className="mb-4 flex flex-col">
            <p>
              Trade Id:{" "}
              <span className="font-semibold">
                {tradeDetail?.traderSeq || "-"}
              </span>{" "}
            </p>
            <p>
              Dispatch Date:{" "}
              <span className="font-semibold">
                {moment(tradeDetail?.date).format("DD-MMM-YYYY") || "-"}
              </span>
            </p>
            <p>
              Source Party:{" "}
              <span className="font-semibold">
                {tradeDetail?.srcName || "-"}
              </span>
            </p>
            <p>
              Source Location:{" "}
              <span className="font-semibold">
                {tradeDetail?.srcAddrLine || "-"}
              </span>
            </p>
          </div>
          <div className="mb-4 flex flex-col">
            <p>
              Destination Partner Name:{" "}
              <span className="font-semibold">
                {tradeDetail?.destName || "-"}
              </span>
            </p>
            <p>
              DOB: <span className="font-semibold">{"-"}</span>
            </p>
            <p>
              Location:{" "}
              <span className="font-semibold">
                {tradeDetail?.srcAddrLine || "-"}
              </span>
            </p>
          </div>
          <div className="py-4 flex border-t border-b flex-col">
            <p className="font-semibold">
              வர்த்தக ஏற்பு விதிமுறைகள் மற்றும் நிபந்தனைகள் (ஒப்பந்தம்)
            </p>
          </div>
          <p className="mb-4 mt-3">
            கீழே கையொப்பமிடப்பட்ட இலக்கு பங்குதாரர், வர்த்தக ஏற்பு படிவத்தை
            <span className="font-semibold"> ("TAF")</span> சமர்ப்பிப்பதன்
            மூலம், டிரேட் ஐடி: {tradeDetail?.traderSeq} க்கான வர்த்தகத்தை எளிதாக
            ஓனோ ஆர்க் இந்தியா பிரைவேட் லிமிடெட்{" "}
            <span className="font-semibold">(“ஓனோ பிளாட்ஃபார்ம்”)</span>{" "}
            தளத்தின் மூலம் ஏற்றுக்கொள்ள படிவம் சமர்ப்பிக்கபடுகிறது. இது கீழே
            குறிப்பிடப்பட்ட விதிமுறைகள் மற்றும் நிபந்தனைகளால் நிர்வகிக்கப்படும்{" "}
            <span className="font-semibold">("ஒப்பந்தம்”)</span>:
          </p>
          <ul className="main_list mb-4">
            <li>
              <p className="mb-1 font-semibold">
                1. இலக்கு பங்குதாரர் கீழே குறிப்பிடப்பட்டுள்ள விதிகளை
                புரிந்துகொண்டு ஒப்புக்கொள்கிறார்:
              </p>
              <ul className="sub_list">
                <li>
                  நல்லெண்ணம் மற்றும் நம்பிக்கை: இந்த வர்த்தக ஒப்பந்தம் தொடர்பான
                  அனைத்து விஷயங்களிலும் மிகுந்த பொறுப்புடன் மற்றும்
                  நம்பிக்கையுடனும் செயல்பட இலக்கு பங்குதாரர்
                  கடமைப்பட்டிருக்கிறார்.
                </li>
                <li>
                  இலக்கு பங்குதாரர் சரியான நேரத்தில் செயல்படவும், வருகை உறுதி
                  செய்யவும் மற்றும் அளவு, தரம் மற்றும் மாதிரி பொருட்கள் போன்ற
                  விவரங்களை ஓனோ செயலியில் பதிவேற்றம் செய்வதன் மூலம் மூல
                  பங்குதாரர் உடன் ஏற்படும் முரண்பாடுகளை தவிர்க்க படும். வாகனம்
                  வந்த 6 மணி நேரத்திற்குள் வருகை மற்றும் தரம் விவரங்களை உறுதி
                  செய்யாமல் இருப்பது இலக்கு தரப்பினரின் நம்பிக்கையை
                  குறைப்பதாகும் மேலும் இது அபராதம் விதிக்க இட்டு செல்லும்.
                </li>
                <li>
                  கமிஷன் முறை வாகனமாக இருந்தால், வாகனம் வந்ததிலிருந்து 4
                  நாட்களுக்குள் இறுதிப் பட்டியை வழங்க இலக்கு பங்குதாரர்
                  வெளிப்படையாக ஒப்புக் கொண்டார். குறிப்பிட்ட காலத்திற்குள் பட்டி
                  வழங்கத் தவறினால் அபராதம் மற்றும் ஒரு நாளின் வர்த்தக மதிப்பில்
                  0.1% தாமதக் கட்டணம் விதிக்கப்படும்.
                </li>
                <li>
                  வாகனம் வந்ததிலிருந்து 7 நாட்களுக்குள் முழுமையான மற்றும்
                  இறுதிக் கட்டணத்தை வழங்க இலக்கு பங்குதாரர் வெளிப்படையாக ஒப்புக்
                  கொண்டார்.குறிப்பிட்ட நேரத்திற்குள் முழு மற்றும் இறுதி பணத்தைச்
                  செலுத்த தவறினால், அபராதம் மற்றும் செலுத்த வேண்டிய நிலுவைத்
                  தொகையில் ஒரு நாளைக்கு 0.1% தாமதக் கட்டணம் விதிக்கப்படும்.
                </li>
                <li>
                  வாகனத்தை வெற்றிகரமாக அனுப்பும்போது/போக்குவரத்தின் போது/வரும்
                  போதும், வர்த்தகத்தை நிராகரிக்கவோ/ரத்து செய்யவோ இலக்கு
                  பங்குதாரருக்கு உரிமை இல்லை. மேலும் எந்தவொரு காரணத்திற்காகவும்,
                  இலக்கு பங்குதாரர் வர்த்தகத்தை அனுப்பப்பட்ட பிறகு/ போக்குவரத்து
                  போதும் நிராகரித்தால், ONO க்கு வர்த்தக மதிப்பில் 5% மற்றும்
                  போக்குவரத்து செலவுகள் ரத்து கட்டணம் வசூலிக்க உரிமை உண்டு.
                </li>
                <li>
                  இலக்கு பங்குதாரர் பொருட்களின் துல்லியமான, சரியான மற்றும்
                  நம்பகமான புகைப்படங்கள் / வீடியோக்களை வழங்க வேண்டும்.மற்றும்
                  அளவுகள், வகைகள், தரத்தை மேலும் ஏதேனும் தர முரண்பாடுகளின்
                  வர்த்தக விவரங்கள் பற்றிய தகவல்களை வழங்க வேண்டும்.
                </li>
              </ul>
            </li>
          </ul>
          <ul className="main_list mb-4">
            <li>
              <p className="mb-1 font-semibold">
                2. ஏற்றுக்கொள்ளப்பட்ட வர்த்தக வாகனங்கள் நிராகரிப்பது / ரத்து
                செய்வது மீதான தடை:
              </p>
              <ul className="sub_list">
                <li>
                  எந்தவொரு சூழ்நிலையிலும் ஏற்றுக்கொள்ளப்பட்ட வர்த்தக வாகனங்கள்
                  ONO இன் முறையான ஒப்புதல் மற்றும் ஒப்புதல் இல்லாமல்
                  நிராகரிக்கப்பட கூடாது / ரத்து செய்யப்படக்கூடாது, ஏனெனில்
                  இத்தகைய நடவடிக்கைகள் மூல தரப்பினரின் நம்பிக்கையை
                  குறைப்பதாகும்.
                </li>
                <li>
                  இலக்கு பங்குதாரர் சரியான நேரத்தில் வந்து சேரும் வாகனத்தை
                  இறக்கி, 4 மணி நேரத்திற்குள் வருகை உறுதிப்படுத்தல் விவரங்கள்
                  மற்றும் ஏதேனும் தரச் சிக்கல்களை வழங்கத் தவறினால், வருகைக்கு
                  அபராதம் மற்றும் வர்த்தக மதிப்புகளில் 5% வரை தாமதமான கட்டணங்கள்
                  மற்றும் மொத்த மதிப்பு மீதான போக்குவரத்து செலவுகள் அடிப்படையில்
                  தாமத கட்டணம் வசூலிக்க உரிமை உண்டு.
                </li>
                <li>
                  விலை ஏற்ற இறக்கங்கள் காரணம், குறிப்பாக ஆர்டர் முறை
                  வர்த்தகத்தின் காரணமாக, சென்றடைந்த வாகனத்தை ரத்து செய்ய /
                  நிராகரிக்க இலக்கு பங்குதாரருக்கு உரிமை இல்லை. வாகனம் ஏதேனும்
                  கட்டாய நிராகரிப்பு / ரத்து செய்யப்பட்டால், உண்மையான
                  அடிப்படையில் முழு டிரக் மதிப்பு மற்றும் போக்குவரத்து செலவு
                  ஆகியவை வசூலிக்கப்படும்.
                </li>
              </ul>
            </li>
          </ul>
          <ul className="main_list mb-4">
            <li>
              <p className="mb-1 font-semibold">
                3. ONO இதற்கு பொறுப்பேற்காது:
              </p>
              <ul className="sub_list">
                <li>
                  ஏதேனும் பொருட்களின் விலை ஏற்ற இறக்கங்கள் / குறையக்கூடிய
                  அபாயங்கள்.
                </li>
                <li>
                  பிந்தைய வருகை தாமதங்கள் அல்லது கலைப்பு சவால்களுடன் தொடர்புடைய
                  ஏதேனும் மற்றும் அனைத்து அபாயங்களும்.
                </li>
                <li>
                  ஓனோ முரண்பாடு தீர்க்கும் செயல்முறையை எளிதாக்கும் அதே வேளையில்,
                  பரிவர்த்தனை அல்லது வர்த்தகத்தின் போது ஏற்படும் தரச் சிக்கல்கள்
                  காரணமாக வர்த்தகங்களை நிராகரிப்பதற்கு ஓனோ பொறுப்பாகாது.
                </li>
                <li>
                  பிளாட்ஃபார்மில் வெளியிடப்படும் அனைத்து தரவுகளும் படங்களும்
                  பொருந்தக்கூடிய சட்டங்களின் இணங்குவதை உறுதி செய்யும் முயற்சிகள்
                  இருந்தபோதிலும், பிளாட்ஃபார்மில் கவனக்குறைவாக தோன்றக்கூடிய
                  சட்டத்திற்குப் புறம்பான படங்கள் அல்லது தரவு.
                </li>
                <li>
                  இந்த தளத்தில் பிரசுரிக்கப்படும் அனைத்து தரவுகளும் மற்றும்
                  படங்களும் பொருந்தும் சட்டங்களுக்கு பொருந்தும் என்பதை உறுதி
                  செய்யும் முயற்சிகளுக்கு மத்தியில் தற்செயலாக தோன்றக்கூடிய
                  சட்டவிரோதமான எந்தப் படங்கள் அல்லது தரவுகள்.சட்ட தேவைகளுக்கு
                  இணங்காத எந்தத் தகவல் அல்லது படங்களுக்கும் ஓனோ பொறுப்பேற்காது.
                </li>
              </ul>
            </li>
          </ul>
          <p className="mb-4">
            மேலே குறிப்பிடப்பட்டுள்ள விதிமுறைகள் மற்றும் நிபந்தனைகளை நான்
            படித்து, ஏற்றுக்கொண்டு, வர்த்தக முன்பணத்திற்கு விண்ணப்பித்துள்ளேன்
            என்பதை இதன்மூலம் ஒப்புக்கொள்கிறேன்.
          </p>
          <div className="mb-4 flex flex-col">
            <p>
              வர்த்தக ஐடி:
              <span className="font-semibold">
                {tradeDetail?.traderSeq || "-"}
              </span>{" "}
            </p>
            <p>
              அனுப்பிய நாள்:
              <span className="font-semibold">
                {moment(tradeDetail?.date).format("DD-MMM-YYYY") || "-"}
              </span>
            </p>
            <p>
              மூல பங்குதாரர் பெயர்:
              <span className="font-semibold">
                {tradeDetail?.srcName || "-"}
              </span>
            </p>
            <p>
              இடம்:
              <span className="font-semibold">
                {tradeDetail?.srcAddrLine || "-"}
              </span>
            </p>
          </div>
          <div className="mb-4 flex flex-col">
            <p>
              இலக்கு பங்குதாரர் பெயர்:
              <span className="font-semibold">
                {tradeDetail?.destName || "-"}
              </span>
            </p>
            <p>
              பிறந்த தேதி: <span className="font-semibold">{"-"}</span>
            </p>
            <p>
              இடம்:
              <span className="font-semibold">
                {tradeDetail?.destAddrLine || "-"}
              </span>
            </p>
          </div>
          {/* {fromPdfView && ( */}
          <div className="mb-4 flex border-t flex-col ">
            <div className="mb-2 pt-2">
              <p>Yours faithfully, </p>
              <p className="font-semibold">For {tradeDetail?.destTradeName}</p>
            </div>
            <div className="mb-2 relative">
              <p>
                eSigned using OTP (ono.ag - {tradeDetail?.destEsign})<br></br>
                {tradeDetail?.destName} / {tradeDetail?.destMobile}
                <br></br>
                {moment(
                  tradeDetail?.acceptTradeDate
                    ? tradeDetail?.acceptTradeDate
                    : new Date()
                ).format("DD-MMM-YYYY | hh:mm:A")}
              </p>
              <img src={logo_op} className="absolute top-0" />
            </div>
            <div className="mb-2">
              <p> {tradeDetail?.destName}</p>
              <p className="font-semibold">{tradeDetail?.destMobile}</p>
            </div>
          </div>
          {/* )} */}
        </div>
      </div>
      {/* {fromPdfView && ( */}
      {tradeDetail != null && (
        <div
          className="bg-white absolute bottom-0 right-0 border-t w-full"
          data-element="order-view-footer"
        >
          <div className="flex justify-end">
            <span className="flex flex-col px-4 py-2 items-center cursor-pointer">
              <div>
                <PDFAcceptance detailsData={tradeDetail} />
              </div>
            </span>
          </div>
        </div>
      )}

      {/* )} */}
    </div>
  );
};
export default TermsAcceptance;
