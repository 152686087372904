import moment from "moment/moment";
import date_icon from "../../../assets/images/date_icon.svg";
import { CSSProperties, FC, useState } from "react";
import Popover from "../../../components/ui/popover";
import Calender from "../../../components/commons/calender";
type TSelectDate = {
  className?: string;
  styles?: CSSProperties;
  onChange: (date: string) => void;
  selectedDate: string;
};
const SelectDateDisable: FC<TSelectDate> = ({ onChange, selectedDate }) => {
  const [calenderOpen, setCalenderOpen] = useState(false);
  /** Function to handle date selection */
  const handleDateSelection = (_date: string) => {
    onChange(moment(_date).format("YYYY-MM-DD"));
    setCalenderOpen(false);
  };
  return (
    <div className="relative items-center  flex justify-center shrink-0 mt-2">
      <div
        className="flex bg-white items-center w-full gap-2 px-2 py-2 border rounded-md text-sm cursor-pointer justify-between"
        onClick={() => {
          setCalenderOpen(!calenderOpen);
        }}
      >
        <span className="color-blue">
          {" "}
          {moment(selectedDate).format("DD-MMM-YYYY")}
        </span>
        <img src={date_icon} alt="icon" className="w-4 h-4" />
      </div>
    </div>
  );
};

export default SelectDateDisable;
