import { createContext } from "react";
export const deviceInfoContext = createContext<{
  deviceType: "MOBILE" | "DESKTOP" | "";
  width: string;
  height: string;
  left: string;
  right: string;
}>({
  deviceType: "",
  width: "100vw",
  height: "100vh",
  left: "",
  right: "",
});
