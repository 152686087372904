import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import "../../fonts"; // Ensure the fonts are registered
import watermark from "../../assets/images/watermark.png"; // Make sure this path is correct
import ono_logo from "../../assets/images/ono_logo_pdf.png";
import logo_op from "../../assets/images/logo_op.png";
const styles = StyleSheet.create({
  page: {
    paddingTop: 110, // Adjust to leave space for the header
    paddingBottom: 190, // Adjust to leave space for the footer
    paddingHorizontal: 40,
    fontFamily: "Roboto",
  },
  header: {
    fontSize: 11,
    color: "#000000",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-between",
    alignItems: "center",
    borderTop: "10px solid #16A02C",
    paddingBottom: 10,
    paddingLeft: 40,
    paddingRight: 40,
    // marginBottom: 70,
    paddingTop: 20,
    borderBottom: "1px solid black",
    // width: "100%",
  },
  logo: {
    width: 80,
  },
  header_head_main: {
    fontSize: 14,
    textAlign: "center",
    marginLeft: "20%",
    marginRight: "auto",
  },
  header_head: {
    fontSize: 14,
    textAlign: "center",
  },
  footer: {
    fontSize: 12,
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "left",
    paddingLeft: 40,
    paddingRight: 40,
    borderTop: "1px solid black",
    paddingTop: 20,
    // color: "grey",
  },
  watermark: {
    position: "absolute",
    top: "30%",
    left: "15%",
    width: 300,
    height: 300,
    // opacity: 0.3, // Make the watermark semi-transparent
    // transform: "rotate(-45deg)",
    zIndex: -1, // Ensure it's behind the content
  },
  section: {
    marginBottom: 20,
    fontSize: 11,
    paddingTop: 20,
    lineHeight: 1.5,
  },
  table: {
    display: "table",
    width: "auto",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "20%",
    padding: 5,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
  },
  tableCell: {
    fontSize: 10,
  },
  para: {
    lineHeight: 1.5,
  },
  subHeading: {
    fontSize: 12,
    fontWeight: "bold",
    marginVertical: 5,
  },
  paragraph: {
    marginBottom: 10,
  },
  list: {
    marginLeft: 10,
    marginBottom: 10,
  },
  listItem: {
    marginBottom: 5,
    marginLeft: 10,
    display: "flex",
    flexDirection: "row",
  },
  listItemLeft: {
    justifyContent: "flex-end",
    textAlign: "right",
  },
  footerPara: {
    fontSize: 11,
    color: "#121212",
  },
  footerParaSm: {
    fontSize: 10,
    color: "#000000",
  },
  bold: {
    fontWeight: "bold",
  },
  space: {
    paddingBottom: 10,
  },
  pageno: {
    paddingTop: 10,
    textAlign: "center",
  },
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  hindiDiv: {
    // borderTop: "1px solid black",
    // padding: 20,
    paddingBottom: 10,
    paddingTop: 10,
    fontFamily: "NotoSansTamil",
  },
  mainpara: {
    paddingBottom: 10,
  },
  visible: {
    visibility: "hidden",
  },
  relative: {
    position: "relative",
  },
  logo_op: {
    position: "absolute",
    top: "18%",
    left: "0%",
    width: 100,
    height: 50,
  },
});

// Watermark component to be used on each page
const Watermark = () => (
  <View fixed style={styles.watermark}>
    <Image src={watermark} style={styles.watermark} />
  </View>
);

const AcceptanceDocument = ({ invoiceData }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Watermark />
      {/* Header */}
      <View fixed style={styles.header}>
        <Image src={ono_logo} style={styles.logo} />
        <Text style={styles.header_head_main}>Trade Acceptance Agreement</Text>
        <View style={styles.visible}>
          {/* <Text>Invoice No : 2024001</Text>
          <Text>Invoice Date : 28/08/2024</Text>
          <Text>Billing ID : 1233-434-23</Text> */}
        </View>
      </View>
      {/* Invoice Details */}
      {/* <View style={styles.section}>
        <Text>Invoice பொருள்: {invoiceData.invoiceDate}</Text>
        <Text style={{ fontFamily: "Roboto" }}>
          Invoice Number: {invoiceData.invoiceNumber}
        </Text>
      </View> */}
      {/* Table */}
      <View style={styles.section}>
        <View>
          <View>
            <Text style={[styles.para, styles.mainpara]}>
              The undersigned Destination Partner has, by way of submitting the
              Trade Acceptance Form
              <span className="font-semibold">(TAF)</span>, requested ONO ARK
              INDIA PRIVATE LIMITED{" "}
              <span className="font-semibold">(“ONO PLATFORM”)</span> to
              facilitate the trade through the platform for the Trade ID:{" "}
              {invoiceData?.tradeSeq}. It shall be governed by below mentioned
              terms and conditions (“Agreement”):
            </Text>
            <Text style={styles.subHeading}>
              1.Destination Partner acknowledges, understands and agrees that:
            </Text>
            <View style={styles.list}>
              <Text style={styles.listItem}>
                a. Good Faith and Trust: The Destination Partner is obligated to
                act with utmost good faith and trust in all matters pertaining
                to this Trade Agreement.
              </Text>
              <Text style={styles.listItem}>
                b. The destination partner expressly agreed to act timely and
                upload arrivals confirmation, commodity details such as
                quantities, quality and sample specifications, to ONO APP to
                avoid any disputes with the source partner. Not confirming
                arrival details and any quality disputes within 6 hours of
                vehicle arrivals will result in breakage of destination party
                trust and leads to penalties.
              </Text>
              <Text style={styles.listItem}>
                c. The destination partner expressly agreed to provide final
                Patti within 4 days from the vehicle arrival in case of
                commission mode vehicle. Failure to provide Patti within
                stipulated time will result in penalties and delayed charges of
                0.1% of the trade value per day.
              </Text>
              <Text style={styles.listItem}>
                d. The destination partner expressly agreed to provide full and
                final payment within 7 days from the vehicle arrival. Failure to
                make full and final payment within stipulated time will result
                in penalties and delayed charges of 0.1% per day of the balance
                payable amount.
              </Text>
              <Text style={styles.listItem}>
                e. The destination partner shall not be entitled to reject /
                cancel the placed trade upon successful dispatch / in-transit /
                arrivals of the vehicle. If, for any reason, the destination
                partner rejects / cancels the trade after it has been arrived /
                in-transit / dispatched, ONO shall have a right to charge the
                Destination Partner a cancellation fee up to 5% of trade value
                plus transportation costs.
              </Text>
              <Text style={styles.listItem}>
                f. The destination partner shall furnish accurate, correct and
                reliable photographs / videos of commodities and information
                about trade details of quantities, varieties, separation marks
                and any quality disputes.
              </Text>
            </View>
            <Text style={styles.subHeading}>
              2. Prohibition on Rejecting / Canceling the Accepted Trade
              Vehicles:
            </Text>
            <View style={styles.list}>
              <Text style={styles.listItem}>
                a. Under no circumstances shall accepted trade vehicles be
                rejected / cancelled without the formal consent and approval of
                ONO, as such actions compromise the trust established with
                source partners.
              </Text>
              <Text style={styles.listItem}>
                b. If the Destination Partner failed to unload the arrival
                vehicle on-time and provide arrivals confirmation details and
                any quality issues within 4 hours of the vehicle, arrivals
                result in penalties and delayed charges of up to 5% of trade
                values plus transportation costs on actual basis.
              </Text>
              <Text style={styles.listItem}>
                c. The destination partner shall not be entitled to cancel /
                reject the arrived vehicle due to price fluctuations, especially
                in order mode trade. If any force rejection / cancellation of a
                vehicle will result in collections of full truck value plus
                transportation cost on actual basis.
              </Text>
            </View>
          </View>
          <View style={styles.space}>
            <Text style={styles.subHeading}>
              3.ONO shall not be held liable for:
            </Text>
            <View style={styles.list}>
              <Text style={styles.listItem}>
                a. Any commodity price fluctuations / reduced risks that may
                arise.
              </Text>
              <Text style={styles.listItem}>
                b. Any and all risks associated with post arrivals delays or
                liquidation challenges.
              </Text>
              <Text style={styles.listItem}>
                c. While ONO facilitates the dispute resolution process ONO is
                not responsible for taking any rejections of trades due to
                quality issues that may occur during the course of the
                transaction or trade.
              </Text>
              <Text style={styles.listItem}>
                d. Any unlawful images or data that may inadvertently appear on
                the platform despite its efforts to ensure that all data and
                images published on the platform comply with applicable laws.
                ONO assumes no responsibility for any information or images
                requested by a partner for publication on the platform that do
                not conform to legal requirements.
              </Text>
            </View>
            <Text style={[styles.para, styles.space]}>
              I acknowledge that I have read, accepted, and agreed to the terms
              and conditions above and accepted the trade.
            </Text>
            <View style={[styles.left, styles.space]}>
              <View style={styles.listItem}>
                <Text>Trade ID: </Text>
                <Text style={styles.bold}> {invoiceData.tradeSeq}</Text>
              </View>
              <View style={styles.listItem}>
                <Text>Dispatch Date: </Text>
                <Text style={styles.bold}> {invoiceData.date}</Text>
              </View>
              <View style={styles.listItem}>
                <Text>Source Partner: </Text>
                <Text style={styles.bold}> {invoiceData.srcName}</Text>
              </View>
              <View style={styles.listItem}>
                <Text>Source Location: </Text>
                <Text style={styles.bold}> {invoiceData.srcAddrLine}</Text>
              </View>
            </View>
            <View style={[styles.right]}>
              <View style={[styles.listItem, styles.listItemLeft]}>
                <Text>Destination Partner: </Text>
                <Text style={styles.bold}> {invoiceData.destName}</Text>
              </View>
              <View style={[styles.listItem, styles.listItemLeft]}>
                <Text>DOB: </Text>
                <Text style={styles.bold}> {invoiceData.dob}</Text>
              </View>
              <View style={[styles.listItem, styles.listItemLeft]}>
                <Text>Location: </Text>
                <Text style={styles.bold}> {invoiceData.destAddrLine}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.hindiDiv}>
          <Text style={[styles.header_head, styles.space, styles.bold]}>
            வர்த்தக ஏற்பு விதிமுறைகள் மற்றும் நிபந்தனைகள் (ஒப்பந்தம்)
          </Text>
          <View>
            <Text style={[styles.para, styles.mainpara]}>
              கீழே கையொப்பமிடப்பட்ட இலக்கு பங்குதாரர், வர்த்தக ஏற்பு படிவத்தை
              <span className="font-semibold"> ("TAF")</span> சமர்ப்பிப்பதன்
              மூலம், டிரேட் ஐடி: {invoiceData?.tradeSeq} க்கான வர்த்தகத்தை
              எளிதாக ஓனோ ஆர்க் இந்தியா பிரைவேட் லிமிடெட்{" "}
              <span className="font-semibold">(“ஓனோ பிளாட்ஃபார்ம்”)</span>{" "}
              தளத்தின் மூலம் ஏற்றுக்கொள்ள படிவம் சமர்ப்பிக்கபடுகிறது. இது கீழே
              குறிப்பிடப்பட்ட விதிமுறைகள் மற்றும் நிபந்தனைகளால்
              நிர்வகிக்கப்படும்{" "}
              <span className="font-semibold">("ஒப்பந்தம்”)</span>:
            </Text>
            <Text style={styles.subHeading}>
              1. இலக்கு பங்குதாரர் கீழே குறிப்பிடப்பட்டுள்ள விதிகளை
              புரிந்துகொண்டு ஒப்புக்கொள்கிறார்:
            </Text>
            <View style={styles.list}>
              <Text style={styles.listItem}>
                a. நல்லெண்ணம் மற்றும் நம்பிக்கை: இந்த வர்த்தக ஒப்பந்தம் தொடர்பான
                அனைத்து விஷயங்களிலும் மிகுந்த பொறுப்புடன் மற்றும்
                நம்பிக்கையுடனும் செயல்பட இலக்கு பங்குதாரர்
                கடமைப்பட்டிருக்கிறார்.
              </Text>
              <Text style={styles.listItem}>
                b. இலக்கு பங்குதாரர் சரியான நேரத்தில் செயல்படவும், வருகை உறுதி
                செய்யவும் மற்றும் அளவு, தரம் மற்றும் மாதிரி பொருட்கள் போன்ற
                விவரங்களை ஓனோ செயலியில் பதிவேற்றம் செய்வதன் மூலம் மூல பங்குதாரர்
                உடன் ஏற்படும் முரண்பாடுகளை தவிர்க்க படும். வாகனம் வந்த 6 மணி
                நேரத்திற்குள் வருகை மற்றும் தரம் விவரங்களை உறுதி செய்யாமல்
                இருப்பது இலக்கு தரப்பினரின் நம்பிக்கையை குறைப்பதாகும் மேலும் இது
                அபராதம் விதிக்க இட்டு செல்லும்.
              </Text>
              <Text style={styles.listItem}>
                c. கமிஷன் முறை வாகனமாக இருந்தால், வாகனம் வந்ததிலிருந்து 4
                நாட்களுக்குள் இறுதிப் பட்டியை வழங்க இலக்கு பங்குதாரர்
                வெளிப்படையாக ஒப்புக் கொண்டார். குறிப்பிட்ட காலத்திற்குள் பட்டி
                வழங்கத் தவறினால் அபராதம் மற்றும் ஒரு நாளின் வர்த்தக மதிப்பில்
                0.1% தாமதக் கட்டணம் விதிக்கப்படும்.
              </Text>
              <Text style={styles.listItem}>
                d. வாகனம் வந்ததிலிருந்து 7 நாட்களுக்குள் முழுமையான மற்றும்
                இறுதிக் கட்டணத்தை வழங்க இலக்கு பங்குதாரர் வெளிப்படையாக ஒப்புக்
                கொண்டார்.குறிப்பிட்ட நேரத்திற்குள் முழு மற்றும் இறுதி பணத்தைச்
                செலுத்த தவறினால், அபராதம் மற்றும் செலுத்த வேண்டிய நிலுவைத்
                தொகையில் ஒரு நாளைக்கு 0.1% தாமதக் கட்டணம் விதிக்கப்படும்.
              </Text>
              <Text style={styles.listItem}>
                e. வாகனத்தை வெற்றிகரமாக அனுப்பும்போது/போக்குவரத்தின் போது/வரும்
                போதும், வர்த்தகத்தை நிராகரிக்கவோ/ரத்து செய்யவோ இலக்கு
                பங்குதாரருக்கு உரிமை இல்லை. மேலும் எந்தவொரு காரணத்திற்காகவும்,
                இலக்கு பங்குதாரர் வர்த்தகத்தை அனுப்பப்பட்ட பிறகு/ போக்குவரத்து
                போதும் நிராகரித்தால், ONO க்கு வர்த்தக மதிப்பில் 5% மற்றும்
                போக்குவரத்து செலவுகள் ரத்து கட்டணம் வசூலிக்க உரிமை உண்டு.
              </Text>
              <Text style={styles.listItem}>
                f. இலக்கு பங்குதாரர் பொருட்களின் துல்லியமான, சரியான மற்றும்
                நம்பகமான புகைப்படங்கள் / வீடியோக்களை வழங்க வேண்டும்.மற்றும்
                அளவுகள், வகைகள், தரத்தை மேலும் ஏதேனும் தர முரண்பாடுகளின் வர்த்தக
                விவரங்கள் பற்றிய தகவல்களை வழங்க வேண்டும்.
              </Text>
            </View>
            <Text style={styles.subHeading}>
              2. ஏற்றுக்கொள்ளப்பட்ட வர்த்தக வாகனங்கள் நிராகரிப்பது / ரத்து
              செய்வது மீதான தடை:
            </Text>
            <View style={styles.list}>
              <Text style={styles.listItem}>
                a. எந்தவொரு சூழ்நிலையிலும் ஏற்றுக்கொள்ளப்பட்ட வர்த்தக வாகனங்கள்
                ONO இன் முறையான ஒப்புதல் மற்றும் ஒப்புதல் இல்லாமல்
                நிராகரிக்கப்பட கூடாது / ரத்து செய்யப்படக்கூடாது, ஏனெனில் இத்தகைய
                நடவடிக்கைகள் மூல தரப்பினரின் நம்பிக்கையை குறைப்பதாகும்.
              </Text>
              <Text style={styles.listItem}>
                b. இலக்கு பங்குதாரர் சரியான நேரத்தில் வந்து சேரும் வாகனத்தை
                இறக்கி, 4 மணி நேரத்திற்குள் வருகை உறுதிப்படுத்தல் விவரங்கள்
                மற்றும் ஏதேனும் தரச் சிக்கல்களை வழங்கத் தவறினால், வருகைக்கு
                அபராதம் மற்றும் வர்த்தக மதிப்புகளில் 5% வரை தாமதமான கட்டணங்கள்
                மற்றும் மொத்த மதிப்பு மீதான போக்குவரத்து செலவுகள் அடிப்படையில்
                தாமத கட்டணம் வசூலிக்க உரிமை உண்டு.
              </Text>
              <Text style={styles.listItem}>
                c. விலை ஏற்ற இறக்கங்கள் காரணம், குறிப்பாக ஆர்டர் முறை
                வர்த்தகத்தின் காரணமாக, சென்றடைந்த வாகனத்தை ரத்து செய்ய /
                நிராகரிக்க இலக்கு பங்குதாரருக்கு உரிமை இல்லை. வாகனம் ஏதேனும்
                கட்டாய நிராகரிப்பு / ரத்து செய்யப்பட்டால், உண்மையான அடிப்படையில்
                முழு டிரக் மதிப்பு மற்றும் போக்குவரத்து செலவு ஆகியவை
                வசூலிக்கப்படும்.
              </Text>
            </View>
          </View>
          <View style={styles.space}>
            <Text style={styles.subHeading}>3. ONO இதற்கு பொறுப்பேற்காது:</Text>
            <View style={styles.list}>
              <Text style={styles.listItem}>
                a. ஏதேனும் பொருட்களின் விலை ஏற்ற இறக்கங்கள் / குறையக்கூடிய
                அபாயங்கள்.
              </Text>
              <Text style={styles.listItem}>
                b. பிந்தைய வருகை தாமதங்கள் அல்லது கலைப்பு சவால்களுடன் தொடர்புடைய
                ஏதேனும் மற்றும் அனைத்து அபாயங்களும்.
              </Text>
              <Text style={styles.listItem}>
                c. ஓனோ முரண்பாடு தீர்க்கும் செயல்முறையை எளிதாக்கும் அதே
                வேளையில், பரிவர்த்தனை அல்லது வர்த்தகத்தின் போது ஏற்படும் தரச்
                சிக்கல்கள் காரணமாக வர்த்தகங்களை நிராகரிப்பதற்கு ஓனோ
                பொறுப்பாகாது.
              </Text>
              <Text style={styles.listItem}>
                d. பிளாட்ஃபார்மில் வெளியிடப்படும் அனைத்து தரவுகளும் படங்களும்
                பொருந்தக்கூடிய சட்டங்களின் இணங்குவதை உறுதி செய்யும் முயற்சிகள்
                இருந்தபோதிலும், பிளாட்ஃபார்மில் கவனக்குறைவாக தோன்றக்கூடிய
                சட்டத்திற்குப் புறம்பான படங்கள் அல்லது தரவு.
              </Text>
              <Text style={styles.listItem}>
                e. இந்த தளத்தில் பிரசுரிக்கப்படும் அனைத்து தரவுகளும் மற்றும்
                படங்களும் பொருந்தும் சட்டங்களுக்கு பொருந்தும் என்பதை உறுதி
                செய்யும் முயற்சிகளுக்கு மத்தியில் தற்செயலாக தோன்றக்கூடிய
                சட்டவிரோதமான எந்தப் படங்கள் அல்லது தரவுகள்.சட்ட தேவைகளுக்கு
                இணங்காத எந்தத் தகவல் அல்லது படங்களுக்கும் ஓனோ பொறுப்பேற்காது.
              </Text>
            </View>
            <Text style={[styles.para, styles.space]}>
              மேலே குறிப்பிடப்பட்டுள்ள விதிமுறைகள் மற்றும் நிபந்தனைகளை நான்
              படித்து, ஏற்றுக்கொண்டு, வர்த்தக முன்பணத்திற்கு விண்ணப்பித்துள்ளேன்
              என்பதை இதன்மூலம் ஒப்புக்கொள்கிறேன்.
            </Text>
            <View style={[styles.left, styles.space]}>
              <View style={styles.listItem}>
                <Text>வர்த்தக ஐடி: </Text>
                <Text style={styles.bold}> {invoiceData.tradeSeq}</Text>
              </View>
              <View style={styles.listItem}>
                <Text>அனுப்பிய நாள்: </Text>
                <Text style={styles.bold}> {invoiceData.date}</Text>
              </View>
              <View style={styles.listItem}>
                <Text>மூல பங்குதாரர் பெயர்: </Text>
                <Text style={styles.bold}> {invoiceData.srcName}</Text>
              </View>
              <View style={styles.listItem}>
                <Text>இடம்: </Text>
                <Text style={styles.bold}> {invoiceData.srcAddrLine}</Text>
              </View>
            </View>
            <View style={[styles.right]}>
              <View style={[styles.listItem, styles.listItemLeft]}>
                <Text> இலக்கு பங்குதாரர் பெயர்: </Text>
                <Text style={styles.bold}> {invoiceData.destName}</Text>
              </View>
              <View style={[styles.listItem, styles.listItemLeft]}>
                <Text>பிறந்த தேதி: </Text>
                <Text style={styles.bold}> {"-"}</Text>
              </View>
              <View style={[styles.listItem, styles.listItemLeft]}>
                <Text>இடம்: </Text>
                <Text style={styles.bold}>{invoiceData.destAddrLine}</Text>
              </View>
              {/* <Text style={styles.listItem}>இடம்: Ahmedabad</Text> */}
            </View>
          </View>
        </View>
        {/* <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Name</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Description</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Quantity</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Unit Price</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Total</Text>
            </View>
          </View>
          {invoiceData.items.map((item, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.description}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.quantity}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.unitPrice}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.total}</Text>
              </View>
            </View>
          ))}
        </View> */}
      </View>
      {/* Footer with Page Numbers */}

      <View style={styles.footer} fixed>
        <View style={styles.relative}>
          <View style={styles.space}>
            <Text style={styles.footerPara}>Yours faithfully,</Text>
            <Text style={[styles.footerPara, styles.bold]}>
              For <Text style={styles.bold}> {invoiceData.destTradename} </Text>
            </Text>
          </View>
          <View style={styles.space}>
            <Text style={styles.footerParaSm}>
              eSigned using OTP (ono.ag - {invoiceData?.destEsign})
            </Text>
            <Text style={styles.footerParaSm}>
              {invoiceData.destName} / {invoiceData?.destMobile}
            </Text>
            <Text style={styles.footerParaSm}>
              Date: {invoiceData.timeStamp}
            </Text>
          </View>
          <View>
            <Text style={styles.footerPara}>{invoiceData.destName}</Text>
            <Text style={[styles.footerPara, styles.bold]}>
              {invoiceData?.destMobile}
            </Text>
          </View>
          <Text
            render={({ pageNumber, totalPages }) =>
              `Page ${pageNumber} of ${totalPages}`
            }
            style={[styles.pageno, styles.footerParaSm]}
          />
          <Image src={logo_op} style={styles.logo_op} />
        </View>
      </View>
    </Page>
  </Document>
);

export default AcceptanceDocument;
