import { CSSProperties, FC } from "react";
import classes from "./style.module.scss";
import { IoMdRadioButtonOff } from "react-icons/io";
import { IoMdRadioButtonOn } from "react-icons/io";

const StyledRadioButton: FC<{
  data: Array<{ [key: string]: string | number }>;
  value: string | number;
  pointer: { label: string; value: string };
  displayType?: "horizontal" | "vertical";
  labelPositon?: "right" | "left";
  onChange: (value: string | number) => void;
  styles?: CSSProperties;
}> = ({
  data = [],
  value = "",
  pointer = { label: "label", value: "value" },
  displayType = "horizontal",
  labelPositon = "right",
  onChange,
  styles = {},
}) => {
  return (
    <div
      className={`mt-1 ${classes.container}`}
      data-view={displayType}
      style={styles}
    >
      {data.map((item) => (
        <span className={`${classes.radio}`}>
          {" "}
          <span
            key={`radio-${item[pointer.value]}`}
            className={`flex grow cursor-pointer items-center mb-2  ${
              value === item[pointer.value]
                ? classes.activeBox
                : classes.radiobox
            }`}
            data-position={labelPositon}
            onClick={() => {
              onChange && onChange(item[pointer.value]);
            }}
          >
            {value === item[pointer.value] ? (
              <IoMdRadioButtonOn className={`${classes.active}`} />
            ) : (
              <IoMdRadioButtonOff />
            )}
            <span className={`text-sm ml-1`}>{item[pointer.label]}</span>
          </span>
        </span>
      ))}
    </div>
  );
};
export default StyledRadioButton;
