import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LOGGEDIN, LOGGEDIN_USER_INFO } from "../constants/storage-keys";
import { TCrop } from "../types";
import { TEmployeInfo } from "../types/auth";
type TinitialState = {
  loggedin: boolean;
  adminId: number;
  userInfo: TEmployeInfo;
  allCrops: TCrop[];
};
const getInitState = (): TinitialState => {
  let userInfo = JSON.parse(
    localStorage.getItem(LOGGEDIN_USER_INFO) || JSON.stringify({})
  );
  return {
    loggedin: localStorage.getItem(LOGGEDIN) === "1" ? true : false,
    adminId: userInfo.adminId,
    userInfo: userInfo,
    allCrops: [],
  };
};
const initialState: TinitialState = getInitState();
const authSlice = createSlice({
  name: "authentication",
  initialState: initialState,
  reducers: {
    setLoggedinStatus: (
      state,
      action: PayloadAction<{ loggedin: boolean; userInfo?: TEmployeInfo }>
    ) => {
      state.loggedin = action.payload.loggedin;
      if (action.payload.loggedin === true && action.payload.userInfo) {
        state.userInfo = action.payload.userInfo;
        state.adminId = action.payload.userInfo.adminId;
        localStorage.setItem(LOGGEDIN, "1");
        localStorage.setItem(
          LOGGEDIN_USER_INFO,
          JSON.stringify(action.payload.userInfo)
        );
      }
    },
    handelLogout: (state, action) => {
      state.loggedin = false;
    },
    setAllCrops: (state, action: PayloadAction<TCrop[]>) => {
      state.allCrops = action.payload;
    },
  },
});

export const { setLoggedinStatus, handelLogout, setAllCrops } =
  authSlice.actions;
export default authSlice.reducer;
