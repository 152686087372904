import { useSelector } from "react-redux";
import useAxiosClient from "../../../hooks/useAxiosClient";
import { RMdetails } from "../../../types";
import { TRootState } from "../../../store";
import { useState } from "react";

const useRm = ({}) => {
    
const [searchTerm, setSearchTerm] = useState("");
const [selectedOpt, setSelectedOpt] = useState<number | null>(null);
const [relationshipManager, setRelationshipManager] = useState<RMdetails[]>(
  []
);

const { handleGetCall } = useAxiosClient();

  const { adminId } = useSelector((state: TRootState) => ({
    adminId: state.auth.adminId,
  }));

const getRmDetails = (rmId = 0) => {
  handleGetCall<RMdetails[]>(
    `admin/trade-platform/rms/adminId/${adminId}`
  ).then(({ data }) => {
    if (data) {
      //to show the selected RM at the top of the list
      if (rmId > 0) {
        var index = data.findIndex((rm) => rm.id == rmId);
        setSearchTerm(data[index].name);

        let relManager = data[index];
        data.splice(index, 1);
        data.splice(0, 0, relManager);
        setRelationshipManager(data);
      } else {
        setRelationshipManager(data);
      }
    } else {
      setRelationshipManager([]);
    }
  });
};

return{
    searchTerm, 
    setSearchTerm,
    relationshipManager, 
    setRelationshipManager,
    getRmDetails,
    selectedOpt, 
    setSelectedOpt
}
  }
  export default useRm;
