import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Button, Card, FileUploader } from "../../components/ui";
import DocumentPreview from "../../components/commons/document-preview";
import { FaXmark } from "react-icons/fa6";
import { TRootState } from "../../store";
import useArrivalsView from "./useArrivalsView";
import useAxiosClient from "../../hooks/useAxiosClient";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";

const PattiDocs: FC<{ type: string }> = ({ type }) => {
  const params = useParams();
  const { adminId } = useSelector((state: TRootState) => ({
    adminId: state.auth.adminId,
  }));
  const navigate = useNavigate();
  const {
    onUploadReceiptImage,
    onRemoveReceiptImage,
    alltradeDetail,
    pattiDocuments,
    setPattiDocuments,
    role,
  } = useArrivalsView({
    traderId: parseInt(params.traderId || "0"),
    tradeId: parseInt(params.tradeId || "0"),
    mode: false,
    type: "",
  });
  const [removeImageStatus, setRemoveImageStatus] = useState(false);
  const { handlePutCall } = useAxiosClient();
  useEffect(() => {
    if (pattiDocuments.length > 0) {
      setTimeout(() => {
        //confrirmArrival();
      }, 1000);
    }
  }, [pattiDocuments]);
  useEffect(() => {
    console.log(removeImageStatus, "removeImageStatus");
    if (removeImageStatus) {
      setTimeout(() => {
        // confrirmArrival();
      }, 1000);
    }
  }, [removeImageStatus]);
  const confrirmArrival = async () => {
    try {
      let imgs: Array<{
        id: number;
        imgFrom: string;
        reqId: number;
        status: number;
        addedBy: number;
        type:
          | "tapal"
          | "receipt"
          | "other"
          | "arrival"
          | "patti"
          | "payment"
          | "arrivalWight";
        url: string;
      }> = [];
      let arrivalImages = imgs;
      let pattiImages = imgs;
      if (removeImageStatus) {
        if (alltradeDetail != null) {
          if (alltradeDetail?.pattiDocs.length > 0) {
            for (let file of alltradeDetail?.pattiDocs) {
              if (file.status == 0) {
                pattiImages.push({
                  ...file,
                  addedBy: adminId,
                });
              }
            }
          }
        }
      }
      if (pattiDocuments.length > 0) {
        for (let file of pattiDocuments) {
          pattiImages.push({
            id: 0,
            imgFrom: "TP_TRD",
            reqId: alltradeDetail?.tradeId || 0,
            status: 1,
            addedBy: adminId || 0,
            type: "patti",
            url: file.url,
          });
        }
      }

      let postBody = {};

      if (type == "PATTI") {
        postBody = {
          adminId: adminId,
          pattiFinal: {
            comm: alltradeDetail?.comm,
            discount: alltradeDetail?.discount,
            freightPaid: alltradeDetail?.freightPaid,
            images: pattiImages,
            lbrCharges: alltradeDetail?.lbrCharges,
            misc: alltradeDetail?.misc,
            pattiAmt: alltradeDetail?.pattiAmt,
            totalPayable: alltradeDetail?.totalPayable,
            tradeId: alltradeDetail?.tradeId,
            tradeStatus: alltradeDetail?.tradeStatus,
            pattiDate: alltradeDetail?.pattiDate,
            comments: alltradeDetail?.pattiComments || "UPDATED",
            finalPayable: alltradeDetail?.finalPayable,
            pfFee:
              alltradeDetail?.tradeType == "DEST"
                ? alltradeDetail?.destPf
                : alltradeDetail?.srcPf,
            pfFeeDisc:
              alltradeDetail?.tradeType == "SOURCE"
                ? alltradeDetail?.srcDis
                : alltradeDetail?.destDis,

            tradeType: alltradeDetail?.tradeMode,
            finalReceivable: alltradeDetail?.finalReceivable,
            // type: typeFrom,
            type: "DEST",
            advPaid: alltradeDetail?.advancePaid,
            totalReceivable: alltradeDetail?.totalReceivable,
          },
          tradeId: alltradeDetail?.tradeId,
          tradeMode: alltradeDetail?.tradeMode,
        };
      }

      //   let statusVal = searchParams.get("tab") === "CLOSED" ? "CLOSED" : "OPEN";
      await handlePutCall<any>({
        URL: "admin/trade-platform/trades/metadata",
        apiParams: postBody,
      })
        .then(({ data, status }) => {
          toast.success(status.message);
          navigate(
            `/trade-detail/${alltradeDetail?.srcPartyId}/${alltradeDetail?.tradeId}?tab=OPEN`
          );
          setRemoveImageStatus(false);
          setPattiDocuments([]);
          // setEditDetail({ section: "", mode: "view" });
          // getTradeDetail();
          //   navigate(
          //     `/trade-detail/${alltradeDetail?.srcPartyId}/${alltradeDetail?.tradeId}?tab=${statusVal}`
          //   );
        })
        .catch((err: any) => {
          toast.error(err.response.data.status.message);
        });
    } catch (ex) {}
  };
  const [onEdit, setOnEdit] = useState(false);
  return (
    <>
      {alltradeDetail != null && (
        <Card
          titile={
            <div className="flex items-center">
              <span>Patti Documents</span>
              {!onEdit && role == "ADMIN" && (
                <Button
                  variant="outlined"
                  className="ml-auto gap-1"
                  size="small"
                  onClick={() => {
                    setOnEdit(true);
                  }}
                >
                  <FaEdit className="" />
                  Edit
                </Button>
              )}

              {onEdit && role == "ADMIN" && (
                <Button
                  variant="outlined"
                  className="ml-auto gap-1"
                  size="small"
                  onClick={() => {
                    setOnEdit(false);
                    confrirmArrival();
                  }}
                >
                  <FaEdit className="" />
                  Save
                </Button>
              )}
            </div>
          }
          className="px-2"
        >
          <div className="mt-2 px-2">
            <h2 className="color-text-primary px-4 pb-2 pt-2 text-sm">
              Final Patti :
            </h2>
            <div className="flex flex-wrap gap-2 border-b overflow-auto pb-4">
              {/* {editDetail.section === "patti_document" &&
                                      editDetail.mode === "edit" && ( */}
              <div className="shrink-0 overflow-hidden p-2">
                {alltradeDetail?.tradeStatus !== "CLOSED" &&
                  role == "ADMIN" && (
                    <FileUploader
                      label=""
                      onChange={(e) => {
                        onUploadReceiptImage(
                          "patti",
                          e.target.files ? e.target.files[0] : null
                        );
                        setOnEdit(true);
                      }}
                    />
                  )}
              </div>
              {/* )} */}
              {pattiDocuments.length > 0 &&
                pattiDocuments.map((image, i) => (
                  <div
                    className="shrink-0 overflow-hidden border-2 p-2 rounded relative"
                    style={{
                      width: "33.333%",
                      height: "10rem",
                    }}
                    key={`arival-doc-${i}`}
                  >
                    <a href={image.url} target="_blank">
                      <DocumentPreview
                        url={image.url}
                        docType={image.docType}
                      />
                    </a>
                    {/* {editDetail.section === "patti_document" &&
                      editDetail.mode === "edit" && ( */}
                    {onEdit && (
                      <span
                        className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                        style={{
                          background: "#ff0000de",
                        }}
                        onClick={() => {
                          onRemoveReceiptImage("patti", i);
                        }}
                      >
                        <FaXmark style={{ color: "white" }} />
                      </span>
                    )}

                    {/* )} */}
                  </div>
                ))}
              {alltradeDetail.pattiDocs.length > 0 &&
                alltradeDetail.pattiDocs
                  .filter((img) => img.status == 1)
                  .map((image, i) => (
                    <div
                      className="shrink-0 overflow-hidden border-2 p-2 rounded relative"
                      style={{
                        width: "33.333%",
                        height: "10rem",
                      }}
                      key={`receipt-doc-${i}`}
                    >
                      <a href={image.url} target="_blank">
                        <DocumentPreview
                          url={image.url}
                          docType={image.docType}
                        />
                      </a>
                      {onEdit && (
                        <span
                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                          style={{ background: "#ff0000de" }}
                          onClick={() => {
                            onRemoveReceiptImage("patti", image.id, true);
                            setRemoveImageStatus(true);
                          }}
                        >
                          <FaXmark style={{ color: "white" }} />
                        </span>
                      )}
                    </div>
                  ))}
              {alltradeDetail.pattiDocs.length <= 0 &&
                pattiDocuments.length <= 0 && (
                  <div
                    className="border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                    style={{
                      width: "33.333%",
                      height: "10rem",
                    }}
                  >
                    No Patti Documents Uploaded
                  </div>
                )}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};
export default PattiDocs;
