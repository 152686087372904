import React, { FC, useState } from "react";
import cancel from "../../assets/images/cancel.svg";
import edit from "../../assets/images/edit_round.svg";
import user from "../../assets/images/single_bill.svg";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "../../store";
import DocumentPreViewer from "../../components/commons/document-preview";
import {
  getCurrencyNumberWithSymbol,
  getMaskedMobileNumber,
} from "../../helpers/get-currency-number";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useAxiosClient from "../../hooks/useAxiosClient";
import Modal from "../../components/ui/modal";
import ModalHeader from "../../components/ui/modal/modal-header";
import { Button } from "../../components/ui";
import { updateEditAdvPayment } from "../../reducers/tradeSlice";
const PaymentHistory: FC<{
  traderId: number;
  tradeId: number;
  onClose: (paymentDone: boolean) => void;
  recordType?: string;
  onEdit: (isEdit: boolean) => void;
  role: string;
}> = ({ traderId, tradeId, onClose, recordType, onEdit, role }) => {
  const { paymentHistoryObj, paymentUpdateStatus, tradeDetail, adminId } =
    useSelector((state: TRootState) => ({
      paymentHistoryObj: state.trades.paymentHistoryObj,
      paymentUpdateStatus: state.trades.paymentUpdateStatus,
      tradeDetail: state.trades.tradeDetails,
      adminId: state.auth.adminId,
    }));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handlePostCall } = useAxiosClient();
  const paymentTypeVal = paymentUpdateStatus
    ? paymentHistoryObj?.inOutWard
    : recordType;
  const [cancelPopup, setCancelPopup] = useState(false);
  const cancelRecordPayment = () => {
    handlePostCall({
      URL: "admin/trade-platform/trades/payments",
      apiParams: {
        action: "CANCEL",
        adminId: adminId,
        amount: paymentHistoryObj?.amount,
        date: moment(paymentHistoryObj?.date).format("YYYY-MM-DD"),
        images: [],
        inOutWard: paymentHistoryObj?.inOutWard,
        pmtMode: paymentHistoryObj?.pmtMode,
        pmtType: paymentHistoryObj?.pmtType,
        tradeId: tradeId,
        traderId: traderId,
        id: paymentHistoryObj?.id,
        status: 0,
        comments: paymentHistoryObj?.comments || "",
        reqAdv: {
          advApproved: adminId,
          advDisb: paymentHistoryObj?.amount,
          disbDate: moment(paymentHistoryObj?.date).format("YYYY-MM-DD"),
          tradeId: tradeId,
        },
      },
    })
      .then(({ data }) => {
        toast.success("Cancelled successfully");
        navigate(
          `/trade-detail/${tradeDetail?.srcPartyId}/${tradeDetail?.tradeId}?tab=OPEN`
        );
        setCancelPopup(false);
        onClose(false);
        // getTradeDetail();
      })
      .catch(() => {
        toast.error("something went wrong");
      });
  };
  return (
    <div>
      <div className="flex">
        <div className="grow-[4]" style={{ height: "calc(-8.4rem + 100vh)" }}>
          <div className="border-primary p-4 br-10 m-4">
            <div className="flex border-b pb-3 justify-between">
              <div className="flex">
                <img src={user} className="w-12 h-12 br-100" />
                <div className="ml-2 flex flex-col">
                  <span className="font-semibold text-sm">
                    {"From :" + tradeDetail?.srcTradeName}
                  </span>
                  <span className="text-sm font-light">
                    Trader-{paymentHistoryObj?.id} |{" "}
                    {getMaskedMobileNumber(tradeDetail?.srcMobile || "")}
                  </span>
                </div>
              </div>

              <div className=" flex flex-col">
                <span className="text-sm font-light">Date</span>
                <p className="font-semibold text-sm">
                  {moment(paymentHistoryObj?.date).format("DD-MMM-YYYY")}
                </p>
              </div>
            </div>
            <div className="flex text-sm border-b py-3 justify-between">
              <div className="flex  flex-col">
                <span className=" font-light">
                  {paymentTypeVal == "INWARD"
                    ? "Amount Received"
                    : "Amount Paid"}
                </span>
                <span className="font-semibold">
                  {getCurrencyNumberWithSymbol(paymentHistoryObj?.amount, true)}
                </span>
              </div>

              <div className="flex flex-col">
                <span className="font-light">
                  {paymentTypeVal == "INWARD"
                    ? "Received Till Date"
                    : "Paid Till Date"}
                </span>
                <span className="font-semibold">
                  {paymentTypeVal == "OUTWARD" &&
                    getCurrencyNumberWithSymbol(tradeDetail?.outwardPmt, true)}
                  {paymentTypeVal == "INWARD" &&
                    getCurrencyNumberWithSymbol(tradeDetail?.inwardPmt, true)}
                </span>
              </div>

              <div className="flex flex-col">
                <span className="font-light">
                  {paymentTypeVal == "INWARD"
                    ? "Balance Receivable"
                    : "Balance Payable"}
                </span>
                <p className="font-semibold">
                  {" "}
                  {paymentTypeVal === "INWARD"
                    ? getCurrencyNumberWithSymbol(
                        (Number(tradeDetail?.totalPayable) +
                          Number(tradeDetail?.destPf) -
                          Number(tradeDetail?.destDis) || 0) -
                          (tradeDetail?.inwardPmt || 0),
                        true
                      )
                    : getCurrencyNumberWithSymbol(
                        (Number(tradeDetail?.totalReceivable) -
                          Number(tradeDetail?.srcPf) +
                          Number(tradeDetail?.srcDis) || 0) -
                          (tradeDetail?.outwardPmt || 0),
                        true
                      )}
                </p>
              </div>
            </div>
            <div className="flex text-sm border-b justify-between py-3">
              <div className="flex flex-col">
                <span className="font-light">Payment Type</span>
                <p className="font-semibold">{paymentHistoryObj?.pmtType}</p>
              </div>
              <div className="flex flex-col">
                <span className="font-light">Mode </span>
                <p className="font-semibold">{paymentHistoryObj?.pmtMode}</p>
              </div>
            </div>
            <div className="flex text-sm flex-col py-3 border-b">
              <span className="font-light">Comments</span>
              <p className="font-semibold">
                {paymentHistoryObj?.comments || "-"}
              </p>
            </div>
            <div className=" mt-3">
              <span className="text-sm font-light">Upload Document</span>
              <div className="flex flex-row overflow-auto w-full hide-scroll-bar">
                {paymentHistoryObj?.images.length != 0 &&
                  paymentHistoryObj?.images.map((file, i) => (
                    <div
                      key={file.url}
                      className={"shrink-0 px-2"}
                      style={{ width: "15rem" }}
                    >
                      <div className="border-2 p-2 rounded flex justify-center relative min-h-full">
                        <a href={file.url} target="_blank">
                          <DocumentPreViewer
                            docType={file.docType}
                            url={file.url}
                          />
                        </a>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        {role == "ADMIN" && (
          <div className="w-50 flex flex-col border-l p-4">
            <button
              onClick={() => {
                onEdit(true);
                dispatch(updateEditAdvPayment({ editAdvancePayment: true }));
              }}
              className="flex flex-col justify-center items-center"
            >
              <img src={edit} className="cursor-pointer w-8" />
              <span className="text-xs">EDIT</span>
            </button>

            <button
              className="mt-5 flex flex-col justify-center items-center"
              onClick={() => {
                setCancelPopup(true);
              }}
            >
              <img src={cancel} className="cursor-pointer w-8" />
              <span className="text-xs">CANCEL</span>
            </button>
          </div>
        )}
      </div>
      <>
        <Modal
          open={cancelPopup}
          onClose={() => {
            setCancelPopup(false);
          }}
          width="30vw"
        >
          <>
            <ModalHeader title={"Cancel Payment"} content={<></>} />
            <div className="text-center py-7 px-10">
              <img
                src={cancel}
                className="h-8 w-8 flex justify-center items-center mx-auto mb-4"
              />
              <span className="text-sm mx-4">
                You are about to cancel the payment. Are you sure you want to
                proceed?
              </span>
            </div>
            <div className="flex items-center bottom-0 right-0 px-4 py-4 border-top gap-2">
              <Button
                variant="primaryLight"
                className="w-full"
                text="NO"
                onClick={() => {
                  setCancelPopup(false);
                }}
              />
              <Button
                variant="contained"
                className="w-full"
                onClick={() => {
                  cancelRecordPayment();
                }}
              >
                YES
              </Button>
            </div>
          </>
        </Modal>
      </>
    </div>
  );
};
export default PaymentHistory;
