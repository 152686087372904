import { ChangeEvent, FC, useEffect, useState } from "react";
import PartnerInfo from "../../components/partner-info";
import { Button, FileUploader, Input, RadioButton } from "../../components/ui";
import market from "../../assets/images/mandi.svg";
import call from "../../assets/images/call.svg";
import { toast } from "react-toastify";
import { TSupplyDemandReq } from "../../types";
import { useSelector } from "react-redux";
import { TRootState } from "../../store";
const ContactInfo: FC<{
  onModalClose: any;
  data?: TSupplyDemandReq | null;
}> = ({ onModalClose, data }) => {
  const { traderContactInfo } = useSelector((state: TRootState) => ({
    traderContactInfo: state.trades.traderContactInfo,
  }));
  const handleCopyClick = (mobileNumber: any) => {
    navigator.clipboard
      .writeText(mobileNumber)
      .then(() => {
        toast.success("Mobile number copied successfully");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };
  console.log(traderContactInfo, "traderContactInfo");
  return (
    <>
      <div className="border br-10 py-4 my-3 px-3">
        <div className="pb-3 border-b">
          <PartnerInfo
            profilePic={traderContactInfo?.profilePic || ""}
            name={traderContactInfo?.traderName || ""}
            shortName={""}
            partnerType={traderContactInfo?.tradeType}
            partnerId={traderContactInfo?.traderId}
            partnerMobile={""}
            partyName={""}
            distanceFrom={true}
            distance={traderContactInfo?.distance.toString() || ""}
          />
        </div>
        <div className="py-3 border-b">
          <div className="flex gap-4 items-center">
            <img src={market} className="w-8 h-8 " />
            <div className="flex flex-col">
              <span className="text-sm">Mandi Address </span>
              <span>
                <span className="text-sm font-semibold">
                  {traderContactInfo?.addressLine
                    ? traderContactInfo?.addressLine + ","
                    : "-"}
                  {traderContactInfo?.state ? traderContactInfo?.state : ""}
                  {traderContactInfo?.pincode
                    ? " " + traderContactInfo?.pincode
                    : ""}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="py-3 border-b flex justify-between items-center">
          <div className="flex gap-4 items-center">
            <img src={call} className="w-8 h-8 " />
            <div className="flex flex-col">
              <span className="text-sm">Mobile number </span>
              <span>
                <span className="text-sm font-semibold">
                  {traderContactInfo?.mobile}
                </span>
              </span>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <Button
              variant="lightBlue"
              className="w-full"
              text="Copy"
              onClick={() => {
                handleCopyClick(traderContactInfo?.mobile);
              }}
            />
            <a href="tel:+1886544764325">
              <Button variant="contained" className="w-full" onClick={() => {}}>
                Call
              </Button>
            </a>
          </div>
        </div>
        <div className="pt-3 flex justify-between items-center">
          <div className="flex gap-4 items-center">
            <img src={call} className="w-8 h-8 " />
            <div className="flex flex-col">
              <span className="text-sm">Alternative Mobile </span>
              <span>
                <span className="text-sm font-semibold">
                  {traderContactInfo?.altMobile || "-"}
                </span>
              </span>
            </div>
          </div>
          {traderContactInfo?.altMobile && (
            <div className="flex items-center gap-4">
              <Button
                variant="lightBlue"
                className="w-full"
                text="Copy"
                onClick={() => {
                  handleCopyClick(traderContactInfo?.altMobile);
                }}
              />
              <a href="tel:+1886544764325">
                <Button variant="contained" className="w-full">
                  Call
                </Button>
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default ContactInfo;
