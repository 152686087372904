import { FC, useRef, ChangeEvent } from "react";
import { FaUpload } from "react-icons/fa6";

const FileUploader: FC<{
  label?: string;
  required?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  fromCropUpload?: boolean;
}> = ({ label, required, onChange, fromCropUpload }) => {
  const ref = useRef<HTMLInputElement>(null);
  return (
    <>
      {label !== "" && (
        <label className="text-sm font-semibold mb-2 flex">
          {label} <span className="color-red">{required === true && "*"}</span>
        </label>
      )}
      <input
        type="file"
        ref={ref}
        multiple
        style={{ display: "none" }}
        onChange={(event) => {
          onChange(event);
          event.target.value = "";
        }}
      />
      <div
        className="h-28 flex items-center justify-center border-2 border-dashed	 rounded cursor-pointer text-center"
        onClick={() => {
          ref.current?.click();
        }}
      >
        <span className="flex flex-col items-center px-2">
          <FaUpload className="color-blue" />
          <span className="text-sm color-sub-text font-medium">
            {fromCropUpload ? "Upload Photo " : "Upload Document Here"}
          </span>
        </span>
      </div>
    </>
  );
};
export default FileUploader;
