import { Font } from "@react-pdf/renderer";

// Register fonts
Font.register({
  family: "Roboto",
  src: "/fonts/Roboto-Regular.ttf",
});
Font.register({
  family: "Roboto",
  src: "/fonts/Roboto-Bold.ttf",
});
Font.register({
  family: "NotoSansTamil",
  src: "/fonts/NotoSansTamil-Regular.ttf",
});

Font.register({
  family: "NotoSansDevanagari",
  src: "/fonts/NotoSansDevanagari-Regular.ttf",
});

Font.register({
  family: "NotoSansDevanagari",
  src: "/fonts/NotoSansDevanagari-Bold.ttf",
});
Font.register({
  family: "NotoSansTamil",
  src: "/fonts/NotoSansTamilSemiBold.ttf",
});
