
import comingsoon from '../../assets/images/coming_soon.gif';

const Borrowers = () => {
    return (
        <div>
           <div className="flex items-center justify-center h-screen">
  <div className="text-center">
    <img
      src={comingsoon}
      className="w-[250px] h-[250px]"
      alt="Loading"
    />
    <h3 className="text-lg font-semibold">Coming soon...</h3>
  </div>
</div>

        </div>
    );
};

export default Borrowers;


