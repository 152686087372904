import { FaImage } from "react-icons/fa6";

const NoCropView = () => {
  return (
    <div
      className="br-10 flex flex-col justify-center items-center p-2"
      style={{ width: "120px", height: "70px", backgroundColor: "#E4E4E4" }}
    >
      <FaImage color="#D0D0D0" />
      <span className="color-sub-text text-center" style={{ fontSize: "10px" }}>
        Photos not available
      </span>
    </div>
  );
};
export default NoCropView;
