import { FC } from "react";
import { useSelector } from "react-redux";
import { TRootState } from "../../store";
import { TDestinationPartner } from "../../types";
import classes from "./dropdown.module.scss";
import PartnerInfo from "../partner-info";
import ONOSelect3 from "./select3";
const PartyDropDown: FC<{
  open: boolean;
  selectedPartner?: TDestinationPartner | null;
  onClose: () => void;
  onChange: (partner: TDestinationPartner) => void;
  allTraders: TDestinationPartner[];
}> = ({ open, onClose, selectedPartner, onChange, allTraders }) => {
  const { traders } = useSelector((state: TRootState) => ({
    traders: state.trades.partyData,
  }));
  return (
    <ONOSelect3
      open={open}
      onClose={onClose}
      className="overflow-auto"
      style={{ maxHeight: "20rem", zIndex: "3" }}
    >
      <div className="px-2">
        {allTraders.length > 0 ? (
          allTraders.map((partner) => (
            <div
              key={partner.traderId}
              onClick={() => {
                onChange(partner);
              }}
              className={`px-2 py-2 my-1 border rounded-md ${classes.partner}`}
              data-selected={
                typeof selectedPartner !== "undefined" &&
                selectedPartner?.traderId === partner.traderId
                  ? true
                  : false
              }
            >
              <PartnerInfo
                profilePic=""
                name={partner.tradeName}
                shortName={""}
                partnerType={"Trader"}
                partnerId={partner.traderId}
                partnerMobile={partner.mobile}
                partyName={partner.name}
              />
            </div>
          ))
        ) : (
          <div className="py-10 px-5 bg-white text-center">
            <span>No Data Available</span>
          </div>
        )}
      </div>
    </ONOSelect3>
  );
};
export default PartyDropDown;
