import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authSlice";
import tradeReducer from "./reducers/tradeSlice";
const store = configureStore({
  reducer: {
    auth: authReducer,
    trades: tradeReducer,
  },
});
export type TRootState = ReturnType<typeof store.getState>;
export default store;
