import { ChangeEvent, FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import PartnerInfo from "../../components/partner-info";
import SelectCrop from "../../components/select-crop";
import { Button, FileUploader, Input } from "../../components/ui";
import SelectDate from "../../components/ui/select-date";
import { FaXmark } from "react-icons/fa6";
import DocumentPreview from "../../components/commons/document-preview";
import StyledRadioButton from "../../components/ui/styledRadioButton";
import useDemandDetailsEdit from "../../hooks/useDemandPostEdit";
import { OnoSelect } from "../../components/ui/select2";
import moment from "moment";
const calculate = ({
  qty,
  rate,
  qtyUnit,
}: {
  qty: number;
  rate: number | string;
  qtyUnit: string | number;
}) => {
  let total = 0;
  if (qtyUnit == "MT/TON") {
    total = Number(qty) * 1000 * Number(rate);
  } else if (qtyUnit == "QUINTAL") {
    total = Number(qty) * 100 * Number(rate);
  } else {
    total = Number(qty) * Number(rate);
  }
  return parseFloat(total.toFixed(2));
};
let calculateTimer: any = null;
const EditDemandPost: FC<{
  onModalClose: any;
  type?: string;
  fromDemandTab?: boolean;
  postID?: any;
}> = ({ onModalClose, type, fromDemandTab, postID }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const {
    demandDetails,
    handleDateSelection,
    setDemandDetails,
    submit,
    // onRemoveReceiptImage,
    onUploadReceiptImage,
    selectedDate,
    cropImages,
    onRemoveReceiptImage,
    handleOfferDateSelection,
    selectedOfferDate,
  } = useDemandDetailsEdit({
    id: fromDemandTab ? postID || "0" : parseInt(params.postId || "0"),
    modalClose: onModalClose,
    fromDemandTab: fromDemandTab,
  });
  const [totalVal, setTotal] = useState(demandDetails?.expTotal);
  const initCalculate = () => {
    if (calculateTimer) {
      clearTimeout(calculateTimer);
    }
    calculateTimer = setTimeout(() => {
      let total = calculate({
        qty: typeof demandDetails?.qty === "number" ? demandDetails?.qty : 0,
        rate: demandDetails?.expRate ? demandDetails?.expRate : 0,
        qtyUnit: demandDetails?.unitType || "",
      });
      setTotal(total);
      setDemandDetails({
        ...demandDetails!,
        expTotal: total,
      });
    }, 300);
  };
  useEffect(() => {
    initCalculate();
  }, [demandDetails?.qty, demandDetails?.expRate, demandDetails?.unitType]);
  const formatString = (str: any) => {
    console.log(demandDetails?.validity, str);
    if (str) {
      return str.replace(/\s+/g, "").toLowerCase();
    }
  };
  useEffect(() => {
    formatString(demandDetails?.validity);
    let daysVal;

    if (demandDetails?.validity === "Next 1 days") {
      daysVal = 1;
    } else if (demandDetails?.validity === "Next 2 days") {
      daysVal = 2;
    } else if (demandDetails?.validity === "Next 3 days") {
      daysVal = 3;
    } else {
      daysVal = 0;
    }

    const today = new Date();
    const futureDate = new Date();
    futureDate.setDate(today.getDate() + daysVal);
    console.log(daysVal, futureDate, "date");
    setDemandDetails({
      ...demandDetails!,
      offerValidity: moment(futureDate).format("YYYY-MM-DD") || "",
    });
  }, [demandDetails?.validity]);
  // const calculateDateDifference = (daysFromToday: any) => {
  //   let daysVal;

  //   if (daysFromToday === "Next 1 days") {
  //     daysVal = 1;
  //   } else if (daysFromToday === "Next 2 days") {
  //     daysVal = 2;
  //   } else if (daysFromToday === "Next 3 days") {
  //     daysVal = 3;
  //   } else {
  //     daysVal = 0;
  //   }

  //   const today = new Date();
  //   const futureDate = new Date();
  //   futureDate.setDate(today.getDate() + daysVal);
  //   console.log(daysVal, futureDate, "date");
  //   setDemandDetails({
  //     ...demandDetails!,
  //     offerValidity: moment(futureDate).format("YYYY-MM-DD") || "",
  //   });
  //   return futureDate;
  // };

  return (
    <>
      <div
        className="pb-3 overflow-y-auto"
        style={{ height: "calc(100vh - 22rem)" }}
      >
        <div className="pb-3 w-full flex gap-4">
          <div className="grow-[3]">
            <div
              className={`relative flex items-center bg-white shrink-0 px-2 py-2 border br-10`}
              style={{ borderRightWidth: "1px", height: "70px" }}
            >
              <div className={`flex items-center cursor-pointer w-full`}>
                <PartnerInfo
                  profilePic={demandDetails?.profilePic || ""}
                  name={demandDetails?.traderName || ""}
                  shortName={""}
                  partnerType={"Trader"}
                  partnerId={demandDetails?.traderId}
                  partnerMobile={demandDetails?.mobile || ""}
                  partyName={demandDetails?.addressLine}
                />
              </div>
            </div>
          </div>
          <div className="grow-[3]">
            <div className="grow overflow-auto scrollbar">
              <SelectCrop status="true" />
            </div>
          </div>
        </div>
        <div className="pt-3 w-full flex gap-4">
          <div className="grow-[3]">
            <label className="text-sm">
              Quality <span className="color-red">{"*"}</span>
            </label>
            <StyledRadioButton
              displayType="horizontal"
              data={[
                { label: "Best", value: "BEST" },
                { label: "Average", value: "AVERAGE" },
                { label: "Low", value: "LOW" },
              ]}
              value={demandDetails?.quality || ""}
              pointer={{ label: "label", value: "value" }}
              onChange={(value) => {
                setDemandDetails({
                  ...demandDetails!,
                  quality: value || "",
                });
              }}
              styles={{ display: "flex", gap: "10px" }}
            />
          </div>
          <div className="grow-[3]">
            <label className="text-sm">
              Unit Type <span className="color-red">{"*"}</span>
            </label>
            <StyledRadioButton
              displayType="horizontal"
              data={[
                { label: "KGS", value: "KGS" },
                { label: "Quintal", value: "QUINTAL" },
                { label: "MT/TON", value: "MT/TON" },
              ]}
              value={demandDetails?.unitType || ""}
              pointer={{ label: "label", value: "value" }}
              onChange={(value) => {
                setDemandDetails({
                  ...demandDetails!,
                  unitType: value || "",
                });
              }}
              styles={{ display: "flex" }}
            />
          </div>
        </div>

        <div className="pt-3 w-full flex gap-4">
          <table>
            <tr>
              <th className="text-sm font-medium">Quantity*</th>
              <td>
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={demandDetails?.qty}
                  onFocus={(e) => {
                    e.target.value === "0" &&
                      setDemandDetails({
                        ...demandDetails!,
                        qty: "",
                      });
                  }}
                  onChange={(e) => {
                    setDemandDetails({
                      ...demandDetails!,
                      qty:
                        e.target.value != "" ? parseFloat(e.target.value) : "",
                    });
                  }}
                  fromSupplyTab={true}
                />
              </td>
            </tr>
            <tr>
              <th className="text-sm font-medium">Expected Rate* (Per KG)</th>
              <td>
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={demandDetails?.expRate}
                  onFocus={(e) => {
                    e.target.value === "0" &&
                      setDemandDetails({
                        ...demandDetails!,
                        expRate: "",
                      });
                  }}
                  onChange={(e) => {
                    setDemandDetails({
                      ...demandDetails!,
                      expRate:
                        e.target.value != "" ? parseFloat(e.target.value) : "",
                    });
                  }}
                  fromSupplyTab={true}
                />
              </td>
            </tr>
            <tr>
              <th
                className="text-sm font-medium"
                style={{ borderRight: "0px" }}
              ></th>
              <td className="text-sm font-medium" style={{ borderLeft: "0px" }}>
                Expected Total : {totalVal}
              </td>
            </tr>
          </table>
        </div>
        <div className="pt-3">
          <div className="flex gap-4">
            <div className="grow-[3]">
              <span className="text-sm">
                Expected Date <span className="color-red">*</span>
              </span>
              <SelectDate
                onChange={handleDateSelection}
                selectedDate={selectedDate || ""}
              />
            </div>
            <div className="grow-[3] flex flex-col gap-1">
              <span className="text-sm mb-2">
                Offer Validity <span className="color-red">*</span>
              </span>
              <div className="">
                <OnoSelect
                  value={demandDetails?.validity}
                  options={[
                    { label: "Today Only", value: "Today Only" },
                    {
                      label: "Next 1 Days",
                      value: "Next 1 days",
                    },
                    {
                      label: "Next 2 Days",
                      value: "Next 2 days",
                    },
                    {
                      label: "Next 3 Days",
                      value: "Next 3 days",
                    },
                  ]}
                  onChange={(e) => {
                    // calculateDateDifference(e.value);
                    setDemandDetails({
                      ...demandDetails!,
                      validity: e.value,
                    });
                  }}
                  fromSupplyDemand={true}
                />
              </div>
              <span className="text-sm">
                Offer Validity date :{" "}
                {moment(demandDetails?.offerValidity).format("DD-MMM-YYYY")}
              </span>
              {/* <SelectDate
                onChange={handleOfferDateSelection}
                selectedDate={selectedOfferDate || ""}
              /> */}
            </div>
          </div>
        </div>
        <div className="pt-3">
          <span className="text-sm">Upload Photo</span>
          <div className="flex flex-wrap gap-2 overflow-auto pb-4">
            <div className="shrink-0 overflow-hidden p-2">
              <FileUploader
                label=""
                onChange={(e) => {
                  onUploadReceiptImage(
                    "demand",
                    e.target.files ? e.target.files[0] : null
                  );
                  // setOnEdit(true);
                }}
                fromCropUpload={true}
              />
            </div>
            {cropImages &&
              cropImages?.length > 0 &&
              cropImages?.map((image, i) => (
                <div
                  className="shrink-0 overflow-hidden border-2 p-2 rounded relative"
                  style={{
                    width: "20%",
                    height: "8rem",
                  }}
                >
                  <a href={image.url} target="_blank">
                    <DocumentPreview url={image.url} docType={image.docType} />
                  </a>

                  <span
                    className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                    style={{
                      background: "#ff0000de",
                    }}
                    onClick={() => {
                      onRemoveReceiptImage("demand", i);
                    }}
                  >
                    <FaXmark style={{ color: "white" }} />
                  </span>
                </div>
              ))}
            {demandDetails?.imgs &&
              demandDetails?.imgs.length &&
              demandDetails.imgs
                .filter((img) => img.status == 1)
                .map((image, i) => (
                  <div
                    className="shrink-0 overflow-hidden border-2 p-2 rounded relative"
                    style={{
                      width: "20%",
                      height: "8rem",
                    }}
                  >
                    <a href={image.url} target="_blank">
                      <DocumentPreview url={image.url} docType={"image"} />
                    </a>

                    <span
                      className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                      style={{
                        background: "#ff0000de",
                      }}
                      onClick={() => {
                        onRemoveReceiptImage("demand", image.id, true);
                      }}
                    >
                      <FaXmark style={{ color: "white" }} />
                    </span>
                  </div>
                ))}
          </div>
          <span className="text-sm color-sub-text">
            Note: Please submit photos of harvested produce only, not crops on
            the field.
          </span>
        </div>
      </div>
      <div>
        <div className="flex items-center bottom-0 right-0 w-full bg-white py-4 justify-between border-top gap-2">
          <Button
            variant="primaryLight"
            className=""
            text="CANCEL"
            onClick={() => {
              onModalClose(false);
            }}
          />
          <Button
            variant="contained"
            className=""
            onClick={() => {
              submit();
            }}
          >
            SAVE
          </Button>
        </div>
      </div>
    </>
  );
};
export default EditDemandPost;
