import { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useAxiosClient from "../hooks/useAxiosClient";
import { TCrop } from "../types";
import { TRootState } from "../store";
import { setAllCrops } from "../reducers/authSlice";
export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};
const useAllCropsList = () => {
  const dispatch = useDispatch();
  const { handleGetCall } = useAxiosClient();
  const allCrops = useSelector((state: TRootState) => state.auth.allCrops);
  const [crops, setCrops] = useState<TCrop[]>(allCrops);
  useEffect(() => {
    allCrops.length === 0 &&
      handleGetCall<TCrop[]>("/account/common/crops?writerId=:writerID").then(
        ({ data }) => {
          setCrops(data);
          dispatch(setAllCrops(data));
        }
      );
  }, []);
  return { crops };
};
export { useAllCropsList };
