import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import moment from "moment";
import download from "../../assets/images/download.svg";
import { getCurrencyNumberWithSymbol } from "../../helpers/get-currency-number";
import AdvanceDocument from "./advanceDocument";
const PDFGenerator = (detailsData) => {
  console.log(
    detailsData?.srcName,
    detailsData?.detailsData,
    detailsData?.destAddrLine,
    "alltradeDetail"
  );
  const details = detailsData?.detailsData;
  const invoiceData = {
    advanceAmt:
      details?.reqAdv != null
        ? getCurrencyNumberWithSymbol(details?.reqAdv?.requestAdv)
        : 0,
    tradeId: details?.tradeId,
    tradeSeq: details?.traderSeq,
    destName: details?.destName,
    destTradename: details?.destTradeName || "-",
    destAddrLine: details?.destAddrLine || "-",
    srcName: details?.srcName,
    dob: "-",
    date: moment(details?.date).format("DD-MMM-YY"),
    srcAddrLine: details?.srcAddrLine || "-",
    srcTradename: details?.srcTradeName || "-",
    srcMobile: details?.srcMobile,
    destMobile: details?.destMobile,
    timeStamp:
      details?.reqAdv != null
        ? moment(details?.reqAdv?.timeStamp).format("DD-MMM-YY | hh:mm:A")
        : "",
    srcEsign: details?.srcEsign,
  };

  return (
    <div className="py-2 flex justify-end">
      <PDFDownloadLink
        document={<AdvanceDocument invoiceData={invoiceData} />}
        fileName="Terms_Advance.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            "Loading document..."
          ) : (
            <div className="flex flex-col justify-center items-center">
              <img src={download} className="w-8" />
              <p className="text-sm">Download</p>
            </div>
          )
        }
      </PDFDownloadLink>
    </div>
  );
};

export default PDFGenerator;
