import { FC, useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { Popover } from "../../components/ui";
import tradeAccepted from "../../assets/images/accepted_state_active.svg";
import arrivalConfirmedIcon from "../../assets/images/arrival_active.svg";
import pattiIcon from "../../assets/images/patti_active.svg";
import paymentPending from "../../assets/images/pay_pnd_Active.svg";
import paymentCompleted from "../../assets/images/pay_com_active.svg";

const tradeStatusList = [
  { label: "Trade Acepted", value: "ACCEPTED", icon: tradeAccepted },
  {
    label: "Arival Confirmed",
    value: "ARL_CONFIRMED",
    icon: arrivalConfirmedIcon,
  },
  { label: "Patti Finalized", value: "PATTI_FINALIZED", icon: pattiIcon },
  {
    label: "Payment Completed",
    value: "PMT_COMPLETED",
    icon: paymentCompleted,
  },
  { label: "Payment Pending", value: "PMT_PENDING", icon: paymentPending },
];
const TradeStatus: FC<{
  trade_status: string;
  time: { lable: string; time: string; icon: string; icon2: string };
  onChangeTradeStatus: (showModal: boolean, status: string) => void;
  roleType: string;
}> = ({ trade_status, time, onChangeTradeStatus, roleType }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {roleType == "ADMIN" ? (
        <Popover
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          fullWidth={true}
          element={
            <div
              onClick={() => {
                setOpen(true);
              }}
              className="bg-white p-4 rounded-lg border-primary flex items-center gap-4"
              style={
                {
                  // height: "90px",
                }
              }
            >
              <div className="flex items-center relative">
                <img src={time.icon} />
                {time.icon2 && (
                  <img src={time.icon2} className="absolute top-0 -right-1" />
                )}
              </div>
              <div>
                <h1 className="text-base font-bold">{trade_status}</h1>
                <p className="text-sm">
                  {time.lable} {time.time}
                </p>
              </div>
              <FaAngleDown className="ml-auto" />
            </div>
          }
        >
          <div>
            {tradeStatusList.map((tradeStatus) => (
              <div
                className="flex items-center px-3 py-2 gap-4 cursor-pointer hv-primary"
                onClick={() => {
                  onChangeTradeStatus(false, tradeStatus.value);
                  setOpen(false);
                }}
              >
                <img src={tradeStatus.icon} />
                <span className="font-semibold text-sm">
                  {tradeStatus.label}
                </span>
              </div>
            ))}
          </div>
        </Popover>
      ) : (
        <div className="bg-white p-4 rounded-lg border-primary flex items-center gap-4">
          <div className="flex items-center relative">
            <img src={time.icon} />
            {time.icon2 && (
              <img src={time.icon2} className="absolute top-0 -right-1" />
            )}
          </div>
          <div>
            <h1 className="text-base font-bold">{trade_status}</h1>
            <p className="text-sm">
              {time.lable} {time.time}
            </p>
          </div>
        </div>
      )}
    </>
  );
};
export default TradeStatus;
