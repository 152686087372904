import React, { FC, useState } from "react";
import PartnerInfo from "../../components/partner-info";
import { FaChevronRight } from "react-icons/fa6";
import edit from "../../assets/images/edit_round.svg";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/ui/modal";
import ModalHeader from "../../components/ui/modal/modal-header";
import EditSupplyPost from "../supply/edit-supply-post";
import useAllSupplyAndDemands from "../../hooks/useAllSupplyDemands";
import useAllDemands from "../../hooks/useAllDemandDetails";
import moment from "moment";
import NoCropView from "../supply/no-crop-view";
import { useDispatch } from "react-redux";
import { updateProposalCropImgs } from "../../reducers/tradeSlice";
import CropImageGallery from "../supply/crop-image-gallery";
import { Button } from "../../components/ui";
import NodataAvailable from "../../assets/images/NodataAvailable.svg";
import EditDemandPost from "./edit-demand";
import { FaCheckCircle } from "react-icons/fa";
import { toCamelCaseWord } from "../../helpers/getText";
import { getCurrencyNumberWithOutSymbol } from "../../helpers/get-currency-number";
const Demand: FC<{
  searchText: string;
  //  openStatus: boolean;
}> = ({ searchText }) => {
  const items = [];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [supplyPostEdit, setSupplyPostEdit] = useState(false);
  const { allDemands, role, activeTab, setActiveTab } = useAllDemands();
  const [imageGalleryOpen, setImageGalleryOpen] = useState(false);
  const [cropName, setCropname] = useState("");
  const ApplicationTabs = [
    {
      id: 1,
      name: "All",
      to: "all",
    },
    {
      id: 2,
      name: "Pending",
      to: "pending",
    },
    {
      id: 3,
      name: "Assigned",
      to: "assigned",
    },
    {
      id: 4,
      name: "Expired",
      to: "expired",
    },
  ];
  const [postId, setPostId] = useState(0);
  return (
    <>
      <div>
        <div className="pb-4">
          <ul className="flex gap-2" id="myTab" role="tablist">
            {ApplicationTabs.map((tab) => {
              return (
                <li
                  key={tab.id}
                  className={
                    "text-sm br-10 px-4 py-2 font-medium cursor-pointer " +
                    (activeTab == tab.to
                      ? "bg-primary text-white"
                      : "bg-white border color-text-primary")
                  }
                  onClick={() => setActiveTab(tab.to)}
                >
                  {tab.name}
                </li>
              );
            })}
          </ul>
        </div>
        <div
          className="overflow-auto"
          style={{ height: "calc(100vh - 13rem)" }}
        >
          {allDemands.length > 0 ? (
            (searchText !== ""
              ? allDemands.filter((trade) => {
                  return (
                    trade.id.toString().includes(searchText) ||
                    trade.traderId.toString().includes(searchText) ||
                    trade.traderName.toLowerCase().includes(searchText) ||
                    trade.cropName.toLowerCase().includes(searchText)
                  );
                })
              : allDemands
            ).map((demand, i) => (
              <div
                className="border br-10 mb-3 bg-white cursor-pointer"
                onClick={() => {
                  navigate(`/demand-details/${demand.id}`);
                }}
              >
                <div className="px-3 py-3 border-b flex justify-between items-center">
                  <div className="grow-[3] basis-0">
                    <PartnerInfo
                      profilePic={demand?.profilePic}
                      name={demand?.traderName || ""}
                      shortName={""}
                      partnerType={demand?.tradeType}
                      partnerId={demand?.traderId}
                      partnerMobile={demand?.mobile}
                      partyName={demand?.addressLine}
                    />
                  </div>
                  <div className="grow-[3] basis-0">
                    {demand?.status == "ASSIGNED" &&
                      demand?.tradeDtls != null && (
                        <PartnerInfo
                          profilePic={""}
                          name={demand?.tradeDtls?.traderName || ""}
                          shortName={""}
                          partnerType={"Trader"}
                          partnerId={demand?.tradeDtls?.traderId}
                          partnerMobile={demand?.tradeDtls?.mobile}
                          partyName={demand?.tradeDtls?.addrLine}
                        />
                      )}
                  </div>
                  {demand?.status == "ASSIGNED" && (
                    <Button variant="primaryLight" className="">
                      <FaCheckCircle color="#16A02C" />
                      Status : {demand?.status}
                    </Button>
                  )}
                </div>
                <div className="px-3 py-3 ">
                  <div key={""} className="flex cursor-pointer items-center">
                    <span className="grow-[3] basis-0 flex  px-2">
                      <div className="flex items-center gap-x-3">
                        <div>
                          {
                            demand?.imgs && demand.imgs.length > 0 ? (
                              // req.imgs.map((img, i) => (
                              <div
                                className="relative cursor-pointer"
                                onClick={(e) => {
                                  setImageGalleryOpen(true);
                                  dispatch(
                                    updateProposalCropImgs({
                                      proposalCropImages: demand.imgs,
                                    })
                                  );
                                  setCropname(demand?.cropName);
                                  e.stopPropagation();
                                }}
                              >
                                <img
                                  src={demand.imgs[0].url}
                                  alt=""
                                  className="br-10 "
                                  style={{ width: "120px", height: "70px" }}
                                />
                              </div>
                            ) : (
                              <NoCropView />
                            )
                            // ))
                          }
                        </div>
                        <div className="flex flex-col">
                          <span className="text-sm font-bold">
                            {demand?.cropName}
                          </span>
                          <span className="text-xs font-medium">
                            {demand?.quality
                              ? toCamelCaseWord(demand?.quality) + " Quality"
                              : "-"}
                          </span>

                          <span className="text-xs">
                            Post ID :{" "}
                            <span className="color-blue">{demand?.id}</span> |{" "}
                            {demand?.timeAgo}
                          </span>
                        </div>
                      </div>
                    </span>
                    <div className="grow-[2] basis-0 flex flex-col px-2">
                      <span className="text-sm">Quantity</span>
                      <span className="text-sm font-semibold">
                        {" "}
                        {getCurrencyNumberWithOutSymbol(demand?.qty) || "-"}
                        {demand?.unitType}
                      </span>
                    </div>
                    <div className="grow-[2] basis-0 flex flex-col px-2">
                      {" "}
                      <span className="text-sm">Expected Rate</span>
                      <span className="text-sm font-semibold">
                        Rs.80 Per KG
                      </span>
                    </div>
                    <span className="grow-[1.8] basis-0 flex flex-col px-2">
                      {" "}
                      <span className="text-sm">Expected Date</span>
                      <span className="text-sm font-semibold">
                        {moment(demand?.expDate).format("DD-MMM-YYYY")}
                      </span>
                    </span>
                    <span className="grow-[2.2] basis-0 flex flex-col px-2">
                      {" "}
                      <span className="text-sm">Offer Validity </span>
                      <span className="text-sm font-semibold">
                        {" "}
                        {demand?.offerValidity || demand?.validity
                          ? (demand?.validity ? demand?.validity + " | " : "") +
                            moment(demand?.offerValidity).format("DD-MMM-YYYY")
                          : "-"}
                      </span>
                    </span>
                    <div className="grow-[2] basis-0 flex flex-col px-2">
                      {" "}
                      <span className="text-sm">Requests</span>
                      <span className="text-sm font-semibold color-blue">
                        {`${
                          demand?.propsCount
                            ? `(${demand?.propsCount})` + " See All"
                            : "-"
                        }
                          `}
                      </span>
                    </div>

                    <div className="grow-[1.5] basis-0 flex px-2 justify-between items-center">
                      {demand?.status != "ASSIGNED" && (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            setSupplyPostEdit(true);
                            setPostId(demand?.id);
                          }}
                        >
                          <img src={edit} alt="edit" />
                        </span>
                      )}
                      <FaChevronRight />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="flex items-center justify-center py-8 bg-white">
              <img src={NodataAvailable} alt="image" />
            </div>
          )}
        </div>
      </div>
      {supplyPostEdit && (
        <>
          <Modal
            open={supplyPostEdit}
            onClose={() => {
              setSupplyPostEdit(false);
            }}
            width="40vw"
          >
            <>
              <ModalHeader title={"Edit Supply "} content={<></>} />
              <div className="py-3 px-6">
                <EditDemandPost
                  onModalClose={setSupplyPostEdit}
                  type="Demand"
                  fromDemandTab={true}
                  postID={postId}
                />
                {/* <EditSupplyPost
                  onModalClose={setSupplyPostEdit}
                  type="Supply"
                  fromSupplyTab={true}
                  postID={postId}
                /> */}
              </div>
            </>
          </Modal>
        </>
      )}
      {imageGalleryOpen && (
        <>
          <Modal
            open={imageGalleryOpen}
            onClose={() => {
              setImageGalleryOpen(false);
            }}
            width="40vw"
          >
            <>
              <ModalHeader title={cropName} content={<></>} />
              <div className="py-3 px-6">
                <CropImageGallery onModalClose={setImageGalleryOpen} />
              </div>
            </>
          </Modal>
        </>
      )}
    </>
  );
};

export default Demand;
