import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useAxiosClient from "./useAxiosClient";
import { TSupplyDemandReq, TTrader } from "../types/index";
import { TRootState } from "../store";

const useAllDemands = () => {
  const { handleGetCall } = useAxiosClient();
  const { adminId, role } = useSelector((state: TRootState) => ({
    adminId: state.auth.adminId,
    role: state.auth.userInfo?.roleType,
  }));
  const [allDemands, setAllDemands] = useState<TSupplyDemandReq[]>([]);
  const [activeTab, setActiveTab] = useState("all");
  useEffect(() => {
    handleGetCall<TSupplyDemandReq[]>(
      `admin/demand-supply/adminId/${adminId}`
    ).then(({ data }) => {
      if (data) {
        if (activeTab == "pending") {
          const filterArray = data.filter(
            (item) => item?.status?.toUpperCase() == "PENDING"
          );
          setAllDemands(filterArray);
        } else if (activeTab == "assigned") {
          const filterArray = data.filter(
            (item) => item?.status?.toUpperCase() == "ASSIGNED"
          );
          setAllDemands(filterArray);
        } else if (activeTab == "expired") {
          const filterArray = data.filter(
            (item) => item?.status?.toUpperCase() == "EXPIRED"
          );
          setAllDemands(filterArray);
        } else {
          setAllDemands(data);
        }
      } else {
        setAllDemands([]);
      }
    });
  }, [activeTab]);

  return { allDemands, role, activeTab, setActiveTab };
};
export default useAllDemands;
