import { useContext, useState } from "react";
import { deviceInfoContext } from "../../context";
import { Input, Button } from "../../components/ui";
import PageHeader from "../../components/commons/page-header";
import useLogin from "./useLogin";
import Illustration from "../../assets/images/Illustration.svg";
import logo from "../../assets/images/mandi-logo.svg";
const Login = () => {
  const { width } = useContext(deviceInfoContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { onLogin } = useLogin();
  return (
    <>
      <PageHeader showTitle={true} title="Account Login" topVal="0" />
      <div
        className="py-5 px-3 flex justify-center"
        style={{
          marginTop: "40px",
        }}
      >
        <div
          className="border border-t-4 p-4"
          style={{ borderTopColor: "green" }}
        >
          <div className="grow-[2]">
            <Input
              label="Employee Email"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </div>

          <div className="grow-[2]">
            <Input
              label="password"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <Button
            className="mt-6"
            styles={{ width: "20rem" }}
            onClick={() => {
              onLogin(username, password);
            }}
          >
            Login
          </Button>
          <div
            style={{
              fontSize: "10px",
              fontWeight: "inherit",
              marginTop: "10px",
            }}
          >
            <p>By continuing, you agree to Ono’s</p>
            <p style={{ color: "#0000ff" }}>
              Conditions of Use and Privacy Policy
            </p>
          </div>
        </div>

        <div className="fixed left-0" style={{ width: width, bottom: "30vh" }}>
          <img src={logo} className="w-24 ml-auto mr-auto" />
        </div>
      </div>
      <div
        className="fixed bottom-0 left-0 bg-contain w-full"
        style={{
          height: "15rem",
          backgroundImage: `url(${Illustration})`,
          padding: "10px",
        }}
      ></div>
    </>
  );
};
export default Login;
