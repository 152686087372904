import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { FaRegPenToSquare, FaXmark } from "react-icons/fa6";
import {
  RadioButton,
  Select2,
  Input,
  Button,
  FileUploader,
} from "../../components/ui";
import Popover from "../../components/ui/popover";
import Calendar from "../../components/commons/calender";
import DocumentPreViewer from "../../components/commons/document-preview";
import PartnerInfo from "../../components/home/partner";
import useOrderView from "./useOrderView";
import { getCurrencyNumberWithSymbol } from "../../helpers/get-currency-number";
import { TRootState } from "../../store";

const RecordPayment: FC<{
  traderId: number;
  tradeId: number;
  onClose: (paymentDone: boolean) => void;
  recordType?: string;
}> = ({ traderId, tradeId, onClose, recordType }) => {
  const {
    tradeDetail,
    recordPaymentData,
    otherDocuments,
    setRecordPaymentData,
    onUploadReceiptImage,
    submitRecordPayment,
    onRemoveReceiptImage,
    paymentHistoryObj,
    paymentUpdateStatus,
    billCreationStatus,
    role,
  } = useOrderView({
    tradeId: tradeId,
    traderId: traderId,
  });
  const [openCalender, setOpenCalendar] = useState(false);
  const paymentTypeVal = paymentUpdateStatus
    ? paymentHistoryObj?.inOutWard
    : recordType;
  // : recordPaymentData.partyId === tradeDetail?.destPartyId
  // ? "INWARD"
  // : recordPaymentData.partyId === tradeDetail?.srcPartyId
  // ? "OUTWARD"
  // : "";
  const { editAdvPay } = useSelector((state: TRootState) => ({
    editAdvPay: state.trades.editAdvancePayment,
  }));
  console.log(
    paymentTypeVal,
    paymentHistoryObj?.inOutWard,
    recordPaymentData,
    editAdvPay,
    "paymentType",
    recordType
  );

  const onSubmitRecordPayment = () => {
    submitRecordPayment(onClose);
  };
  useEffect(() => {
    setRecordPaymentData({
      ...recordPaymentData,
      // partyId: tradeDetail?.srcPartyId || "",
      paymentTypeVal: recordType || "",
    });
  }, []);
  useEffect(() => {
    setRecordPaymentData({
      ...recordPaymentData,
      partyId:
        paymentTypeVal == "INWARD"
          ? tradeDetail?.destPartyId || ""
          : tradeDetail?.srcPartyId || "",
      paymentTypeVal: paymentTypeVal || "",
    });
  }, [paymentTypeVal]);

  return (
    <>
      <div className="px-4 py-2">
        <div className="flex gap-4">
          <div className="grow-[3]">
            <span className="font-semibold text-sm">
              {paymentTypeVal == "INWARD" ? "Received From " : "Payable To "}
            </span>
            <div
              className="flex border rounded-md items-center gap-2 px-2 py-2"
              // onClick={() => {
              //   setRecordPaymentData({
              //     ...recordPaymentData,
              //     partyId: tradeDetail?.srcPartyId || "",
              //     paymentType: "OUTWARD",
              //   });
              // }}
            >
              <RadioButton
                data={[{ label: "", value: tradeDetail?.srcPartyId || "" }]}
                pointer={{ label: "label", value: "value" }}
                value={recordPaymentData.partyId}
                onChange={(v) => {}}
              />
              <div className="flex">
                {paymentTypeVal == "INWARD" && (
                  <PartnerInfo
                    partyType="From"
                    name={tradeDetail?.destName || ""}
                    tradeName={tradeDetail?.destTradeName + "(Dest)" || ""}
                    profilePic={""}
                  />
                )}
                {paymentTypeVal == "OUTWARD" && (
                  <PartnerInfo
                    partyType="To"
                    name={tradeDetail?.srcName || ""}
                    tradeName={tradeDetail?.srcTradeName + "(Source)" || ""}
                    profilePic={""}
                  />
                )}
              </div>
            </div>
          </div>
          {/* <div className="grow-[3]">
            <span className="font-semibold text-sm">Received From </span>
            <div
              className="flex border rounded-md items-center gap-2 px-2 py-2"
              onClick={() => {
                setRecordPaymentData({
                  ...recordPaymentData,
                  partyId: tradeDetail?.destPartyId || "",
                  paymentType: "INWARD",
                });
              }}
            >
              <RadioButton
                data={[{ label: "", value: tradeDetail?.destPartyId || "" }]}
                pointer={{ label: "label", value: "value" }}
                value={recordPaymentData.partyId}
                onChange={(v) => {}}
              />
              <div className="flex">
                <PartnerInfo
                  partyType="From"
                  name={tradeDetail?.destName || ""}
                  tradeName={tradeDetail?.destTradeName || ""}
                  profilePic={""}
                />
              </div>
            </div>
          </div> */}
        </div>
        <div className="flex gap-4 mt-4">
          <div className="relative grow-[3]">
            <Popover
              open={openCalender}
              element={
                <div>
                  <Input
                    label="Select Date"
                    onFocus={() => {
                      setOpenCalendar(true);
                    }}
                    value={moment(recordPaymentData.paymentDate).format(
                      "DD-MM-YYYY"
                    )}
                  />
                </div>
              }
              onClose={() => {
                setOpenCalendar(false);
              }}
            >
              <Calendar
                mode="single"
                initialDateRange={{}}
                onChange={({ date }: { date: string }) => {
                  setOpenCalendar(false);
                  setRecordPaymentData({
                    ...recordPaymentData,
                    paymentDate: date,
                  });
                }}
                currentDate={recordPaymentData.paymentDate}
                maxDate={moment().format("YYYY-MM-DD")}
                style={{ width: "18rem" }}
              />
            </Popover>
          </div>
          <div className="grow-[3] relative">
            {paymentTypeVal !== "" && tradeDetail && (
              <>
                <Input
                  type="number"
                  label={
                    paymentTypeVal === "OUTWARD"
                      ? "Final Payable "
                      : "Final Receivable "
                  }
                  value={
                    paymentTypeVal === "INWARD"
                      ? Number(tradeDetail?.totalPayable) +
                        Number(tradeDetail?.destPf) -
                        Number(tradeDetail?.destDis)
                      : Number(tradeDetail?.totalReceivable) -
                        Number(tradeDetail?.srcPf) +
                        Number(tradeDetail?.srcDis)
                  }
                />
                <FaRegPenToSquare className="absolute top-0 -right-0" />
              </>
            )}
          </div>
        </div>
        <div className="mt-4">
          {recordPaymentData?.paymentType != "" &&
          recordPaymentData?.paymentType == "ADVANCE" &&
          paymentTypeVal === "OUTWARD" &&
          tradeDetail?.reqAdv != null ? (
            <Input
              type="number"
              label={"Amount Paid"}
              value={recordPaymentData.requestAdv}
              onChange={(e) =>
                setRecordPaymentData({
                  ...recordPaymentData,
                  requestAdv: parseFloat(e.target.value),
                  paidAmount: parseFloat(e.target.value),
                })
              }
            />
          ) : (
            <Input
              type="number"
              label={
                paymentTypeVal === "OUTWARD" ? "Amount Paid" : "Amount Received"
              }
              value={recordPaymentData.paidAmount}
              onChange={(e) =>
                setRecordPaymentData({
                  ...recordPaymentData,
                  paidAmount: parseFloat(e.target.value),
                })
              }
            />
          )}
        </div>
        {paymentTypeVal !== "" && (
          <div className="flex gap-4 mt-3">
            <div className="grow-[3]">
              <div className="font-semibold text-sm">
                {paymentTypeVal === "OUTWARD"
                  ? "Paid Till date"
                  : "Received Till date"}
              </div>
              <div className="text-sm font-medium">
                {paymentTypeVal == "OUTWARD" &&
                  getCurrencyNumberWithSymbol(tradeDetail?.outwardPmt, true)}
                {paymentTypeVal == "INWARD" &&
                  getCurrencyNumberWithSymbol(tradeDetail?.inwardPmt, true)}
              </div>
            </div>
            <div className="grow-[3]">
              <div className="font-semibold text-sm">
                {paymentTypeVal === "OUTWARD"
                  ? "Balance Payable"
                  : "Balance Receivable"}
              </div>
              <div className="text-sm font-medium">
                {paymentTypeVal === "INWARD"
                  ? getCurrencyNumberWithSymbol(
                      (Number(tradeDetail?.totalPayable) +
                        Number(tradeDetail?.destPf) -
                        Number(tradeDetail?.destDis) || 0) -
                        (tradeDetail?.inwardPmt || 0),
                      true
                    )
                  : getCurrencyNumberWithSymbol(
                      (Number(tradeDetail?.totalReceivable) -
                        Number(tradeDetail?.srcPf) +
                        Number(tradeDetail?.srcDis) || 0) -
                        (tradeDetail?.outwardPmt || 0),
                      true
                    )}
              </div>
            </div>
          </div>
        )}
        <div className="relative flex mt-3 gap-4">
          {paymentTypeVal == "OUTWARD" && (
            <Select2
              value={recordPaymentData.paymentType}
              lable="Payment Type "
              options={[
                {
                  label: "Transportation",
                  value: "TRANSPORTATION",
                },
                {
                  label: "Advance",
                  value: "ADVANCE",
                  disabled:
                    tradeDetail?.advanceRcvd != 0
                      ? editAdvPay
                        ? false
                        : true
                      : false,
                },
                {
                  label: "Partial Payment",
                  value: "INSTALLMENT",
                  // disabled:
                  //   tradeDetail?.advanceRcvd != 0
                  //     ? editAdvPay
                  //       ? true
                  //       : false
                  //     : false,
                },
                {
                  label: "Full Payment",
                  value: "FINAL_PAYMENT",
                },
              ]}
              starMark={true}
              onChange={(selectedPaymentType) => {
                setRecordPaymentData({
                  ...recordPaymentData,
                  paymentType:
                    selectedPaymentType.value === "TRANSPORTATION"
                      ? "TRANSPORTATION"
                      : selectedPaymentType.value === "ADVANCE"
                      ? "ADVANCE"
                      : selectedPaymentType.value === "INSTALLMENT" ||
                        selectedPaymentType.value === "PARTIAL PAYMENT"
                      ? "INSTALLMENT"
                      : selectedPaymentType.value === "FINAL_PAYMENT"
                      ? "FINAL_PAYMENT"
                      : "",
                });
              }}
              classNames="w-1/2"
            />
          )}
          {paymentTypeVal == "INWARD" && (
            <Select2
              value={recordPaymentData.paymentType}
              lable="Payment Type "
              options={[
                {
                  label: "Transportation",
                  value: "TRANSPORTATION",
                },
                {
                  label: "Advance",
                  value: "ADVANCE",
                },
                {
                  label: "Partial Payment",
                  value: "INSTALLMENT",
                },
                {
                  label: "Full Payment",
                  value: "FINAL_PAYMENT",
                },
              ]}
              onChange={(selectedPaymentType) => {
                setRecordPaymentData({
                  ...recordPaymentData,
                  paymentType:
                    selectedPaymentType.value === "TRANSPORTATION"
                      ? "TRANSPORTATION"
                      : selectedPaymentType.value === "ADVANCE"
                      ? "ADVANCE"
                      : selectedPaymentType.value === "INSTALLMENT" ||
                        selectedPaymentType.value === "PARTIAL PAYMENT"
                      ? "INSTALLMENT"
                      : selectedPaymentType.value === "FINAL_PAYMENT"
                      ? "FINAL_PAYMENT"
                      : "",
                });
              }}
              classNames="w-1/2"
            />
          )}
          <Select2
            value={recordPaymentData.paymentMode}
            lable="Payment Mode "
            options={[
              {
                label: "IMPS",
                value: "IMPS",
              },
              {
                label: "NEFT",
                value: "NEFT",
              },
              {
                label: "UPI",
                value: "UPI",
              },
              {
                label: "RTGS",
                value: "RTGS",
              },
              {
                label: "CASH",
                value: "CASH",
              },
              {
                label: "CHEQUE",
                value: "CHEQUE",
              },
            ]}
            onChange={(selectedPaymentMode) => {
              setRecordPaymentData({
                ...recordPaymentData,
                paymentMode: selectedPaymentMode.value,
              });
            }}
            classNames="w-1/2"
          />
        </div>
        <div className="" style={{ paddingTop: "40px" }}>
          <div>
            <Input
              type="text"
              label={"Comments"}
              value={recordPaymentData.comments}
              onChange={(e) =>
                setRecordPaymentData({
                  ...recordPaymentData,
                  comments: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>

      <div className="px-2 mt-3">
        <span className="px-2 text-sm font-semibold">Upload Document</span>
        <div className="flex flex-row overflow-auto w-full hide-scroll-bar">
          <div className={`shrink-0 px-2`} style={{ width: "15rem" }}>
            <FileUploader
              onChange={(e) => {
                e.target.files &&
                  e.target.files?.length > 0 &&
                  onUploadReceiptImage(e.target.files[0]);
              }}
            />
          </div>
          {otherDocuments.map((file, i) => (
            // <div
            //   key={file.url}
            //   className={`shrink-0 px-2`}
            //   style={{ width: "15rem", height: "10rem"}}
            // >
            <div
              className="p-2 shrink-0 overflow-hidden rounded relative"
              style={{
                width: "33.33%",
                height: "8rem",
              }}
              key={file.url}
            >
              <a href={file.url} target="_blank">
                <DocumentPreViewer docType={file.docType} url={file.url} />
              </a>
              <span
                className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                style={{ background: "#ff0000de" }}
                onClick={() => {
                  onRemoveReceiptImage(i, false);
                }}
              >
                <FaXmark style={{ color: "white" }} />
              </span>
            </div>
          ))}
          {/* {paymentHistoryObj?.images.length} */}
          {paymentHistoryObj?.images.length != 0 &&
            paymentHistoryObj?.images
              .filter((img) => img.status == 1)
              .map((file, i) => (
                // <div
                //   key={file.url}
                //   className={`shrink-0 px-2`}
                //   style={{ width: "15rem" }}
                // >
                <div
                  className="p-2 shrink-0 overflow-hidden rounded relative"
                  style={{
                    width: "33.33%",
                    height: "8rem",
                  }}
                  key={file.url}
                >
                  <a href={file.url} target="_blank">
                    <DocumentPreViewer docType={file.docType} url={file.url} />
                  </a>
                  <span
                    className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                    style={{ background: "#ff0000de" }}
                    onClick={() => {
                      onRemoveReceiptImage(i, true, "payment", file.id);
                    }}
                  >
                    <FaXmark style={{ color: "white" }} />
                  </span>
                </div>
              ))}
        </div>
      </div>
      {tradeDetail?.tradeStatus !== "CANCEL" &&
        tradeDetail?.tradeStatus !== "CLOSED" && (
          <div className="bg-white flex justify-between mt-4 w-full px-5 pb-2 absolute bottom-3">
            <Button
              variant="outlined"
              onClick={() => {
                onClose(false);
              }}
            >
              CANCEL
            </Button>
            <Button
              onClick={onSubmitRecordPayment}
              disabled={billCreationStatus.status === "LOADING" ? true : false}
            >
              SUBMIT
            </Button>
          </div>
        )}
    </>
  );
};
export default RecordPayment;
