import { FC, useContext, useEffect } from "react";
import useArrivalsView from "./useArrivalsView";
import moment from "moment";
import { useSelector } from "react-redux";
import { TRootState } from "../../store";
import { getCurrencyNumberWithSymbol } from "../../helpers/get-currency-number";
import PDFGenerator from "./pdfAdvance";
import logo_op from "../../assets/images/logo_op.png";
const TermsAdvance: FC<{}> = ({}) => {
  //   const { deviceType, right } = useContext(deviceInfoContext);
  //   const { reqAdvInfo } = useArrivalsView("Adv", false);
  const { tradeDetails } = useSelector((state: TRootState) => ({
    tradeDetails: state.trades.tradeDetails,
  }));
  //   const { getTradeDetail } = useOrderView();
  //   useEffect(() => {
  //     if (tradeDetails === null) {
  //       getTradeDetail();
  //     }
  //   }, []);
  const alltradeDetail = tradeDetails;
  return (
    <div>
      <>
        <div
          className="py-3 px-6 color-text-primary text-sm terms_mobile"
          style={{ overflowY: "auto", height: "calc(100vh - 12rem)" }}
        >
          <div>
            <p className="mb-4">
              The undersigned Source Partner has, by way of submitting the Trade
              Advance Request Form{" "}
              <span className="font-semibold">(“TARF”)</span>, requested ONO ARK
              INDIA PRIVATE LIMITED{" "}
              <span className="font-semibold">(“ONO PLATFORM”)</span> to
              facilitate the trade and provide trade advance amount of
              {getCurrencyNumberWithSymbol(
                alltradeDetail?.reqAdv?.requestAdv ||
                  alltradeDetail?.reqAdv?.requestAdv
              ) || 0}{" "}
              against Trade ID: {alltradeDetail?.traderSeq}. It shall be
              governed by below mentioned terms and conditions{" "}
              <span className="font-semibold">(“Agreement”)</span>:
            </p>
            <ul className="main_list mb-4">
              <li>
                <p className="mb-1 font-semibold">
                  1.Source Partner acknowledges, understands and agrees that:
                </p>
                <ul className="sub_list">
                  <li>
                    The trade advance amount made at the time of dispatch or
                    before dispatch shall remain the responsibility of the
                    source partner until such time as the arrival of the goods
                    is confirmed and accepted by the destination partner.
                  </li>
                  <li>
                    {" "}
                    Trade advance is not an obligation by the platform (ONO) and
                    ONO has the right to reject any Trade Advance request at its
                    discretion.
                  </li>
                  <li>
                    {" "}
                    Source Partner agreed to strictly comply with Destination
                    Partner requirements of commodity quality and variety
                    specifications.{" "}
                  </li>
                  <li>
                    Source Partner shall furnish accurate, correct and reliable
                    photographs of commodities and information about trade
                    details of quantities, varieties, separation marks and
                    dispatch details.{" "}
                  </li>
                  <li>
                    <span className="font-semibold">Good Faith and Trust:</span>{" "}
                    The Source Partner is obligated to act with utmost good
                    faith and trust in all matters pertaining to this Trade
                    Agreement.
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="main_list mb-4">
              <li>
                <p className="mb-1 font-semibold">
                  2.Prohibition on Redirecting / Not Dispatching the Accepted
                  Trade Vehicles:
                </p>
                <ul className="sub_list">
                  <li>
                    Under no circumstances shall accepted trade vehicles be
                    redirected or not dispatched on-time without the formal
                    consent and approval of ONO, as such actions compromise the
                    trust established with destination partners.
                  </li>
                  <li>
                    If the Source Partner failed to dispatch the vehicle on-time
                    (or) diverted the vehicle mid-way, is a breach of this
                    agreement and as such actions compromise the trust
                    established with the Destination Party. In addition, Source
                    Partner is liable to payback Trade Advance taken against
                    this trade immediately to ONO with penalties.
                  </li>
                  <li>
                    The source partner shall not be entitled to cancel / divert
                    the confirmed trade upon successfully received Trade Advance
                    through the Platform. If, for any reason, the Source partner
                    cancels / diverts the Trade after it has been confirmed and
                    Trade Advance availed, ONO shall have a right to charge the
                    Source partner a cancellation fee. In case of cancellation /
                    diverted for any such reasons wherein the fees have been
                    paid by ONO to the Source partner for such canceled Trades,
                    the Source partner shall refund the fees received by it to
                    ONO within 2 (Two) working days after which such fees shall
                    further be refunded to the destination partner after
                    deducting charges attributable to ONO and the cancellation
                    fee, as may be deemed appropriate by ONO at its sole
                    discretion. Additionally a 2% of the canceled trade costs
                    will be paid which shall be transferred to the destination
                    partner.
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="main_list mb-4">
              <li>
                <p className="mb-1 font-semibold">
                  3.ONO shall not be held liable for:
                </p>
                <ul className="sub_list">
                  <li>Any inventory or commodity risks that may arise.</li>
                  <li>Any and all risks associated with transportation.</li>
                  <li>
                    Any rejections of orders, vehicles, or commodities based on
                    quality issues that may occur during the course of the
                    transaction or trade.
                  </li>
                  <li>
                    Any unlawful images or data that may inadvertently appear on
                    the platform despite its efforts to ensure that all data and
                    images published on the platform comply with applicable
                    laws. ONO assumes no responsibility for any information or
                    images requested by a partner for publication on the
                    platform that do not conform to legal requirements.
                  </li>
                </ul>
              </li>
            </ul>
            <p className="mb-4">
              I hereby read and accepted and agreed to the terms and conditions
              set forth above and applied for trade advance.{" "}
            </p>
            <div className="mb-4 flex flex-col">
              <p>
                Trade Id:{" "}
                <span className="font-semibold">
                  {alltradeDetail?.traderSeq || "-"}
                </span>{" "}
              </p>
              <p>
                Destination Party:{" "}
                <span className="font-semibold">
                  {alltradeDetail?.destName || "-"}
                </span>
              </p>
              <p>
                Destination Location:{" "}
                <span className="font-semibold">
                  {alltradeDetail?.destAddrLine || "-"}
                </span>
              </p>
              <p>
                Dispatch Date:{" "}
                <span className="font-semibold">
                  {moment(alltradeDetail?.date).format("DD-MMM-YYYY") || "-"}
                </span>
              </p>
              <p>
                {" "}
                Advance Amount:{" "}
                <span className="font-semibold">
                  {getCurrencyNumberWithSymbol(
                    alltradeDetail?.reqAdv?.requestAdv
                  ) || "-"}
                </span>
              </p>
            </div>
            <div className="mb-4 flex flex-col">
              <p>
                Source Partner Name:{" "}
                <span className="font-semibold">
                  {alltradeDetail?.srcName || "-"}
                </span>
              </p>
              <p>
                DOB: <span className="font-semibold">{"-"}</span>
              </p>
              <p>
                Location:{" "}
                <span className="font-semibold">
                  {alltradeDetail?.srcAddrLine || "-"}
                </span>
              </p>
            </div>
            <div className="py-4 flex border-t border-b flex-col">
              <p className="font-semibold">
                ट्रेड एडवांस नियम एवं शर्तें (एग्रीमेंट)
              </p>
            </div>
            <p className="mb-4 mt-3">
              नीचे हस्ताक्षरकर्ता स्रोत भागीदार ने ट्रेड एडवांस अनुरोध फॉर्म
              <span className="font-semibold">(“TARF”)</span> प्रस्तुत करके, ONO
              ARK INDIA PRIVATE LIMITED{" "}
              <span className="font-semibold">(“ONO PLATFORM”)</span> से व्यापार
              को सुविधाजनक बनाने और व्यापार ID: {alltradeDetail?.traderSeq} के
              लिए{" "}
              {getCurrencyNumberWithSymbol(
                alltradeDetail?.reqAdv?.requestAdv
              ) || 0}{" "}
              की व्यापार अग्रिम राशि प्रदान करने का अनुरोध किया है। यह नीचे
              उल्लिखित नियमों और शर्तों
              <span className="font-semibold">(“एग्रीमेंट”)</span>द्वारा शासित
              होगा::
            </p>
            <ul className="main_list mb-4">
              <li>
                <p className="mb-1 font-semibold">
                  1. स्रोत भागीदार स्वीकार करता है, समझता है और सहमत है कि:
                </p>
                <ul className="sub_list">
                  <li>
                    प्रेषण के समय या प्रेषण से पहले की गई व्यापार अग्रिम राशि तब
                    तक स्रोत भागीदार की जिम्मेदारी रहेगी जब तक कि माल के आगमन की
                    पुष्टि नहीं हो जाती और गंतव्य भागीदार द्वारा स्वीकार नहीं कर
                    लिया जाता।
                  </li>
                  <li>
                    व्यापार अग्रिम मंच (ONO) या ONO द्वारा कोई दायित्व नहीं है,
                    अपने विवेक पर किसी भी व्यापार अग्रिम अनुरोध को अस्वीकार करने
                    का अधिकार रखता है।
                  </li>
                  <li>
                    {" "}
                    स्रोत साझेदार ने गंतव्य साझेदार की वस्तु गुणवत्ता और किस्म
                    विनिर्देशों की आवश्यकताओं का कड़ाई से अनुपालन करने पर सहमति
                    व्यक्त की।
                  </li>
                  <li>
                    स्रोत साझेदार को वस्तुओं की सटीक, सही और विश्वसनीय तस्वीरें
                    तथा मात्रा, किस्म, पृथक्करण चिह्न और प्रेषण विवरण के व्यापार
                    विवरण के बारे में जानकारी प्रस्तुत करनी होगी।
                  </li>
                  <li>
                    सद्भावना और भरोसा: स्रोत भागीदार इस व्यापार समझौते से
                    संबंधित सभी मामलों में अत्यंत सद्भावना और भरोसे के साथ कार्य
                    करने के लिए बाध्य है।
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="main_list mb-4">
              <li>
                <p className="mb-1 font-semibold">
                  2. स्वीकृत व्यापार वाहनों को पुनर्निर्देशित करने / न भेजने पर
                  प्रतिबंध:
                </p>
                <ul className="sub_list">
                  <li>
                    किसी भी परिस्थिति में स्वीकृत व्यापार वाहनों को ONO की
                    औपचारिक सहमति और अनुमोदन के बिना समय पर पुनर्निर्देशित या
                    नहीं भेजा जाएगा, क्योंकि ऐसी कार्रवाइयां गंतव्य भागीदारों के
                    साथ स्थापित विश्वास से समझौता करती हैं।
                  </li>
                  <li>
                    यदि स्रोत भागीदार समय पर वाहन भेजने में विफल रहता है (या)
                    वाहन को बीच रास्ते में ही मोड़ देता है, तो यह इस समझौते का
                    उल्लंघन है और ऐसी कार्रवाइयों से गंतव्य पक्ष के साथ स्थापित
                    विश्वास पर प्रतिकूल प्रभाव पड़ता है। इसके अलावा, स्रोत
                    भागीदार इस व्यापार के लिए लिया गया व्यापार अग्रिम दंड के साथ
                    तुरंत ONO को वापस करने के लिए उत्तरदायी है।
                  </li>
                  <li>
                    स्रोत भागीदार प्लेटफ़ॉर्म के माध्यम से ट्रेड एडवांस की सफल
                    प्राप्ति के बाद पुष्टि किए गए व्यापार को रद्द या डायवर्ट
                    करने का हकदार नहीं होगा। यदि, किसी भी कारण से, स्रोत भागीदार
                    पुष्टि किए जाने और ट्रेड एडवांस का लाभ उठाए जाने के बाद
                    व्यापार को रद्द या डायवर्ट करता है, तो ONO को स्रोत भागीदार
                    से रद्दीकरण शुल्क वसूलने का अधिकार होगा। ऐसे किसी भी कारण से
                    रद्दीकरण/डायवर्ट किए जाने की स्थिति में, जिसमें ऐसे रद्द किए
                    गए व्यापार के लिए ONO द्वारा स्रोत भागीदार को शुल्क का
                    भुगतान किया गया है, सोर्स पार्टनर 2 (दो) कार्य दिवसों के
                    भीतर ONO को प्राप्त शुल्क वापस करेगा, जिसके बाद ONO द्वारा
                    अपने विवेकानुसार उचित समझे जाने वाले शुल्क और रद्दीकरण शुल्क
                    को घटाकर गंतव्य पार्टनर को वापस कर दिया जाएगा। इसके
                    अतिरिक्त, रद्द किए गए व्यापार लागत का 2% भुगतान किया जाएगा,
                    जिसे गंतव्य पार्टनर को हस्तांतरित किया जाएगा।
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="main_list mb-4">
              <li>
                <p className="mb-1 font-semibold">
                  3. ONO को इसके लिए उत्तरदायी नहीं ठहराया जाएगा:
                </p>
                <ul className="sub_list">
                  <li>
                    कोई भी इन्वेंट्री या कमोडिटी जोखिम जो उत्पन्न हो सकता है।
                  </li>
                  <li>परिवहन से जुड़े सभी जोखिम।</li>
                  <li>
                    गुणवत्ता संबंधी मुद्दों के आधार पर ऑर्डर, वाहन या कमोडिटी की
                    अस्वीकृति जो लेन-देन या व्यापार के दौरान हो सकती है।
                  </li>
                  <li>
                    कोई भी गैरकानूनी छवि या डेटा जो अनजाने में प्लेटफ़ॉर्म पर
                    दिखाई दे सकता है, भले ही यह सुनिश्चित करने का प्रयास किया
                    गया हो कि प्लेटफ़ॉर्म पर प्रकाशित सभी डेटा और छवियां लागू
                    कानूनों का अनुपालन करती हैं। ONO किसी भागीदार द्वारा
                    प्लेटफ़ॉर्म पर प्रकाशन के लिए अनुरोध की गई किसी भी जानकारी
                    या छवियों के लिए कोई ज़िम्मेदारी नहीं लेता है जो कानूनी
                    आवश्यकताओं के अनुरूप नहीं हैं।
                  </li>
                </ul>
              </li>
            </ul>
            <p className="mb-4">
              मैं ऊपर दिए गए नियमों और शर्तों को पढ़ता हूँ, स्वीकार करता हूँ और
              उनसे सहमत हूँ तथा व्यापार अग्रिम के लिए आवेदन करता हूँ।
            </p>
            <div className="mb-4 flex flex-col">
              <p>
                व्यापार आईडी:{" "}
                <span className="font-semibold">
                  {alltradeDetail?.traderSeq || "-"}
                </span>{" "}
              </p>
              <p>
                गंतव्य भागीदार:{" "}
                <span className="font-semibold">
                  {alltradeDetail?.destName || "-"}
                </span>
              </p>
              <p>
                गंतव्य स्थान:{" "}
                <span className="font-semibold">
                  {alltradeDetail?.destAddrLine || "-"}
                </span>
              </p>
              <p>
                प्रेषण तिथि:{" "}
                <span className="font-semibold">
                  {moment(alltradeDetail?.date).format("DD-MMM-YYYY") || "-"}
                </span>
              </p>
              <p>
                {" "}
                अग्रिम राशि:{" "}
                <span className="font-semibold">
                  {getCurrencyNumberWithSymbol(
                    tradeDetails?.reqAdv?.requestAdv ||
                      alltradeDetail?.reqAdv?.requestAdv
                  ) || "-"}
                </span>
              </p>
            </div>
            <div className="mb-4 flex flex-col">
              <p>
                स्रोत भागीदार:{" "}
                <span className="font-semibold">
                  {alltradeDetail?.srcName || "-"}
                </span>
              </p>
              <p>
                जन्म तिथि: <span className="font-semibold">{"-"}</span>
              </p>
              <p>
                स्थान:{" "}
                <span className="font-semibold">
                  {alltradeDetail?.srcAddrLine || "-"}
                </span>
              </p>
            </div>
            <div className="mb-4 flex border-t flex-col ">
              <div className="mb-2 pt-2">
                <p>Yours faithfully, </p>
                <p className="font-semibold">
                  For {alltradeDetail?.srcTradeName}
                </p>
              </div>
              <div className="mb-2 relative">
                <p>
                  eSigned using OTP (ono.ag - {alltradeDetail?.srcEsign})
                  <br></br>
                  {alltradeDetail?.srcName} / {alltradeDetail?.srcMobile}
                  <br></br>
                  {moment(alltradeDetail?.reqAdv?.timeStamp).format(
                    "DD-MMM-YYYY | hh:mm:A"
                  )}
                </p>
                <img src={logo_op} className="absolute top-0" />
              </div>
              <div className="mb-2">
                <p> {alltradeDetail?.srcName}</p>
                <p className="font-semibold">{alltradeDetail?.srcMobile}</p>
              </div>
            </div>
          </div>
        </div>
        {alltradeDetail != null && (
          <div
            className="bg-white absolute bottom-0 right-0 w-full border-t"
            data-element="order-view-footer"
          >
            <div className="flex justify-end">
              <span className="flex flex-col px-4 py-2 items-center cursor-pointer">
                <div>
                  <PDFGenerator detailsData={alltradeDetail} />
                </div>
              </span>
            </div>
          </div>
        )}
      </>
    </div>
  );
};
export default TermsAdvance;
