import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import "../../fonts"; // Ensure the fonts are registered
import watermark from "../../assets/images/watermark.png"; // Make sure this path is correct
import ono_logo from "../../assets/images/ono_logo_pdf.png";
import logo_op from "../../assets/images/logo_op.png";
const styles = StyleSheet.create({
  page: {
    paddingTop: 110, // Adjust to leave space for the header
    paddingBottom: 190, // Adjust to leave space for the footer
    paddingHorizontal: 40,
    fontFamily: "Roboto",
  },
  header: {
    fontSize: 11,
    color: "#000000",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: "10px solid #16A02C",
    paddingBottom: 10,
    paddingLeft: 40,
    paddingRight: 40,
    // marginBottom: 70,
    paddingTop: 20,
    borderBottom: "1px solid black",
  },
  logo: {
    width: 100,
  },
  header_head_main: {
    fontSize: 14,
    textAlign: "center",
    marginLeft: "15%",
    marginRight: "auto",
  },
  header_head: {
    fontSize: 14,
    textAlign: "center",
  },
  footer: {
    fontSize: 12,
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "left",
    paddingLeft: 40,
    paddingRight: 40,
    borderTop: "1px solid black",
    paddingTop: 20,
    // color: "grey",
  },
  watermark: {
    position: "absolute",
    top: "30%",
    left: "15%",
    width: 300,
    height: 300,
    // opacity: 0.3, // Make the watermark semi-transparent
    // transform: "rotate(-45deg)",
    zIndex: -1, // Ensure it's behind the content
  },
  section: {
    marginBottom: 20,
    fontSize: 11,
    paddingTop: 20,
    lineHeight: 1.5,
  },
  table: {
    display: "table",
    width: "auto",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "20%",
    padding: 5,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
  },
  tableCell: {
    fontSize: 10,
  },
  para: {
    lineHeight: 1.5,
  },
  subHeading: {
    fontSize: 12,
    fontWeight: "bold",
    marginVertical: 5,
  },
  paragraph: {
    marginBottom: 10,
  },
  list: {
    marginLeft: 10,
    marginBottom: 10,
  },
  listItem: {
    marginBottom: 5,
    marginLeft: 10,
    display: "flex",
    flexDirection: "row",
  },
  listItemLeft: {
    justifyContent: "flex-end",
    textAlign: "right",
  },
  footerPara: {
    fontSize: 11,
    color: "#121212",
  },
  footerParaSm: {
    fontSize: 10,
    color: "#000000",
  },
  bold: {
    fontWeight: "bold",
  },
  space: {
    paddingBottom: 10,
  },
  pageno: {
    paddingTop: 10,
    textAlign: "center",
  },
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  hindiDiv: {
    // borderTop: "1px solid black",
    // padding: 20,
    paddingBottom: 10,
    paddingTop: 10,
    fontFamily: "NotoSansDevanagari",
  },
  mainpara: {
    paddingBottom: 10,
  },
  relative: {
    position: "relative",
  },
  logo_op: {
    position: "absolute",
    top: "18%",
    left: "0%",
    width: 100,
    height: 50,
  },
});

// Watermark component to be used on each page
const Watermark = () => (
  <View fixed style={styles.watermark}>
    <Image src={watermark} style={styles.watermark} />
  </View>
);

const AdvanceDocument = ({ invoiceData }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Watermark />
      {/* Header */}
      <View fixed style={styles.header}>
        <Image src={ono_logo} style={styles.logo} />
        <Text style={styles.header_head_main}>Trade Advance Agreement</Text>
        {/* <View>
          <Text>Invoice No : 2024001</Text>
          <Text>Invoice Date : 28/08/2024</Text>
          <Text>Billing ID : 1233-434-23</Text>
        </View> */}
      </View>
      {/* Invoice Details */}
      {/* <View style={styles.section}>
        <Text>Invoice பொருள்: {invoiceData.invoiceDate}</Text>
        <Text style={{ fontFamily: "Roboto" }}>
          Invoice Number: {invoiceData.invoiceNumber}
        </Text>
      </View> */}
      {/* Table */}
      <View style={styles.section}>
        <View>
          <View>
            <Text style={[styles.para, styles.mainpara]}>
              The undersigned Source Partner has, by way of submitting the Trade
              Advance Request Form <Text style={styles.bold}>(“TARF”)</Text>,
              requested ONO ARK INDIA PRIVATE LIMITED{" "}
              <Text>(“ONO PLATFORM”)</Text> to facilitate the trade and provide
              trade advance amount of {invoiceData.advanceAmt} against Trade ID:
              {invoiceData.tradeSeq}. It shall be governed by below mentioned
              terms and conditions <Text>(“Agreement”)</Text>:
            </Text>
            <Text style={styles.subHeading}>
              1. The Source Partner acknowledges, understands, and agrees that:
            </Text>
            <View style={styles.list}>
              <Text style={styles.listItem}>
                a. The trade advance amount made at the time of dispatch or
                before dispatch shall remain the responsibility of the source
                partner until such time as the arrival of the goods is confirmed
                and accepted by the destination partner.
              </Text>
              <Text style={styles.listItem}>
                b. Trade advance is not an obligation by the platform (ONO) and
                ONO has the right to reject any Trade Advance request at its
                discretion.
              </Text>
              <Text style={styles.listItem}>
                c. Source Partner agreed to strictly comply with Destination
                Partner requirements of commodity quality and variety
                specifications.
              </Text>
              <Text style={styles.listItem}>
                d. Source Partner shall furnish accurate, correct and reliable
                photographs of commodities and information about trade details
                of quantities, varieties, separation marks and dispatch details.
              </Text>
              <Text style={styles.listItem}>
                e. Good Faith and Trust: The Source Partner is obligated to act
                with utmost good faith and trust in all matters pertaining to
                this Trade Agreement.
              </Text>
            </View>
            <Text style={styles.subHeading}>
              2. Prohibition on Redirecting / Not Dispatching the Accepted Trade
              Vehicles:
            </Text>
            <View style={styles.list}>
              <Text style={styles.listItem}>
                a. Under no circumstances shall accepted trade vehicles be
                redirected or not dispatched on-time without the formal consent
                and approval of ONO, as such actions compromise the trust
                established with destination partners.
              </Text>
              <Text style={styles.listItem}>
                b. If the Source Partner failed to dispatch the vehicle on-time
                (or) diverted the vehicle mid-way, is a breach of this agreement
                and as such actions compromise the trust established with the
                Destination Party. In addition, Source Partner is liable to
                payback Trade Advance taken against this trade immediately to
                ONO with penalties.
              </Text>
              <Text style={styles.listItem}>
                c. The source partner shall not be entitled to cancel / divert
                the confirmed trade upon successfully received Trade Advance
                through the Platform. If, for any reason, the Source partner
                cancels / diverts the Trade after it has been confirmed and
                Trade Advance availed, ONO shall have a right to charge the
                Source partner a cancellation fee. In case of cancellation /
                diverted for any such reasons wherein the fees have been paid by
                ONO to the Source partner for such canceled Trades, the Source
                partner shall refund the fees received by it to ONO within 2
                (Two) working days after which such fees shall further be
                refunded to the destination partner after deducting charges
                attributable to ONO and the cancellation fee, as may be deemed
                appropriate by ONO at its sole discretion. Additionally a 2% of
                the canceled trade costs will be paid which shall be transferred
                to the destination partner.
              </Text>
            </View>
          </View>
          <View style={styles.space}>
            <Text style={styles.subHeading}>
              3.ONO shall not be held liable for:
            </Text>
            <View style={styles.list}>
              <Text style={styles.listItem}>
                a. Any inventory or commodity risks that may arise.
              </Text>
              <Text style={styles.listItem}>
                b. Any and all risks associated with transportation.
              </Text>
              <Text style={styles.listItem}>
                c. Any rejections of orders, vehicles, or commodities based on
                quality issues that may occur during the course of the
                transaction or trade.
              </Text>
              <Text style={styles.listItem}>
                d. Any unlawful images or data that may inadvertently appear on
                the platform despite its efforts to ensure that all data and
                images published on the platform comply with applicable laws.
                ONO assumes no responsibility for any information or images
                requested by a partner for publication on the platform that do
                not conform to legal requirements.
              </Text>
            </View>
            <Text style={[styles.para, styles.space]}>
              I hereby read and accepted and agreed to the terms and conditions
              set forth above and applied for trade advance.
            </Text>
            <View style={[styles.left, styles.space]}>
              <View style={styles.listItem}>
                <Text>Trade ID: </Text>
                <Text style={styles.bold}> {invoiceData.tradeSeq}</Text>
              </View>
              <View style={styles.listItem}>
                <Text>Destination Partner: </Text>
                <Text style={styles.bold}> {invoiceData.destName}</Text>
              </View>
              <View style={styles.listItem}>
                <Text>Destination Location: </Text>
                <Text style={styles.bold}> {invoiceData.destAddrLine}</Text>
              </View>
              <View style={styles.listItem}>
                <Text>Dispatch Date: </Text>
                <Text style={styles.bold}> {invoiceData.date}</Text>
              </View>
              <View style={styles.listItem}>
                <Text>Advance Amount: </Text>
                <Text style={styles.bold}> {invoiceData.advanceAmt}</Text>
              </View>
            </View>
            <View style={[styles.right]}>
              <View style={[styles.listItem, styles.listItemLeft]}>
                <Text>Source Partner: </Text>
                <Text style={styles.bold}> {invoiceData.srcName}</Text>
              </View>
              <View style={[styles.listItem, styles.listItemLeft]}>
                <Text>DOB: </Text>
                <Text style={styles.bold}> {invoiceData.dob}</Text>
              </View>
              <View style={[styles.listItem, styles.listItemLeft]}>
                <Text>Location: </Text>
                <Text style={styles.bold}> {invoiceData.srcAddrLine}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.hindiDiv}>
          <Text style={[styles.header_head, styles.space, styles.bold]}>
            ट्रेड एडवांस नियम एवं शर्तें (एग्रीमेंट)
          </Text>
          <View>
            <Text style={[styles.para, styles.mainpara]}>
              नीचे हस्ताक्षरकर्ता स्रोत भागीदार ने ट्रेड एडवांस अनुरोध फॉर्म
              <Text style={styles.bold}>(“TARF”)</Text> प्रस्तुत करके, ONO ARK
              INDIA PRIVATE LIMITED <Text>(“ONO PLATFORM”)</Text> से व्यापार को
              सुविधाजनक बनाने और व्यापार ID: {invoiceData.tradeSeq} के लिए{" "}
              {invoiceData.advanceAmt}
              की व्यापार अग्रिम राशि प्रदान करने का अनुरोध किया है। यह नीचे
              उल्लिखित नियमों और शर्तों
              <Text style={styles.bold}>(“एग्रीमेंट”)</Text>द्वारा शासित होगा:
            </Text>
            <Text style={styles.subHeading}>
              1. स्रोत भागीदार स्वीकार करता है, समझता है और सहमत है कि:
            </Text>
            <View style={styles.list}>
              <Text style={styles.listItem}>
                a. प्रेषण के समय या प्रेषण से पहले की गई व्यापार अग्रिम राशि तब
                तक स्रोत भागीदार की जिम्मेदारी रहेगी जब तक कि माल के आगमन की
                पुष्टि नहीं हो जाती और गंतव्य भागीदार द्वारा स्वीकार नहीं कर
                लिया जाता।
              </Text>
              <Text style={styles.listItem}>
                b. व्यापार अग्रिम मंच (ONO) या ONO द्वारा कोई दायित्व नहीं है,
                अपने विवेक पर किसी भी व्यापार अग्रिम अनुरोध को अस्वीकार करने का
                अधिकार रखता है।
              </Text>
              <Text style={styles.listItem}>
                c. स्रोत साझेदार ने गंतव्य साझेदार की वस्तु गुणवत्ता और किस्म
                विनिर्देशों की आवश्यकताओं का कड़ाई से अनुपालन करने पर सहमति
                व्यक्त की।
              </Text>
              <Text style={styles.listItem}>
                d. स्रोत साझेदार को वस्तुओं की सटीक, सही और विश्वसनीय तस्वीरें
                तथा मात्रा, किस्म, पृथक्करण चिह्न और प्रेषण विवरण के व्यापार
                विवरण के बारे में जानकारी प्रस्तुत करनी होगी।
              </Text>
              <Text style={styles.listItem}>
                e. सद्भावना और भरोसा: स्रोत भागीदार इस व्यापार समझौते से संबंधित
                सभी मामलों में अत्यंत सद्भावना और भरोसे के साथ कार्य करने के लिए
                बाध्य है।
              </Text>
            </View>
            <Text style={styles.subHeading}>
              2. स्वीकृत व्यापार वाहनों को पुनर्निर्देशित करने / न भेजने पर
              प्रतिबंध:
            </Text>
            <View style={styles.list}>
              <Text style={styles.listItem}>
                a. किसी भी परिस्थिति में स्वीकृत व्यापार वाहनों को ONO की
                औपचारिक सहमति और अनुमोदन के बिना समय पर पुनर्निर्देशित या नहीं
                भेजा जाएगा, क्योंकि ऐसी कार्रवाइयां गंतव्य भागीदारों के साथ
                स्थापित विश्वास से समझौता करती हैं।
              </Text>
              <Text style={styles.listItem}>
                b. यदि स्रोत भागीदार समय पर वाहन भेजने में विफल रहता है (या)
                वाहन को बीच रास्ते में ही मोड़ देता है, तो यह इस समझौते का
                उल्लंघन है और ऐसी कार्रवाइयों से गंतव्य पक्ष के साथ स्थापित
                विश्वास पर प्रतिकूल प्रभाव पड़ता है। इसके अलावा, स्रोत भागीदार
                इस व्यापार के लिए लिया गया व्यापार अग्रिम दंड के साथ तुरंत ONO
                को वापस करने के लिए उत्तरदायी है।
              </Text>
              <Text style={styles.listItem}>
                c. स्रोत भागीदार प्लेटफ़ॉर्म के माध्यम से ट्रेड एडवांस की सफल
                प्राप्ति के बाद पुष्टि किए गए व्यापार को रद्द या डायवर्ट करने का
                हकदार नहीं होगा। यदि, किसी भी कारण से, स्रोत भागीदार पुष्टि किए
                जाने और ट्रेड एडवांस का लाभ उठाए जाने के बाद व्यापार को रद्द या
                डायवर्ट करता है, तो ONO को स्रोत भागीदार से रद्दीकरण शुल्क
                वसूलने का अधिकार होगा। ऐसे किसी भी कारण से रद्दीकरण/डायवर्ट किए
                जाने की स्थिति में, जिसमें ऐसे रद्द किए गए व्यापार के लिए ONO
                द्वारा स्रोत भागीदार को शुल्क का भुगतान किया गया है, सोर्स
                पार्टनर 2 (दो) कार्य दिवसों के भीतर ONO को प्राप्त शुल्क वापस
                करेगा, जिसके बाद ONO द्वारा अपने विवेकानुसार उचित समझे जाने वाले
                शुल्क और रद्दीकरण शुल्क को घटाकर गंतव्य पार्टनर को वापस कर दिया
                जाएगा। इसके अतिरिक्त, रद्द किए गए व्यापार लागत का 2% भुगतान किया
                जाएगा, जिसे गंतव्य पार्टनर को हस्तांतरित किया जाएगा।
              </Text>
            </View>
          </View>
          <View style={styles.space}>
            <Text style={styles.subHeading}>
              3. ONO को इसके लिए उत्तरदायी नहीं ठहराया जाएगा:
            </Text>
            <View style={styles.list}>
              <Text style={styles.listItem}>
                a. कोई भी इन्वेंट्री या कमोडिटी जोखिम जो उत्पन्न हो सकता है।
              </Text>
              <Text style={styles.listItem}>b. परिवहन से जुड़े सभी जोखिम।</Text>
              <Text style={styles.listItem}>
                c. गुणवत्ता संबंधी मुद्दों के आधार पर ऑर्डर, वाहन या कमोडिटी की
                अस्वीकृति जो लेन-देन या व्यापार के दौरान हो सकती है।
              </Text>
              <Text style={styles.listItem}>
                d. कोई भी गैरकानूनी छवि या डेटा जो अनजाने में प्लेटफ़ॉर्म पर
                दिखाई दे सकता है, भले ही यह सुनिश्चित करने का प्रयास किया गया हो
                कि प्लेटफ़ॉर्म पर प्रकाशित सभी डेटा और छवियां लागू कानूनों का
                अनुपालन करती हैं। ONO किसी भागीदार द्वारा प्लेटफ़ॉर्म पर प्रकाशन
                के लिए अनुरोध की गई किसी भी जानकारी या छवियों के लिए कोई
                ज़िम्मेदारी नहीं लेता है जो कानूनी आवश्यकताओं के अनुरूप नहीं
                हैं।
              </Text>
            </View>
            <Text style={[styles.para, styles.space]}>
              मैं ऊपर दिए गए नियमों और शर्तों को पढ़ता हूँ, स्वीकार करता हूँ और
              उनसे सहमत हूँ तथा व्यापार अग्रिम के लिए आवेदन करता हूँ।
            </Text>
            <View style={[styles.left, styles.space]}>
              <View style={styles.listItem}>
                <Text>व्यापार आईडी: </Text>
                <Text style={styles.bold}> {invoiceData.tradeSeq}</Text>
              </View>

              <View style={styles.listItem}>
                <Text> गंतव्य भागीदार: </Text>
                <Text style={styles.bold}> {invoiceData.destName}</Text>
              </View>
              <View style={styles.listItem}>
                <Text> गंतव्य स्थान: </Text>
                <Text style={styles.bold}> {invoiceData.destAddrLine}</Text>
              </View>
              <View style={styles.listItem}>
                <Text> प्रेषण तिथि: </Text>
                <Text style={styles.bold}> {invoiceData.date}</Text>
              </View>
              <View style={styles.listItem}>
                <Text>अग्रिम राशि:</Text>
                <Text style={styles.bold}> {invoiceData.advanceAmt}</Text>
              </View>
            </View>

            <View style={[styles.right]}>
              <View style={[styles.listItem, styles.listItemLeft]}>
                <Text> स्रोत भागीदार:</Text>
                <Text style={styles.bold}> {invoiceData.srcName}</Text>
              </View>{" "}
              <View style={[styles.listItem, styles.listItemLeft]}>
                <Text> जन्म तिथि:</Text>
                <Text style={styles.bold}> {"-"}</Text>
              </View>
              <View style={[styles.listItem, styles.listItemLeft]}>
                <Text> स्थान:</Text>
                <Text style={styles.bold}> {invoiceData.srcAddrLine}</Text>
              </View>
            </View>
          </View>
        </View>
        {/* <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Name</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Description</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Quantity</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Unit Price</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Total</Text>
            </View>
          </View>
          {invoiceData.items.map((item, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.description}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.quantity}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.unitPrice}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.total}</Text>
              </View>
            </View>
          ))}
        </View> */}
      </View>
      {/* Footer with Page Numbers */}

      <View style={styles.footer} fixed>
        <View style={styles.relative}>
          <View style={styles.space}>
            <Text style={styles.footerPara}>Yours faithfully,</Text>
            <Text style={[styles.footerPara, styles.bold]}>
              For <Text style={styles.bold}> {invoiceData.srcTradename} </Text>
            </Text>
          </View>
          <View style={styles.space}>
            <Text style={styles.footerParaSm}>
              eSigned using OTP (ono.ag - {invoiceData?.srcEsign})
            </Text>
            <Text style={styles.footerParaSm}>
              {invoiceData.srcName} / {invoiceData?.srcMobile}
            </Text>
            <Text style={styles.footerParaSm}>
              Date: {invoiceData.timeStamp}
            </Text>
          </View>
          <View>
            <Text style={styles.footerPara}>{invoiceData.srcName}</Text>
            <Text style={[styles.footerPara, styles.bold]}>
              {invoiceData?.srcMobile}
            </Text>
          </View>
          <Text
            render={({ pageNumber, totalPages }) =>
              `Page ${pageNumber} of ${totalPages}`
            }
            style={[styles.pageno, styles.footerParaSm]}
          />
          <Image src={logo_op} style={styles.logo_op} />
        </View>
      </View>
    </Page>
  </Document>
);

export default AdvanceDocument;
