import React from "react";
import { Document, Page, View, StyleSheet } from "@react-pdf/renderer";
import FinalTaxInvoiceDoc from "./finalTaxInvoice";

const styles = StyleSheet.create({
  page: {
    paddingTop: 80, // Adjust to leave space for the header
    paddingBottom: 60, // Adjust to leave space for the footer
    paddingHorizontal: 40,
    fontFamily: "Roboto",
    height: "100%",
  },
});

const TaxDocs = ({ details, type, status }) =>
  status == "single" ? (
    <Document>
      <Page size="A4" style={styles.page}>
        <FinalTaxInvoiceDoc details={details} type={type} />
      </Page>
    </Document>
  ) : (
    <View style={styles.page}>
      <FinalTaxInvoiceDoc details={details} type={type} />
    </View>
  );

export default TaxDocs;
