import { ArrowBack } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PartnerInfo from "../../components/partner-info";
import edit from "../../assets/images/edit_round.svg";
import { Button } from "../../components/ui";
import Modal from "../../components/ui/modal";
import ModalHeader from "../../components/ui/modal/modal-header";
import EditSupplyPost from "./edit-supply-post";
import market from "../../assets/images/mandi.svg";
import ContactInfo from "./contact-info";
import EditPostRequest from "./edit-post-request";
import AssignOffTaker from "./assign-off-taker";
import CropImageGallery from "./crop-image-gallery";
import call from "../../assets/images/call.svg";
import useSupplyDetailsById from "./useSupplyDetails";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  updateActiveTradeTab,
  updateProposalCropImgs,
  updateTraderContact,
} from "../../reducers/tradeSlice";
import NodataAvailable from "../../assets/images/NodataAvailable.svg";
import { FaCheck } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";
import { toCamelCaseWord } from "../../helpers/getText";
import getCurrencyNumber, {
  getCurrencyNumberWithOutSymbol,
} from "../../helpers/get-currency-number";
const SupplyPostView = () => {
  const navigate = useNavigate(); // To navigate to the KYC documents page
  const dispatch = useDispatch();
  const params = useParams();
  const { supplyDemandDetails, getSupplyDetail } = useSupplyDetailsById({
    id: parseInt(params.postId || "0"),
  });
  useEffect(() => {
    getSupplyDetail();
  }, [params]);

  const [supplyPostEdit, setSupplyPostEdit] = useState(false);
  const [viewContactInfo, setViewContactInfo] = useState(false);
  const [requestPostEdit, setRequestPostEdit] = useState(false);
  const [assignOffTakerStatus, setAssignOffTakerStatus] = useState(false);
  const [imageGalleryOpen, setImageGalleryOpen] = useState(false);
  const [propsalAccept, setAropsalAccept] = useState(false);
  return (
    <div className="">
      <div className="border overflow-hidden bg-white px-4">
        <div className="flex text-sm py-4 cursor-pointer items-center">
          <div
            className="grow-[2.5] basis-0 flex items-center cursor-pointer gap-1"
            style={{ width: "30%" }}
            onClick={() => {
              navigate("/trades");
            }}
          >
            <ArrowBack />
            <PartnerInfo
              profilePic={supplyDemandDetails?.profilePic || ""}
              name={supplyDemandDetails?.traderName || ""}
              shortName={""}
              partnerType={supplyDemandDetails?.tradeType}
              partnerId={supplyDemandDetails?.traderId}
              partnerMobile={supplyDemandDetails?.mobile || ""}
              partyName={supplyDemandDetails?.addressLine}
            />
          </div>

          <div className="flex gap-4 items-center" style={{ width: "25%" }}>
            <img src={market} className="w-8 h-8 " />
            <div className="flex flex-col">
              <span className="text-sm">Mandi Address </span>
              <span>
                <span className="text-sm font-semibold">
                  {supplyDemandDetails?.location
                    ? supplyDemandDetails?.location + ","
                    : "-"}
                  {supplyDemandDetails?.city
                    ? supplyDemandDetails?.city + ","
                    : ""}
                  {supplyDemandDetails?.state ? supplyDemandDetails?.state : ""}
                  {supplyDemandDetails?.pincode
                    ? " " + supplyDemandDetails?.pincode
                    : ""}
                </span>
              </span>
            </div>
          </div>
          <div className="flex gap-4 items-center" style={{ width: "20%" }}>
            <img src={call} className="w-8 h-8 " />
            <div className="flex flex-col">
              <span className="text-sm">Mobile number </span>
              <span>
                <span className="text-sm font-semibold">
                  {supplyDemandDetails?.mobile || "-"}
                </span>
              </span>
            </div>
          </div>
          <div className="flex gap-4 items-center" style={{ width: "20%" }}>
            <img src={call} className="w-8 h-8 " />
            <div className="flex flex-col">
              <span className="text-sm">Alternative Mobile </span>
              <span>
                <span className="text-sm font-semibold">
                  {supplyDemandDetails?.altMobile || "-"}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-4">
        <div className="border br-10 px-2 bg-white">
          {" "}
          <div
            className=" border-b text-sm py-4 flex justify-between items-center"
            style={{ width: "100%" }}
          >
            <div
              className=" flex justify-between px-2 "
              style={{ width: "100%" }}
            >
              <div
                className="grow-[3] basis-0 flex flex-col"
                style={{ width: "30%" }}
              >
                <div className="flex items-center gap-x-3">
                  <div>
                    <img
                      src={supplyDemandDetails?.cropUrl}
                      alt=""
                      className="br-100 w-8 h-8 "
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm font-bold">
                      {supplyDemandDetails?.cropName}
                    </span>
                    <span className="text-xs font-medium">
                      {supplyDemandDetails?.quality
                        ? toCamelCaseWord(supplyDemandDetails?.quality) +
                          " Quality"
                        : "-"}
                    </span>

                    <span className="text-xs">
                      Post ID :{" "}
                      <span className="color-blue">
                        {supplyDemandDetails?.id}
                      </span>{" "}
                      | {supplyDemandDetails?.timeAgo}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="grow-[2] basis-0 flex px-2 flex-col"
                style={{ width: "20%" }}
              >
                <span className="text-sm">Quantity</span>
                <span className="text-sm font-semibold">
                  {" "}
                  {getCurrencyNumberWithOutSymbol(supplyDemandDetails?.qty) ||
                    "-"}
                  {supplyDemandDetails?.unitType}
                </span>
              </div>
              <div
                className="grow-[2] basis-0 flex px-2 flex-col"
                style={{ width: "20%" }}
              >
                <span className="text-sm">Expected Rate</span>
                <span className="font-semibold text-sm ">
                  {" "}
                  Rs.{supplyDemandDetails?.expRate} Per KG
                </span>
              </div>
              <div
                className="grow-[2] basis-0 flex px-2 flex-col"
                style={{ width: "20%" }}
              >
                <span className="text-sm">Availability Date</span>
                <span className="text-sm font-semibold">
                  {" "}
                  {moment(supplyDemandDetails?.avlDate).format("DD-MMM-YYYY") ||
                    "-"}
                </span>
              </div>
              <div
                className="grow-[2] basis-0 flex px-2 flex-col"
                style={{ width: "20%" }}
              >
                <span className="text-sm">Requests</span>
                <span className="font-semibold text-sm ">{`${
                  supplyDemandDetails?.propsCount
                    ? `${supplyDemandDetails?.propsCount}`
                    : "-"
                }
                          `}</span>
              </div>
            </div>
            <div className="flex" style={{ width: "20%" }}>
              <div>
                {supplyDemandDetails?.status != "ASSIGNED" ? (
                  <img
                    src={edit}
                    onClick={() => {
                      setSupplyPostEdit(true);
                    }}
                    className="cursor-pointer w-10 h-10"
                  />
                ) : (
                  <Button variant="primaryLight" className="">
                    <FaCheckCircle color="#16A02C" /> Status :{" "}
                    {supplyDemandDetails?.status}
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="py-4 px-2 flex justify-between items-center">
            <div className="flex gap-4">
              {supplyDemandDetails?.imgs
                ? supplyDemandDetails?.imgs.map((crop, i) => (
                    <img
                      src={crop.url}
                      alt=""
                      className="br-10 cursor-pointer"
                      style={{ width: "60px", height: "60px" }}
                      onClick={() => {
                        setImageGalleryOpen(true);
                        dispatch(
                          updateProposalCropImgs({
                            proposalCropImages: supplyDemandDetails.imgs,
                          })
                        );
                      }}
                    />
                  ))
                : ""}
            </div>
            {supplyDemandDetails?.status != "ASSIGNED" ? (
              <Button
                variant="contained"
                className=""
                onClick={() => {
                  setAssignOffTakerStatus(true);
                }}
              >
                Assign Off Taker
              </Button>
            ) : (
              <Button
                variant="contained"
                className=""
                onClick={() => {
                  if (supplyDemandDetails?.tradeDtls?.tradeId) {
                    navigate(
                      `/trade-detail/${supplyDemandDetails?.traderId}/${supplyDemandDetails?.tradeDtls?.tradeId}`
                    );
                  } else {
                    navigate("/trades");
                  }
                  dispatch(updateActiveTradeTab("OPEN"));
                }}
              >
                Go To Trades
              </Button>
            )}
          </div>
        </div>
      </div>
      {supplyDemandDetails?.status == "ASSIGNED" &&
        supplyDemandDetails?.tradeDtls &&
        supplyDemandDetails?.propsCount == 0 && (
          <div className="px-4 py-1">
            <div className="border-b pb-2">
              <span className="rounded-t-lg border-green-600 border-b-4 pb-2 font-medium color-primary">
                Assigned Off Taker
              </span>
            </div>

            <div className="bg-white border br-10 py-4 px-4 flex justify-between items-center mt-4">
              <div className="flex items-center gap-4" style={{ width: "50%" }}>
                {" "}
                <div className="grow-[2] basis-0 flex px-2 flex-col">
                  <PartnerInfo
                    profilePic={""}
                    name={supplyDemandDetails?.tradeDtls?.traderName || ""}
                    shortName={""}
                    partnerType={"Trader"}
                    partnerId={supplyDemandDetails?.tradeDtls?.traderId}
                    partnerMobile={supplyDemandDetails?.tradeDtls?.mobile || ""}
                    partyName={supplyDemandDetails?.tradeDtls?.addrLine}
                  />
                </div>
                <div
                  className="grow-[2] basis-0 flex px-2 flex-col"
                  style={{ width: "20%" }}
                >
                  <span className="text-sm">Pickup Date</span>
                  <span className="font-semibold text-sm ">
                    {moment(supplyDemandDetails?.tradeDtls?.date).format(
                      "DD-MMM-YYYY"
                    )}
                  </span>
                </div>
                <div
                  className="grow-[2] basis-0 flex px-2 flex-col"
                  style={{ width: "20%" }}
                >
                  <span className="text-sm">Offer Price</span>
                  <span className="font-semibold text-sm ">
                    Rs. {supplyDemandDetails?.tradeDtls?.rate} Per Kg
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      <div className="px-4 py-1">
        <div className="border-b pb-2">
          <span className="rounded-t-lg border-green-600 border-b-4 pb-2 font-medium color-primary">
            {supplyDemandDetails?.status == "ASSIGNED" &&
            supplyDemandDetails?.proposals
              ? "Accepted"
              : ""}{" "}
            Requests (
            {supplyDemandDetails?.propsCount
              ? supplyDemandDetails?.propsCount
              : "0"}
            )
          </span>
        </div>
        <div>
          {supplyDemandDetails?.proposals ? (
            supplyDemandDetails?.proposals
              .filter((doc) => doc.prgStatus != "ASSIGNED")
              .map((req, index) => (
                <div className="bg-white border br-10 py-4 px-4 flex justify-between items-center mt-4">
                  <div
                    className="flex items-center gap-4"
                    style={{ width: "30%" }}
                  >
                    <PartnerInfo
                      profilePic={req?.profilePic || ""}
                      name={req?.traderName || ""}
                      shortName={""}
                      partnerType={req?.tradeType}
                      partnerId={req?.traderId}
                      partnerMobile={""}
                      partyName={req?.addressLine}
                      distanceFrom={true}
                      distance={req?.distance ? req?.distance.toFixed(2) : ""}
                    />
                    {
                      req?.imgs && req.imgs.length > 0 && (
                        // req.imgs.map((img, i) => (
                        <div
                          className="relative cursor-pointer"
                          onClick={() => {
                            setImageGalleryOpen(true);
                            dispatch(
                              updateProposalCropImgs({
                                proposalCropImages: req.imgs,
                              })
                            );
                          }}
                        >
                          <img
                            src={req.imgs[0].url}
                            alt=""
                            className="br-10 "
                            style={{ width: "120px", height: "70px" }}
                          />
                          {req.imgs.length > 1 && (
                            <div
                              className="br-10 absolute top-0 flex justify-center items-center"
                              style={{
                                width: "70px",
                                height: "70px",
                                backgroundColor: "#000",
                                opacity: "0.5",
                                right: "-10px",
                              }}
                            >
                              <span className="text-sm text-white font-medium">
                                +{req.imgs.length - 1}
                              </span>
                            </div>
                          )}
                        </div>
                      )
                      // ))
                    }
                  </div>
                  <div
                    className="flex items-center gap-4"
                    style={{ width: "40%" }}
                  >
                    <div
                      className="grow-[2] basis-0 flex px-2 flex-col"
                      style={{ width: "20%" }}
                    >
                      <span className="text-sm">Expected Date</span>
                      <span className="text-sm font-semibold">
                        {moment(req.expDate).format("DD-MMM-YYYY") || "-"}
                      </span>
                    </div>
                    <div
                      className="grow-[2] basis-0 flex px-2 flex-col"
                      style={{ width: "20%" }}
                    >
                      <span className="text-sm">Offer Price</span>
                      <span className="font-semibold text-sm ">
                        Rs.{req.rate} Per KG
                      </span>
                    </div>
                    <div
                      className="grow-[2] basis-0 flex px-2 flex-col"
                      style={{ width: "20%" }}
                    >
                      {/* <span className="text-sm">Offer Price</span>
                <span className="font-semibold text-sm ">Rs.80 Per KG</span> */}
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <div>
                      {supplyDemandDetails?.status != "ASSIGNED" && (
                        <img
                          src={edit}
                          className="cursor-pointer w-8 h-8"
                          onClick={() => {
                            setRequestPostEdit(true);
                            dispatch(
                              updateTraderContact({
                                traderContactInfo: req,
                              })
                            );
                          }}
                        />
                      )}
                    </div>
                    <Button
                      variant="lightBlue"
                      className=""
                      onClick={() => {
                        setViewContactInfo(true);
                        dispatch(
                          updateTraderContact({
                            traderContactInfo: req,
                          })
                        );
                      }}
                    >
                      View Contact Info
                    </Button>
                    {/* <Button
                      variant="primaryLight"
                      className=""
                      onClick={() => {}}
                    >
                      Chat
                    </Button> */}
                    {supplyDemandDetails?.status != "ASSIGNED" && (
                      <Button
                        variant="contained"
                        className=""
                        onClick={() => {
                          setAssignOffTakerStatus(true);
                          setAropsalAccept(true);
                          dispatch(
                            updateTraderContact({
                              traderContactInfo: req,
                            })
                          );
                        }}
                      >
                        Accept
                      </Button>
                    )}
                  </div>
                </div>
              ))
          ) : (
            <div className="flex items-center justify-center py-8 mt-4 ">
              <img src={NodataAvailable} alt="image" />
            </div>
          )}
        </div>
      </div>
      {supplyPostEdit && (
        <>
          <Modal
            open={supplyPostEdit}
            onClose={() => {
              setSupplyPostEdit(false);
            }}
            width="40vw"
          >
            <>
              <ModalHeader title={"Edit Supply "} content={<></>} />
              <div className="py-3 px-6">
                <EditSupplyPost
                  onModalClose={setSupplyPostEdit}
                  type="Supply"
                />
              </div>
            </>
          </Modal>
        </>
      )}
      {viewContactInfo && (
        <>
          <Modal
            open={viewContactInfo}
            onClose={() => {
              setViewContactInfo(false);
            }}
            width="40vw"
          >
            <>
              <ModalHeader title={"Seller Contact Info"} content={<></>} />
              <div className="py-3 px-6">
                <ContactInfo onModalClose={setViewContactInfo} />
              </div>
            </>
          </Modal>
        </>
      )}
      {requestPostEdit && (
        <>
          <Modal
            open={requestPostEdit}
            onClose={() => {
              setRequestPostEdit(false);
            }}
            width="40vw"
          >
            <>
              <ModalHeader title={"Edit Request "} content={<></>} />
              <div className="py-3 px-6">
                <EditPostRequest
                  onModalClose={setRequestPostEdit}
                  type={"SUPPLY"}
                />
              </div>
            </>
          </Modal>
        </>
      )}
      {assignOffTakerStatus && (
        <>
          <Modal
            open={assignOffTakerStatus}
            onClose={() => {
              setAssignOffTakerStatus(false);
            }}
            width="50vw"
          >
            <>
              <ModalHeader
                title={`Supply Post ID : ${supplyDemandDetails?.id}  `}
                content={<></>}
              />
              <div className="py-3 px-6">
                <AssignOffTaker
                  onModalClose={setAssignOffTakerStatus}
                  type={"SUPPLY"}
                  fromPropsal={propsalAccept}
                />
              </div>
            </>
          </Modal>
        </>
      )}
      {imageGalleryOpen && (
        <>
          <Modal
            open={imageGalleryOpen}
            onClose={() => {
              setImageGalleryOpen(false);
            }}
            width="40vw"
          >
            <>
              <ModalHeader
                title={supplyDemandDetails?.cropName}
                content={<></>}
              />
              <div className="py-3 px-6">
                <CropImageGallery onModalClose={setImageGalleryOpen} />
              </div>
            </>
          </Modal>
        </>
      )}
    </div>
  );
};

export default SupplyPostView;
