import { FC, ReactNode } from "react";
import { useSelector } from "react-redux";
import Login from "../../pages/login";
import { LOGGEDIN } from "../../constants/storage-keys";
import { TRootState } from "../../store";
const AuthLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const { loggedin } = useSelector((state: TRootState) => ({
    loggedin: state.auth.loggedin,
  }));
  if (loggedin === false) {
    return <Login />;
  }
  return <>{children}</>;
};
export default AuthLayout;
