const getEnvVariables = (): {
  API_BASE_URL: string;
  ENV: "DEV" | "PROD";
  PDF_SERVICE_BASE_API: string;
  DOMAIN: string;
} => {
  if (window.location.host === "portal.onoark.com") {
    return {
      API_BASE_URL: "https://api.onoark.com/v1/",
      ENV: "PROD",
      PDF_SERVICE_BASE_API: "https://dev-pdfgen.onoark.com/v1/pdfgen", // "http://54.86.67.9:9001/v1/pdfgen",
      DOMAIN: "https://portal.onoark.com",
    };
  } else {
    return {
      API_BASE_URL: "https://dev-api.onoark.com/v1/",
      ENV: "DEV",
      PDF_SERVICE_BASE_API: "https://dev-pdfgen.onoark.com/v1/pdfgen",
      DOMAIN: "https://dev-portal.onoark.com",
    };
  }
};
export const SOURCE = "WEB";
export const { API_BASE_URL, ENV, PDF_SERVICE_BASE_API, DOMAIN } =
  getEnvVariables();
