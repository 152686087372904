import { FC, ReactNode, useMemo } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { deviceInfoContext } from "../context";
import banner1 from "../assets/images/banner1.png";
import banner2 from "../assets/images/banner2.png";
import banner3 from "../assets/images/banner3.png";
import classes from "./style.module.scss";
import { Slider } from "../components/ui";
import { useState } from "react";
import { useEffect } from "react";
function getdeviceInfo(): {
  deviceType: "MOBILE" | "DESKTOP" | "";
  width: string;
  height: string;
  left: string;
  right: string;
} {
  let width = window.innerWidth;
  let height = window.innerHeight;
  let deviceType: "MOBILE" | "DESKTOP" = width <= 800 ? "MOBILE" : "DESKTOP";
  return {
    deviceType: deviceType,
    width: deviceType === "MOBILE" ? width + "px" : "100vw",
    height: deviceType === "MOBILE" ? width + "px" : height + "px",
    left: "0px",
    right: "500px",
  };
}
let installPrompt: any = null;

const DeviceLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const deviceinfo = useMemo(() => {
    return getdeviceInfo();
  }, []);
  useEffect(() => {
    // const installButton = document.querySelector("#install");
    // window.addEventListener("beforeinstallprompt", (event) => {
    //   event.preventDefault();
    //   installPrompt = event;
    // });
  }, []);
  if (deviceinfo.deviceType === "MOBILE") {
    return (
      <deviceInfoContext.Provider value={deviceinfo}>
        {children}
        <ToastContainer position="top-left" />
      </deviceInfoContext.Provider>
    );
  }
  return (
    <deviceInfoContext.Provider value={deviceinfo}>
      {children}
      <ToastContainer position="top-right" />
    </deviceInfoContext.Provider>
  );
};
export default DeviceLayout;
