import React, { FC, useState } from "react";
import { IoSearchSharp } from "react-icons/io5";
import { BsCheckCircleFill } from "react-icons/bs";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import classes from "./style.module.scss";
import { TCrop } from "../../types";
import { useAllCropsList } from "../../hooks/useAllCrops";
import { IoMdCloseCircleOutline } from "react-icons/io";
const CropSelection: FC<{
  selectionMode: "single" | "multiple";
  onSelect: (crops: TCrop) => void;
}> = ({ selectionMode, onSelect }) => {
  const { crops } = useAllCropsList();
  const [selectedCrops, setSelectedCrops] = useState<{
    [key: number]: TCrop;
  }>({});
  const [searchText, setSearchText] = useState("");
  const onSelectCrop = (crop: TCrop) => {
    console.log(crop, selectionMode);
    if (selectionMode === "single") {
      onSelect(crop);
      return;
    }
    if (selectedCrops && selectedCrops[crop.cropId]) {
      let selectedCropsNew = { ...selectedCrops };
      delete selectedCropsNew[crop.cropId];
      setSelectedCrops(selectedCropsNew);
    } else {
      setSelectedCrops({
        ...selectedCrops,
        [crop.cropId]: crop,
      });
    }
  }; // clear serch
  const clearSearch = () => {
    console.log(searchText, "searchtext");
    setSearchText("");
  };

  return (
    <>
      <div>
        <div className="relative">
          <input
            style={{
              textIndent: ".9rem",
              color: "var(--ono-subtext)",
            }}
            placeholder="Search crop"
            value={searchText}
            onChange={(e: any) => {
              setSearchText(e.target.value);
            }}
            className="border br-10 px-3 py-2 w-full mb-3"
          />
          <IoSearchSharp
            className="absolute"
            style={{ top: "0.8rem", left: "0.5rem" }}
          />
          {searchText !== "" && (
            <IoMdCloseCircleOutline
              onClick={() => {
                clearSearch();
              }}
              className="absolute color-primary cursor-pointer"
              style={{
                top: ".7rem",
                right: "1rem",
                fontSize: "1.2rem",
                color: "gray",
                cursor: "pointer",
              }}
            />
          )}
        </div>
      </div>
      <div className="h-80 overflow-auto grid grid-cols-4 gap-2 gap-y-3 ">
        {(searchText
          ? crops.filter((crop) =>
              crop.cropName.toLowerCase().includes(searchText.toLowerCase())
            )
          : crops
        ).map((crop) => (
          <span
            className={`flex items-center flex-col text-center cursor-pointer relative ${
              selectedCrops && typeof selectedCrops[crop.cropId] !== "undefined"
                ? classes.selected
                : ""
            }`}
            onClick={() => {
              onSelectCrop(crop);
            }}
          >
            <img alt="crop_image" height={40} src={crop.imageUrl} width={40} />
            <span className="text-xs font-semibold">{crop.cropName}</span>
            <BsCheckCircleFill className={classes.selectedIcon} />
          </span>
        ))}
      </div>
      {/* {selectionMode === "single" && (
        <div className="flex px-4 py-2">
          <Button
            className="ml-auto"
            disabled={Object.keys(selectedCrops).length === 0}
            onClick={() => {
              onSelect(Object.values(selectedCrops));
              setSelectedCrops({});
            }}
          >
            Continue&nbsp;&nbsp;
            <FaAngleRight />
          </Button>
        </div>
      )} */}
    </>
  );
};
export default CropSelection;
