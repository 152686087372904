import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import PartnerInfo from "../../components/partner-info";
import { Button, FileUploader, Input, RadioButton } from "../../components/ui";
import market from "../../assets/images/market.png";
import { TRootState } from "../../store";
import { useSelector } from "react-redux";
const CropImageGallery: FC<{
  onModalClose: any;
}> = ({ onModalClose }) => {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const { proposalCropImages } = useSelector((state: TRootState) => ({
    proposalCropImages: state.trades.proposalCropImages,
  }));
  // State to track the selected image
  const [selectedImage, setSelectedImage] = useState(proposalCropImages[0]);

  // Handle image click
  const handleImageClick = (image: any) => {
    setSelectedImage(image);
  };

  // Dynamically adjust image size on window resize
  useEffect(() => {
    const resizeImage = () => {
      const img = imgRef.current;
      const container = img?.parentElement;

      if (img && container) {
        const containerWidth = container.offsetWidth;

        // Set the image width to the full width of the container initially
        img.style.width = "100%";
        img.style.height = "auto"; // Maintain aspect ratio based on width

        // If the height exceeds a certain value, adjust width/height accordingly
        if (img.offsetHeight > 300) {
          img.style.height = "300px";
          img.style.width = "auto";
        }
      }
    };

    window.addEventListener("resize", resizeImage);
    resizeImage(); // Initial resize

    return () => window.removeEventListener("resize", resizeImage);
  }, []);
  return (
    <>
      <div className="py-4 px-3">
        {" "}
        <div className="image-gallery">
          <div
            className="selected-image"
            style={{
              width: "100%",
              // height: "400px",
              // position: "relative",
              // overflow: "hidden",
            }}
          >
            <img
              ref={imgRef}
              src={selectedImage.url}
              alt="Selected"
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "300px", // Limit height to 300px
                objectFit: "contain",
                objectPosition: "center",
              }}
              className="br-10"
            />
          </div>
          <div className="thumbnail-list flex justify-center mt-3">
            {proposalCropImages.map((image, index) => (
              <div
                key={index}
                onClick={() => handleImageClick(image)}
                className="br-10 cursor-pointer"
                style={{
                  margin: "0 10px",
                  border:
                    selectedImage.id === image.id
                      ? "2px solid #16A02C"
                      : "none",
                }}
              >
                <img
                  className="br-10"
                  src={image.url}
                  alt={`crop ${index + 1}`}
                  style={{ width: "80px", height: "80px" }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default CropImageGallery;
