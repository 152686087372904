import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useAxiosClient from "./useAxiosClient";
import { TSupplyDemandReq, TTrader } from "../types/index";
import { TRootState } from "../store";

const useAllSupplyDetails = () => {
  const { handleGetCall } = useAxiosClient();
  const { adminId, role } = useSelector((state: TRootState) => ({
    adminId: state.auth.adminId,
    role: state.auth.userInfo?.roleType,
  }));
  const [allSupplyDemands, setAllSupplyDemands] = useState<TSupplyDemandReq[]>(
    []
  );
  const [activeTab, setActiveTab] = useState("all");
  const [dataLength, setDataLength] = useState<{
    typeTab: string;
    length: number;
  }>({ typeTab: "", length: 0 });
  useEffect(() => {
    handleGetCall<TSupplyDemandReq[]>(
      `admin/demand-supply/supply/adminId/${adminId}`
    ).then(({ data }) => {
      if (data) {
        if (activeTab == "pending") {
          const filterArray = data.filter(
            (item) => item?.status?.toUpperCase() == "PENDING"
          );
          setAllSupplyDemands(filterArray);
          setDataLength({
            ...dataLength,
            typeTab: "pending",
            length: filterArray.length,
          });
        } else if (activeTab == "assigned") {
          const filterArray = data.filter(
            (item) => item?.status?.toUpperCase() == "ASSIGNED"
          );
          setAllSupplyDemands(filterArray);
          setDataLength({
            ...dataLength,
            typeTab: "assigned",
            length: filterArray.length,
          });
        } else if (activeTab == "expired") {
          const filterArray = data.filter(
            (item) => item?.status?.toUpperCase() == "EXPIRED"
          );
          setAllSupplyDemands(filterArray);
          setDataLength({
            ...dataLength,
            typeTab: "expired",
            length: filterArray.length,
          });
        } else {
          setAllSupplyDemands(data);
          setDataLength({
            ...dataLength,
            typeTab: "all",
            length: data.length,
          });
        }
        console.log(data, "data supply");
      } else {
        setAllSupplyDemands([]);
      }
    });
  }, [activeTab]);

  return { allSupplyDemands, role, activeTab, setActiveTab, dataLength };
};
export default useAllSupplyDetails;
