import { FC } from "react";
import defaultProfilePic from "../../../assets/images/single_bill.svg";
const PartnerInfo: FC<{
  name: string;
  tradeName: string;
  profilePic: string;
  partyType?: "From" | "To" | "";
}> = ({ name, tradeName, profilePic, partyType = "" }) => {
  return (
    <>
      <div className="flex gap-2">
        <img src={profilePic || defaultProfilePic} />
        <span className="flex flex-col">
          <span className="text-sm font-semibold">
            {partyType + " : "}
            {tradeName}
          </span>
          <span className="text-xs font-medium">{name}</span>
        </span>
      </div>
    </>
  );
};
export default PartnerInfo;
